import './index.scss';
import {Button, Switch, Popover, App} from "antd";
import {fetchLogout, setLoginVisible} from "@/store/modules/userStore";
import { setIsMock  } from '@/store/modules/commonStore';
import {useDispatch, useSelector} from "react-redux";
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import {PriceSvg, CoinSvg} from "@/components/svg";
import { useNavigate, useLocation } from 'react-router-dom';
import { RouterPath } from '@/config/nav';

function UserPopoverContent() {
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const dispatch = useDispatch();
  const { userId, avatar_url, username, phone_mask, email_mask, plan, plan_end_at } = useSelector((state) => state.user);

  const getMemberClassName = () => {
    if (plan.level_id === 0) {
      return 'member-item free'
    } else if (plan.level_id === 1) {
      return 'member-item base'
    } else if (plan.level_id === 2) {
      return 'member-item standard'
    } else if (plan.level_id === 3) {
      return 'member-item pro'
    }
  }

  const logout = () => {
    modal.confirm({
      title: '确定要退出登录吗？',
      onOk: () => dispatch(fetchLogout())
    })
  }

  return (
    <div className="user-popover">
      <div className="user-base">
        <img className="user-avatar" src={avatar_url} alt=""/>
        <div className="user-info">
          <div className="user-info-username">{ username || email_mask || phone_mask }</div>
          <div className="user-info-id">ID：{userId}</div>
        </div>
      </div>
      <div className="user-member">
        <div className={getMemberClassName()}>
          <div>{plan.name}</div>
          { !!plan_end_at && <div className="deadline">到期时间：{plan_end_at}</div> }
        </div>
      </div>
      <div className="other-setting" onClick={() => navigate(RouterPath.account)}>账号设置</div>
      <div className="other-setting" onClick={ logout }>退出登录</div>
    </div>
  )
}

function CoinsPopoverContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = App.useApp();
  const { plan, lifetime_coins, period_coins } = useSelector((state) => state.user);

  function goPage(e, path) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();


    if (location.pathname === path) {
      message.info('已在当前页面');
    } else {
      navigate(path)
    }
  }

  return (
    <div className="coins-popover">
      <div className="coins-popover-amount">
        <div className="coins-popover-amount-left">
          <Icon className="coins-amount-icon" component={CoinSvg}></Icon>
          <span className="coins-amount-number">{period_coins} + {lifetime_coins}</span>
        </div>
        <Button size="small" type="primary" shape="round" onClick={(e) => goPage(e, RouterPath.recharge)}>购买</Button>
      </div>
      <div className="coins-popover-detail">
        <div className="coins-popover-rule">1 金币 = 1次 普通对话</div>
      </div>
      <div className="coins-popover-rules">
        <div className="coins-popover-rule">
          <span className="coins-popover-rule-text">每月 {plan.period_coins } 个金币</span>
          <div className="link-text" onClick={(e) => goPage(e, RouterPath.recharge)}>升级套餐</div>
        </div>
        <div className="coins-popover-rule">
          <span className="coins-popover-rule-text">如何赚取更多金币？</span>
          <div className="link-text" onClick={(e) => goPage(e, RouterPath.user)}>查看攻略</div>
        </div>
      </div>
    </div>
  )
}

function CommonHeader({ title, headerLeft, headerRight, showAvatar = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { userId, avatar_url, coins, systemSettings } = useSelector((state) => state.user);
  const { isMock } = useSelector((state) => state.common);

  function handleChangeMock(checked) {
    dispatch(setIsMock(checked));
  }

  function showCustomerService() {
    if (systemSettings.customer_service_link) {
      window.open(systemSettings.customer_service_link, '_blank');
    } else {
      message.warning('暂无客服在线，请稍后联系');
    }
  }

  return (
    <div className="common-header">
      <div className="common-header-left">
        { headerLeft ? headerLeft : (<div className="header-left-name">{title}</div>) }
      </div>
      <div className="common-header-right">
        { headerRight }
        {/* 切换mock */}
        {
          process.env.REACT_APP_ENV === 'dev' && (<div className="header-item customer-service-item">
            <span>模拟：</span> <Switch value={isMock} onChange={handleChangeMock} />
          </div>)
        }
        {/* 价格套餐 */}
        <div className="header-item price-item" onClick={() => navigate(RouterPath.recharge)}>
          <Icon component={PriceSvg} className="price-item-icon"></Icon>
          <span className="price-item-text">价格</span>
        </div>
        {/* 剩余金币 */}
        {
          !!userId && (<div className="header-item price-item coin-item" onClick={() => navigate(RouterPath.user)}>
            <Icon component={CoinSvg} className="price-item-icon"></Icon>
            <span className="price-item-text">{ coins }</span>
            <Popover content={CoinsPopoverContent} title="">
              <QuestionCircleOutlined className="question-icon"/>
            </Popover>
          </div>)
        }
        {/* 联系客服 */}
        <div className="header-item customer-service-item">
          <Button type="text" size="small" onClick={showCustomerService}>客服</Button>
        </div>
        {/* 用户头像 */}
        {
          showAvatar && (<div className="user-avatar">
            { userId ?
              <Popover content={UserPopoverContent} title="">
                <img className="user-avatar-img"
                     onClick={() => navigate(RouterPath.user)}
                     src={avatar_url} alt="" />
              </Popover>
              :
              <Button type="primary" onClick={() => dispatch(setLoginVisible(true))}>登录/注册</Button> }
          </div>)
        }
      </div>
    </div>
  )
}

export default CommonHeader;
