import './User.scss';
import {useEffect, useState} from 'react';
import CommonHeader from '@/components/CommonHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginVisible } from '@/store/modules/userStore';
import Icon from '@ant-design/icons';
import {Affix, Button} from 'antd';
import {UnLoginSvg} from "@/components/svg";
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {RouterPath} from "@/config/nav";

const navs = [
  {
    label: '我的会员',
    key: 'member',
    path: '/user',
  },
  {
    label: '账号信息',
    key: 'account',
    path: '/user/account'
  },
  {
    label: '我的邀请',
    key: 'invite',
    path: '/user/invite'
  },
  {
    label: '支付历史',
    key: 'payment',
    path: '/user/payment'
  }
]

function User() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  const [navKey, setNavKey] = useState(navs[0].key);
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (location && location.pathname) {
      const pathMatch = location.pathname.match(/\/.*\//);
      if (pathMatch) {
        const [key] = Object.entries(RouterPath).find(item => item[1] === location.pathname);
        setNavKey(key);
      } else {
        setNavKey(navs[0].key);
      }
    }
  }, [location])

  function changeTab(item) {
    navigate(item.path);
  }

  return (
    <div className="user-container">
      {/* 头部 */}
      <CommonHeader title="个人中心" />
      {/* 主体 */}
      <div className="user-body">
        { token ? (
          <div className="person-container" ref={setContainer}>
            {/* tab */}
            <Affix offsetTop={0} target={() => container}>
              <div className="navs">
                { navs.map(item => {
                  return (<div key={item.key}
                               className={item.key === navKey ? 'nav nav-active' : 'nav'}
                               onClick={() => changeTab(item)}>{ item.label }</div>)
                }) }
              </div>
            </Affix>
            <div className="person-body">
              <Outlet></Outlet>
            </div>
          </div>
        ) : (
          <div className="un-auth-container">
            <div className="un-auth-icon">
              <Icon component={UnLoginSvg}></Icon>
            </div>
            <div className="un-auth-text">您还没有登录，请先登录后再操作</div>
            <div className="un-auth-btn">
              <Button size="large"
                      type="primary"
                      onClick={() => dispatch(setLoginVisible(true))}>点击登录</Button>
            </div>
          </div>
        ) }
      </div>
    </div>
  )
}

export default User;
