// 封装和draft草稿相关的方法

const DRAFT_KEY = 'draft_key';
const PASS_INPUT_KEY = 'pass_input_key';
const CHAT_CONFIG_KEY = 'chat_config_key';

// const dataStore = {
//   conversationId_1: xxx,
//   conversationId_2: xxx
// }

export function setStorageDraft(id, text = '') {
  const dataStore = JSON.parse(sessionStorage.getItem(DRAFT_KEY)) || {};
  dataStore[id] = text;
  sessionStorage.setItem(DRAFT_KEY, JSON.stringify(dataStore));
}

export function getStorageDrafts() {
  return JSON.parse(sessionStorage.getItem(DRAFT_KEY)) || {};
}

export function removeStorageDrafts() {
  sessionStorage.removeItem(DRAFT_KEY)
}

export function setStoragePassInput(id, text = '') {
  const dataStore = JSON.parse(sessionStorage.getItem(PASS_INPUT_KEY)) || {};
  dataStore[id] = text;
  sessionStorage.setItem(PASS_INPUT_KEY, JSON.stringify(dataStore));
}

export function getStoragePassInput() {
  return JSON.parse(sessionStorage.getItem(PASS_INPUT_KEY)) || {};
}

export function removeStoragePassInput() {
  sessionStorage.removeItem(PASS_INPUT_KEY)
}


// 获取相关配置

export function setStorageChatConfig(config) {
  sessionStorage.setItem(CHAT_CONFIG_KEY, JSON.stringify(config))
}

export function getStorageChatConfig() {
  return JSON.parse(sessionStorage.getItem(CHAT_CONFIG_KEY));
}

export function removeStorageChatConfig() {
  sessionStorage.removeItem(CHAT_CONFIG_KEY)
}
