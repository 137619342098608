import { createSlice } from '@reduxjs/toolkit';
import {
  setStorageToken,
  getStorageToken,
  removeStorageToken,
  antdUtils,
  setStorageUserId,
  // getStorageUserId,
  removeStorageUserId
} from '@/utils';
import { loginApi, getUserInfoApi, logoutApi } from '@/api/user';
import { seaApi, getAppsApi } from "@/api/common";
import initAvatar from '@/images/new_user_avatar.png';
// import router from '@/router';
import {AppTotalTag} from "@/config/chat";

const userStore = createSlice({
  name: 'user',
  initialState: {
    // 用户信息
    token: getStorageToken() || '',
    is_new: false,
    userId: null,
    code: '',
    username: '',
    nickname: '',
    phone_area_code: '',
    phone_number: '',
    phone_mask: '',
    email: '',
    email_mask: '',
    avatar_url: '',
    is_password_set: false,
    coins: 0,
    plan: {},
    plan_id: '',
    plan_start_at: '',
    plan_end_at: '',
    points: 0,
    lifetime_coins: 0,
    period_coins: 0,
    period_coins_start_at: '',
    period_coins_end_at: '',
    // 登录模态框
    loginVisible: false,
    // 通用信息
    plans: [], // 升级会员计划
    // buy_coin_amounts: [], // 购买金币选择的数量
    rmb_to_coins_exchange_rate: 0, // 基础兑换比率
    // min_order_amount: 0, // 最小订单金额
    chat_ai_models: [], // 所有的模型
    ai_apps: [], // 所有的app（除了默认app）
    default_ai_app: null,
    bot_ai_app_tags: [AppTotalTag], // chat类型的标签
    form_ai_app_tags: [AppTotalTag], // form类型的标签
    ai_commands: [],
    ai_command_tags: [AppTotalTag],
    is_dev: false,
    systemSettings: {
      buy_coins_quick_amounts: [],
      customer_service_link: '',
      place_order_min_amount: 0,
    }
  },
  reducers: {
    // 保存token信息
    setToken(state, action) {
      state.token = action.payload;
      setStorageToken(action.payload);
    },
    setIsNew(state, action) {
      state.is_new = action.payload;
    },
    // 保存用户信息
    setUserInfo(state, action) {
      const info = action.payload;
      state.userId = info.id;
      state.username = info.username;
      state.nickname = info.nickname;
      state.code = info.code;
      state.phone_number = info.phone_number;
      state.phone_area_code = info.phone_area_code;
      state.phone_mask = info.phone_mask;
      state.email = info.email;
      state.email_mask = info.email_mask;
      state.avatar_url = info.avatar_url || initAvatar;
      state.is_password_set = info.is_password_set;
      state.plan = info.plan;
      state.plan_id = info.plan_id;
      state.coins = info.coins;
      state.plan_start_at = info.plan_start_at;
      state.plan_end_at = info.plan_end_at;
      state.points = info.points;
      state.lifetime_coins = info.lifetime_coins;
      state.period_coins = info.period_coins;
      state.period_coins_start_at = info.period_coins_start_at;
      state.period_coins_end_at = info.period_coins_end_at;
    },
    // 更新用户头像
    setUserAvatar(state, action) {
      state.avatar_url = action.payload;
    },
    setLoginVisible(state, action) {
      state.loginVisible = action.payload;
    },
    // 保存海接口数据
    setSeaData(state, action) {
      const data = action.payload;
      // console.log(data);

      state.plans = data.plans;
      // state.buy_coin_amounts = data.buy_coin_amounts;
      state.rmb_to_coins_exchange_rate = data.rmb_to_coins_exchange_rate;
      // state.min_order_amount = data.min_order_amount;
      state.chat_ai_models = data.chat_ai_models;
      state.ai_apps = data.ai_apps;
      state.default_ai_app = data.default_ai_app;
      state.bot_ai_app_tags = [...state.bot_ai_app_tags, ...data.bot_ai_app_tags];
      state.form_ai_app_tags = [...state.form_ai_app_tags, ...data.form_ai_app_tags];
      state.ai_commands = data.ai_commands;
      state.ai_command_tags = [...state.ai_command_tags, ...data.ai_command_tags];
      state.is_dev = data.is_dev;
      state.systemSettings = Object.assign({ ...state.systemSettings }, data.settings.system);
    },
    clearUserStore(state) {
      state.token = '';
      state.is_new = false;
      state.userId = null;
      state.code = '';
      state.username = '';
      state.nickname = '';
      state.phone_area_code = '';
      state.phone_number = '';
      state.phone_mask = '';
      state.email = '';
      state.email_mask = '';
      state.avatar_url = '';
      state.is_password_set = false;
      state.coins = 0;
      state.plan = {};
      state.plan_id = '';
      state.plan_start_at = '';
      state.plan_end_at = '';
      state.points = 0;
      state.lifetime_coins = 0;
      state.period_coins = 0;
      state.period_coins_start_at = '';
      state.period_coins_end_at = '';

      removeStorageToken();
      removeStorageUserId();
    }
  }
})

const {
  setToken,
  setIsNew,
  setUserInfo,
  setUserAvatar,
  setLoginVisible,
  clearUserStore,
  setSeaData
} = userStore.actions;

// 获取sea数据
const fetchSea = () => {
  return async (dispatch) => {
    try{
      const res = await seaApi();
      dispatch(setSeaData(res));
    } catch (e) {
      console.log(e);
    }
  }
}

// 获取apps数据
const fetchApps = (params = {
  page_no: 1,
  page_size: 999,
}) => {
  return async (dispatch) => {
    try{
      const res = await getAppsApi(params);
      // dispatch(setApps(res.list));
    } catch (e) {
      console.log(e);
    }
  }
}

// 登录
const fetchLogin = (formData, isRegister = false) => {
  const params = { ...formData, auth_code: formData.code };
  delete params.code;

  return async (dispatch) => {
    // 登录请求
    antdUtils.setLoading && antdUtils.setLoading(true);
    try {
      const loginRes = await loginApi(params);
      loginRes.token && dispatch(setToken(loginRes.token));
      dispatch(setIsNew(loginRes.is_new));
      // 登录成功后获取用户信息
      if (!isRegister) {
        await dispatch(fetchUserInfo());
        dispatch(setLoginVisible(false));
      }
      antdUtils.setLoading && antdUtils.setLoading(false);
    } catch (e) {
      console.log(e);
      antdUtils.setLoading && antdUtils.setLoading(false);
    }
  }
}

// 获取用户信息
const fetchUserInfo = () => {
  return async (dispatch) => {
    try {
      const userRes = await getUserInfoApi();
      if (userRes.id > 0) {
        dispatch(setUserInfo(userRes));
        setStorageUserId(userRes.id);
      } else {
        dispatch(clearUserStore());
      }
    } catch (e) {
      console.log(e);
    }
  }
}

// 退出登录
const fetchLogout = () => {
  return async (dispatch) => {
    antdUtils.setLoading && antdUtils.setLoading(true);
    try {
      await logoutApi();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(clearUserStore());
      antdUtils.setLoading && antdUtils.setLoading(false);
      // router.navigate('/');
    }
  }
}


const reducer = userStore.reducer;

export {
  setToken,
  setIsNew,
  setUserInfo,
  setUserAvatar,
  setLoginVisible,
  fetchUserInfo,
  fetchLogin,
  fetchLogout,
  fetchSea,
  setSeaData,
  clearUserStore,
  fetchApps,
};
export default reducer;
