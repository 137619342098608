import {useEffect, useState, useRef} from "react";
import {Tooltip, Input, Form, Button, Radio, App, Popover} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {fetchUserInfo, setLoginVisible} from "@/store/modules/userStore";
import {chatApi} from "@/api/chat";
import {animateScroll} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";
import {FormAppInputType} from "@/config/chat";
import Markdown from "@/components/markdown";
import {
  getStorageChatConfig,
  setStorageChatConfig,
  getFormApp_dataStore,
  updateFormAppMessages_dataStore,
  initFormApp_dataStore
} from "@/utils";
import dayjs from "dayjs";
import ChatHistoryModal from "@/components/ChatHistoryModal";
import FormSettingPopover from "@/pages/form_apps/FormSettingPopover";
import {throttle} from "lodash";

export default function FormAppDetail({ app, open, onOpen }) {
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.user);
  const storageChatConfig = getStorageChatConfig();

  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [currentMessage, setCurrentMessage] = useState(null);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [chatHistoryModalOpen, setChatHistoryModalOpen] = useState(false);
  const [formAppConversation, setFormAppConversation] = useState(null);

  // 延迟滚动到底部
  const scrollToBottom = throttle(() => {
    animateScroll.scrollToBottom({
      containerId: 'formAppDetailElement',
      smooth: true,
      delay: 0,
      duration: 100,
    });
  }, 100);

  // 测试发送方式
  const [isMock, setIsMock] = useState(storageChatConfig ? storageChatConfig.isMock : false);
  const [isStream, setIsStream] = useState(storageChatConfig ? storageChatConfig.isStream : true);

  useEffect(() => {
    if (app && !open) {
      form.resetFields();
      setCurrentMessage(null);
      setCurrentMessages([]);
    }

    if (app && open) {
      // 初始化form app
      initFormApp_dataStore(app).then(() => {
        // 获取历史消息
        getFormApp_dataStore(app.id).then(data => {
          if (data && data.messages) {
            setCurrentMessages(data.messages);
          }
          setFormAppConversation(data);
        })
      })
    }
  }, [open]);

  useEffect(() => {
    setStorageChatConfig({ isMock, isStream })
  }, [isMock, isStream]);

  const chooseText = (text) => {
    const name = app.settings.form.fields[0].id;
    form.setFieldValue(name, text);
    form.validateFields();
  }

  const handleSubmit = (values) => {
    // 这里发送请求
    // 1、判断是否登录，未登录则打开弹窗
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    let output = '';
    const msg = {
      created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      role: 'assistant',
      id: app.id,
      content: '',
      loading: true,
      ok: true,
    };
    setCurrentMessage(msg);

    // 根据form字段获取content内容
    const messages = [...currentMessages];
    let userContent = '';
    if (app.settings.form && app.settings.form.fields && app.settings.form.fields.length) {
      const fields = app.settings.form.fields;
      fields.forEach((item) => {
        userContent += `# ${item.name} \r\n ${values[item.id]}`;
      })
    }
    messages.push({
      created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      role: 'user', // 用户发的消息
      id: userId,
      content: userContent,
      ok: true,
    })

    const params = {
      max_tokens: app.settings.max_tokens,
      temperature: app.settings.temperature,
    };
    if (formAppConversation.hasOwnProperty('max_tokens')) {
      params.max_tokens = formAppConversation.max_tokens;
    }
    if (formAppConversation.hasOwnProperty('temperature')) {
      params.temperature = formAppConversation.temperature;
    }

    // 发送请求
    chatApi({
      "ai_app_type_id": 'form',
      "is_mock": isMock,
      "is_stream": isStream,
      "ai_app_form_data": values,
      "ai_app_id": app.id,
      "ai_model_id": formAppConversation.modelId,
      ...params
    }, {
      openCallback: () => {
        console.log('---- open了 建立了链接----');
      },
      messageCallback: (data) => {
        if (msg.loading) {
          msg.loading = false;
        }
        output += data.content;
        msg.content = output;

        if (data.isError) {
          msg.ok = false;
        }

        setCurrentMessage({ ...msg });
        scrollToBottom();
      },
      closeCallback: () => {
        console.log('closeCallback');
        if (msg.loading) {
          msg.loading = false;
        }

        // 在这里更新金币余额
        dispatch(fetchUserInfo());
        // 保存数据
        setCurrentMessage({ ...msg });
        messages.push(msg);
        setCurrentMessages(messages);
        // 更新数据库中的数据
        updateFormAppMessages_dataStore(app, messages);
        setTimeout(() => {
          animateScroll.scrollToBottom({
            containerId: 'formAppDetailElement',
            smooth: true,
            delay: 0,
            duration: 500
          });
        },50)
      },
      errorCallback: (e) => {
        console.log('errorCallback');
        if (msg.loading) {
          msg.loading = false;
        }

        if (!msg.content) {
          msg.ok = false;
          msg.content = e.message;
        }

        // 在这里更新金币余额
        dispatch(fetchUserInfo());
        // 保存数据
        setCurrentMessage({ ...msg });
        if (!messages.includes(msg)) {
          messages.push(msg);
        }
        setCurrentMessages([...messages]);
        // 更新数据库中的数据
        updateFormAppMessages_dataStore(app, [...messages]);

        setTimeout(() => {
          animateScroll.scrollToBottom({
            containerId: 'formAppDetailElement',
            smooth: true,
            delay: 0,
            duration: 500
          });
        },50)
      }
    }).then(res => {
      console.log(res);
    }).catch(e => {
      // console.log(e);
    })


    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'chatContainerElement',
        smooth: true,
        delay: 0,
        duration: 500
      });
    },50)
  }

  function showHistoryModal() {
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    setChatHistoryModalOpen(true);
  }

  function handleUpdate() {
    getFormApp_dataStore(app.id).then(data => {
      setFormAppConversation(data);
    })
  }

  return app && (
    <div className={ open ? 'form-app-detail is-show' : 'form-app-detail' } id="formAppDetailElement">
      <div className="form-app-detail-inner">
        <div className="detail-back" onClick={() => onOpen(false)}>
          <Tooltip title="返回">
            <ArrowLeftOutlined className="detail-back-icon" />
          </Tooltip>
        </div>
        <div className="detail-title">
          <img className="avatar-img" src={app.avatar_url} alt=""/>
          <div className="title-text">{ app.name }</div>
        </div>
        <div className="detail-description">{ app.introduction }</div>
        <div className="detail-greeting">{ app.greeting }</div>
        <div className="detail-commands">
          {
            app.settings.form &&
            app.settings.form.fields &&
            app.settings.form.fields.length === 1 &&
            ['text', 'textarea'].includes(app.settings.form.fields[0].type_id) &&
            app.settings.commands &&
            app.settings.commands.map(item => {
              return (<div key={item} className="detail-command" onClick={() => chooseText(item)}>{item}</div>)
            })
          }
        </div>
        <div className="detail-form">
          <Form
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            {
              app.settings.form && app.settings.form.fields && app.settings.form.fields.map((item => {
                if (item.type_id === FormAppInputType.text) {
                  // 单行文本输入
                  return (<Form.Item
                    key={item.id}
                    label={item.name}
                    name={item.id}
                    rules={[
                      {
                        required: item.is_required,
                      }
                    ]}
                  >
                    <Input placeholder={item.placeholder} />
                  </Form.Item>)
                } else if (item.type_id === FormAppInputType.textarea) {
                  // 本文域输入
                  return (<Form.Item
                    key={item.id}
                    label={item.name}
                    name={item.id}
                    rules={[
                      {
                        required: item.is_required,
                      }
                    ]}
                  >
                    <Input.TextArea className="form-app-input-textarea" autoSize={{ minRows: 5 }} placeholder={item.placeholder} />
                  </Form.Item>)
                } else if (item.type_id === FormAppInputType.radio) {
                  // radio单选类型的
                  return null
                } else if (item.type_id === FormAppInputType.checkbox) {
                  // checkbox多选类型的
                  return null
                } else if (item.type_id === FormAppInputType.file) {
                  // file上传文件类型的
                  return null
                }
              }))
            }
            <Form.Item>
              <div className="form-btn-list">
                <div className="left">
                  <Button className="form-btn" ghost type="primary" onClick={() => showHistoryModal()}>历史记录</Button>
                  <Popover content={<FormSettingPopover app={app} form_app_conversation={formAppConversation} onUpdate={handleUpdate} />} title="参数调整" trigger="click">
                    <Button className="form-btn" type="default">参数调整</Button>
                  </Popover>
                </div>
                <div className="right">
                  <Button className="form-btn" onClick={() => form.resetFields()}>清空</Button>
                  <Button className="form-btn" type="primary" htmlType="submit">提交</Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
        {
          process.env.REACT_APP_ENV === 'dev' && (<div className="test-part-send-type">
            <span>测试切换发送选项：</span>
            <Radio.Group buttonStyle="solid"
                         onChange={(e) => setIsStream(e.target.value)} value={isStream}
                         style={{ margin: '0 20px 0 20px' }}>
              <Radio.Button value={false}>普通模式</Radio.Button>
              <Radio.Button value={true}>高速模式</Radio.Button>
            </Radio.Group>
            <Radio.Group buttonStyle="solid"
                         onChange={(e) => setIsMock(e.target.value)} value={isMock}>
              <Radio.Button value={true}>模拟发送</Radio.Button>
              <Radio.Button value={false}>真实发送</Radio.Button>
            </Radio.Group>
          </div>)
        }
        {
          !!currentMessage && (
            <div className="detail-output-box">
              <div className="output-title">生成结果：</div>
              <div className={ currentMessage.ok ? 'output-text' : 'output-text output-text-error' }>
                <Markdown message={currentMessage}></Markdown>
              </div>
            </div>
          )
        }
      </div>

      <ChatHistoryModal
        type="form_app"
        app={app}
        conversation_id={app ? app.id : null}
        open={chatHistoryModalOpen}
        onOpen={setChatHistoryModalOpen}></ChatHistoryModal>
    </div>
  )
}
