// 页面整体布局
import Navbar from './navbar';
import LoginModal from "@/components/UserInfoChangeModal/LoginModal";
import { Outlet } from 'react-router-dom';
// 样式
import './layout.scss';
// store
import { useSelector, useDispatch } from 'react-redux';
import { fetchSea, fetchUserInfo, setLoginVisible } from '@/store/modules/userStore';
import {
  setApps,
  setConversations,
  setActiveConversationId,
  clearChatStore, setIsChatBarHide
} from '@/store/modules/chatStore';
// 其他
import { useEffect, useState } from 'react';
import {App, Spin} from 'antd';
import {
  antdUtils,
  saveUserInfo_dataStore,
  NavContext,
  createConversationObj,
  saveConversations_dataStore
} from '@/utils'
import {getUserInfo_dataStore} from "@/utils";
import { navList, NavPage } from '@/config/nav';
import {newUserInitChatNumber} from "@/config/chat";

const Layout = () => {
  const { notification, message, modal } = App.useApp();
  const dispatch = useDispatch();
  const { token, userId, is_new, loginVisible, default_ai_app, ai_apps } = useSelector(state => state.user);
  // const { botApps } = useSelector(state => state.chat);

  const [activeNav, setActiveNav] = useState(NavPage.chat);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    antdUtils.saveLoadingMethod(setLoading);
    antdUtils.setModalInstance(modal);
    antdUtils.setMessageInstance(message);
    antdUtils.setNotificationInstance(notification);
  }, [notification, message, modal]);

  useEffect(() => {
    antdUtils.setLoading && antdUtils.setLoading(true);
    dispatch(fetchSea()).then(() => {
      antdUtils.setLoading && antdUtils.setLoading(false);
      if (token) {
        // 如果token存在，则请求用户信息
        antdUtils.setLoading && antdUtils.setLoading(true);
        dispatch(fetchUserInfo()).then(() => {
          antdUtils.setLoading && antdUtils.setLoading(false);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (userId && default_ai_app) {
      // 如果拿到了用户id，则开始初始化
      initUserInfo(userId);
    }

    if (!userId && default_ai_app) {
      // 未登录数据
      reset();
    }
  }, [userId, default_ai_app]);

  async function reset() {
    dispatch(clearChatStore());
    const _apps = [default_ai_app, ...ai_apps];
    const _conversations = [];
    const firstConversation = createConversationObj(default_ai_app, 'init');
    _conversations.push(firstConversation);

    dispatch(setApps(_apps));
    dispatch(setConversations(_conversations))
    dispatch(setActiveConversationId(_conversations[0].id))
    dispatch(setIsChatBarHide(true));
  }

  async function initUserInfo(userId) {
    // 1、准备相关数据存储容器
    const _apps = [default_ai_app, ...ai_apps];
    const _conversations = [];

    // 2、从数据库中拿用户数据
    const userInfo = await getUserInfo_dataStore(userId);
    if (userInfo) {
      // 有用户数据，则拿用户数据，保存到store里
      userInfo.conversations.forEach(item => {
        const app = _apps.find((app => app.id === item.appId));
        // 动态给对话里赋新字段
        // if (!item.hasOwnProperty('popOpen')) {
        //   item.popOpen = false;
        // }
        // 过滤掉不存在app的对话
        if (app) {
          _conversations.push(item);
        }
      })
    } else {
      // 如果没有用户数据，第一次登录，则初始化数据库
      const firstConversation = createConversationObj(default_ai_app, 'init');
      await saveUserInfo_dataStore(userId, {
        config: {},
        conversations: [firstConversation], // 初始化对话
      });
      _conversations.push(firstConversation);
    }



    // 3、更新store里的数据
    dispatch(setApps(_apps));
    // 如果是新用户，则随机10个智能体对话
    // if (is_new && _conversations.length === 1) {
    //   const newArr = [...botApps].sort(() => Math.random() - 0.5).slice(0, newUserInitChatNumber);
    //   newArr.forEach(item => {
    //     const newConversation = createConversationObj(item, 'bot');
    //     _conversations.push(newConversation);
    //   })
    //   await saveConversations_dataStore(_conversations);
    // }
    if (_conversations.length === 1) {
      dispatch(setIsChatBarHide(true));
    } else {
      dispatch(setIsChatBarHide(false));
    }
    dispatch(setConversations(_conversations))
    // 设置默认激活的对话
    dispatch(setActiveConversationId(_conversations[0].id))
  }
  function changeActiveNav(nav) {
    setActiveNav(nav.id);
  }

  function setLoginModalOpen(open) {
    dispatch(setLoginVisible(open))
  }

  return (
    <div className="layout">
      {/* 导航栏 */}
      <div className="navbar">
        <Navbar list={navList} active={activeNav} onChange={changeActiveNav} />
      </div>
      {/* 主体区域 */}
      <NavContext.Provider value={{ setActiveNav }}>
        <div className="main">
          <Outlet></Outlet>
        </div>
      </NavContext.Provider>
      {/* 全局loading */}
      <Spin spinning={loading} fullscreen style={{ zIndex: 9999 }} />
      {/* 登录框 */}
      <LoginModal open={loginVisible} onOpen={setLoginModalOpen}></LoginModal>
    </div>
  )
}

export default Layout;
