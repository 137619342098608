import { Button } from 'antd';
import Icon from '@ant-design/icons';
import {CoinSvg} from "@/components/svg";
import {useDispatch, useSelector} from 'react-redux';
import {useEffect,useState} from "react";
import {getCoinLogsApi} from "@/api/payment";
import {antdUtils} from "@/utils";
import {useNavigate} from "react-router-dom";
import {RouterPath} from "@/config/nav";
import CoinLogsModal from "@/pages/user/CoinLogsModal";
import {fetchUserInfo} from "@/store/modules/userStore";

export default function Member() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, plan, period_coins_end_at, period_coins, plan_end_at, lifetime_coins } = useSelector(state => state.user);
  const [coinLogs, setCoinLogs] = useState([]);

  const [coinLogsModalOpen, setCoinLogsModalOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserInfo());
      getCoinLogs();
    }
  }, [userId]);

  async function getCoinLogs() {
    try {
      antdUtils.setLoading && antdUtils.setLoading(true);
      const res = await getCoinLogsApi({ page_no: 1, page_size: 10 });
      setCoinLogs(res.list);
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading(false);
    }
  }

  return (<div className="member-box">
    <div className="member-header">
      <div className="member-info">
        <div className="info-title">当前金币</div>
        <div className="next-time">下次刷新时间: {period_coins_end_at}</div>
        <div className="coins-amount">
          <div className="coins-amount-item">
            <div className="item-top">
              <Icon className="coins-icon" component={CoinSvg}></Icon>
              <span className="coins-number">{period_coins}</span>
            </div>
            <div className="item-bottom">每月金币</div>
          </div>
          <span className="plus">+</span>
          <div className="coins-amount-item">
            <div className="item-top">
              <Icon className="coins-icon" component={CoinSvg}></Icon>
              <span className="coins-number">{lifetime_coins}</span>
            </div>
            <div className="item-bottom">终身金币</div>
          </div>
        </div>
        <div className="level-info">
          <div className="level-name">{ plan.name }</div>
          <div className="look-all link-text" onClick={() => navigate(RouterPath.recharge)}>查看所有套餐</div>
        </div>
        { !!plan_end_at && <div className="user-deadline success-color">到期时间：{plan_end_at}</div> }
        <Button className="update-btn" type="primary" size="large" shape="round" onClick={() => navigate(RouterPath.recharge)}>升级</Button>
      </div>
      <div className="coins-detail">
        <div className="coins-detail-title">
          <span style={{ marginRight: '10px' }}>金币明细</span>
          <Button type="link" size="small" onClick={() => setCoinLogsModalOpen(true)}>查看详情</Button>
        </div>
        <div className="coins-detail-list">

          {
            coinLogs.length ? (coinLogs.map(item => {
              return (<div key={item.id} className="coins-detail-item">
                <div className="coins-detail-item-label">{item.event_name}</div>
                <div className={item.coins_changes > 0 ? 'coins-detail-item-value success-color' : 'coins-detail-item-value error-color'}>
                  {item.coins_changes}
                </div>
              </div>)
            })) : (<div className="empty-coins-detail">（暂无数据）</div>)
          }
        </div>
      </div>
    </div>
    <div className="earn-coins">
      <div className="earn-coins-title">获取金币</div>
      <div className="earn-coins-des">需要金币？以下是赚取金币的方法</div>
      <div className="earn-coins-source">
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">升级会员</div>
          <div className="earn-item-des gray-color">升级您的会员资格，获得每月刷新的金币</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">马上升级</Button>
        </div>
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">邀请好友</div>
          <div className="earn-item-des">您和您的朋友可以赚取更多金币！</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">邀请好友</Button>
        </div>
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">购买</div>
          <div className="earn-item-des">直接购买金币</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">立即购买</Button>
        </div>
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">获取奖励</div>
          <div className="earn-item-des">创建并发布精彩作品！</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">发布作品</Button>
        </div>
      </div>
    </div>
    <div className="earn-coins-other">
      <div className="earn-coins-other-title">赚取金币的其他方式</div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">10金币</span>
        </div>
        <div className="other-item-content">每推荐一个人</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">1000金币</span>
        </div>
        <div className="other-item-content">每推荐一位订阅者（不封顶）</div>
        <div className="other-item-max gray-color">0</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">0.1金币</span>
        </div>
        <div className="other-item-content">每天关注不同的作者</div>
        <div className="other-item-max gray-color">0/10 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">0.1金币</span>
        </div>
        <div className="other-item-content gray-color">每天为其他作品互动</div>
        <div className="other-item-max gray-color">0/10 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">1金币</span>
        </div>
        <div className="other-item-content">每天发表的作品</div>
        <div className="other-item-max gray-color">0/10 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">1金币</span>
        </div>
        <div className="other-item-content">每次用户再创作你的作品</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">1金币</span>
        </div>
        <div className="other-item-content">每次有用户喜欢你的作品</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">2金币</span>
        </div>
        <div className="other-item-content">每次用户下载你的作品</div>
        <div className="other-item-max gray-color">0/200 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">1金币</span>
        </div>
        <div className="other-item-content">每一份被接受的举报</div>
        <div className="other-item-max gray-color">0/10 每月</div>
      </div>
    </div>
    <div className="split-line"></div>
    <div className="earn-coins">
      <div className="earn-coins-title">花费金币</div>
      <div className="earn-coins-des">已经有金币了？可以用它来做以下事情</div>
      <div className="earn-coins-source">
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">生成图片</div>
          <div className="earn-item-des">持续创作，用你的创造力改变世界</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">图片创作</Button>
        </div>
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">生成视频</div>
          <div className="earn-item-des">将你的想法变为现实！创作更有生命力的作品</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">视频创作</Button>
        </div>
        <div className="earn-item">
          <Icon className="earn-item-icon" component={CoinSvg}></Icon>
          <div className="earn-item-title">下载他人作品</div>
          <div className="earn-item-des">直接下载其他杰出作者发表的作品</div>
          <Button className="earn-item-btn" type="primary" ghost shape="round" size="small">立即购买</Button>
        </div>
      </div>
    </div>
    <div className="earn-coins-other">
      <div className="earn-coins-other-title">花费金币的方式</div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">10金币</span>
        </div>
        <div className="other-item-content gray-color">每推荐一个人</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">10金币</span>
        </div>
        <div className="other-item-content gray-color">每推荐一个人</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
      <div className="earn-coins-other-item">
        <div className="other-item-price">
          <Icon className="other-item-price-icon" component={CoinSvg}></Icon>
          <span className="other-item-price-text">10金币</span>
        </div>
        <div className="other-item-content gray-color">每推荐一个人</div>
        <div className="other-item-max gray-color">0/100 每日</div>
      </div>
    </div>
    <CoinLogsModal open={coinLogsModalOpen} onOpen={setCoinLogsModalOpen}></CoinLogsModal>
  </div>)
}
