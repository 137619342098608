import { createSlice } from '@reduxjs/toolkit';
import { saveConversations_dataStore } from '@/utils';
import { AppType } from '@/config/chat';

const ChatStore = createSlice({
  name: 'chat',
  // 初始化状态
  initialState: {
    // app数据
    apps: [], // 所有的app池（包括默认）
    appsMap: {}, // 所有app池的Id map映射（包括默认）
    botApps: [], // 智能体app池（不包括默认）
    formApps: [], // 表单应用池（不包括默认）
    // 对话数据
    conversations: [], // 对话列表
    activeConversationId: '', // 激活的对话Id
    activeConversation: null, // 激活的对话信息 （随着上述Id变化而动态变化）
    activeConversationApp: null, // 激活的app信息 （随着上述id变化而动态变化）
    // 聊天列表显示隐藏状态
    isChatBarHide: true,
  },
  // 同步方法
  reducers: {
    setApps(state, action) {
      const apps = action.payload;
      const botApps = [];
      const formApps = [];

      apps.forEach((item, index) => {
        state.appsMap[item.id] = item;

        if (index > 0) {
          // 排除掉第一个默认的，其他的进行应用分类
          if (item.type_id === AppType.bot) {
            botApps.push(item);
          } else if (item.type_id === AppType.form) {
            formApps.push(item);
          }
        }
      })

      state.apps = apps;
      state.botApps = botApps;
      state.formApps = formApps;
    },
    setConversations(state, action) {
      state.conversations = action.payload;
    },
    setActiveConversationId(state, action) {
      const id = action.payload;
      state.activeConversationId = id;
      state.activeConversation = state.conversations.find((item) => item.id === id);
      state.activeConversationApp = state.appsMap[state.activeConversation.appId];
    },
    // 更新对话里的消息数据，传messages过来
    updateActiveConversationMessages(state, action) {
      const { type, messages } = action.payload;
      // 对原有数据进行替换
      const newConversations = [...state.conversations];
      const index = newConversations.findIndex(item => item.id === state.activeConversationId);
      const item = { ...newConversations.find(item => item.id === state.activeConversationId) };
      // 移除不需要的属性
      const newMessages = [];
      messages.forEach(item => {
        const msg = { ...item };
        delete msg.loading;
        newMessages.push(msg);
      })
      item.messages = newMessages;
      // 更新时间（最新一条消息的创建时间）
      const length = item.messages.length;
      if (length) {
        item.updated_at = item.messages[length - 1].created_at;
      }
      newConversations[index] = item;
      // 如果是type是add类型的，则将对话从列表中向上升序
      if (type === 'add' && length) {
        if (item.isTop) {
          // 如果当前对话是置顶中的对话（非默认的），则将其从置顶中升序
          if (index > 0) {
            newConversations.splice(index, 1);
            newConversations.splice(1, 0, item);
          }
        } else {
          // 如果是非置顶的，则将其从非置顶中升序
          newConversations.splice(index, 1);
          // 找到非置顶的第一个位置
          const curNonTopIndex = newConversations.findIndex(item => !item.isTop);
          if (curNonTopIndex > 0) {
            newConversations.splice(curNonTopIndex, 0, item);
          } else {
            newConversations.push(item);
          }
        }
      }
      // 更新store数据
      state.conversations = newConversations;
      state.activeConversation = item;
      // 更新数据库中的数据
      saveConversations_dataStore(newConversations);
    },
    // 更新对话名称
    updateConversationName(state, action) {
      const { conversationId, name } = action.payload;
      const newConversations = [...state.conversations];
      const index = newConversations.findIndex(item => item.id === conversationId);
      newConversations[index].name = name;
      // 更新store数据
      state.conversations = newConversations;
      state.activeConversation = newConversations.find((item) => item.id === state.activeConversationId);
      // 更新数据库中的数据
      saveConversations_dataStore(newConversations);
    },
    // 更新对话参数
    updateConversationSettings(state, action) {
      const { conversationId, settings } = action.payload;
      const newConversations = [...state.conversations];
      const index = newConversations.findIndex(item => item.id === conversationId);
      Object.assign(newConversations[index], settings);
      // 更新store数据
      state.conversations = newConversations;
      state.activeConversation = newConversations.find((item) => item.id === state.activeConversationId);
      // 更新数据库中的数据
      saveConversations_dataStore(newConversations);
    },
    // 设置对话置顶
    updateConversationTop(state, action) {
      const { conversationId, isTop } = action.payload;
      const topConversations = [];
      const normalConversations = [];
      state.conversations.forEach((item, index) => {
        if (item.isTop) {
          topConversations.push(item);
        } else {
          normalConversations.push(item);
        }
      })

      // 如果是置顶的，则将该对话排序提上去
      if (isTop) {
        let index = normalConversations.findIndex(item => item.id === conversationId);
        const [item] = normalConversations.splice(index, 1);
        item.isTop = isTop;
        topConversations.splice(1,0,item);
      } else {
        let index = topConversations.findIndex(item => item.id === conversationId);
        const [item] = topConversations.splice(index, 1);
        item.isTop = isTop;
        normalConversations.unshift(item);
      }

      const newConversations = [...topConversations, ...normalConversations];
      // 更新store数据
      state.conversations = newConversations;
      state.activeConversation = newConversations.find((item) => item.id === state.activeConversationId);
      // 更新数据库中的数据
      saveConversations_dataStore(newConversations);
    },
    // 删除对话
    deleteConversation(state, action) {
      const id = action.payload;
      const newConversations = state.conversations.filter(item => item.id !== id);
      // 更新store数据
      state.conversations = newConversations;
      const activeId = newConversations[0].id;
      state.activeConversationId = activeId;
      state.activeConversation = newConversations.find((item) => item.id === activeId);
      state.activeConversationApp = state.appsMap[state.activeConversation.appId];
      // 更新数据库中的数据
      saveConversations_dataStore(newConversations);
    },

    setIsChatBarHide(state, action) {
      state.isChatBarHide = action.payload;
    },

    clearChatStore(state) {
      state.conversations = [];
      state.activeConversationId = '';
      state.activeConversation = null;
      state.activeConversationApp = null;
    }
  }
})

const {
  setApps,
  setConversations,
  setActiveConversationId,
  updateActiveConversationMessages,
  updateConversationName,
  updateConversationSettings,
  updateConversationTop,
  deleteConversation,

  setIsChatBarHide,
  clearChatStore,
} = ChatStore.actions;

const reducer = ChatStore.reducer;

export {
  setApps,
  setConversations,
  setActiveConversationId,
  updateActiveConversationMessages,
  updateConversationName,
  updateConversationSettings,
  updateConversationTop,
  deleteConversation,

  setIsChatBarHide,
  clearChatStore,
};
export default reducer;
