import { Button } from 'antd';
import useCopy from "@/hooks/useCopy";
import {useSelector} from "react-redux";
import {useMemo} from "react";

export default function Invite() {
  const { code } = useSelector((state) => state.user);
  const { handleCopy } = useCopy();

  const inviteUrl = useMemo(() => {
    return window.location.origin + '/join?invite=' + code;
  }, [code]);


  return (<div className="invite-box">
    <div className="invite-header">
      <span className="invite-header-text">邀请好友使用神采，即可赚取免费获取金币。</span>
      <Button type="primary" ghost size="small" shape="round">邀请规则</Button>
    </div>
    <div className="invite-section">
      <div className="invite-section-title">给予 10 金币，获得 10 金币</div>
      <div className="invite-section-des">您推荐的每个人都会获得 10 个金币（每天限制最多100个）。一旦他们在我们这里创建了帐户，您也将获得10 个金币。</div>
      <div className="invite-section-des">通过推荐获得的金币不会过期，并且您可以获得的数量没有限制。</div>
      <div className="invite-referral">
        <div className="invite-url">{inviteUrl}</div>
        <Button className="invite-url-btn" type="primary" ghost size="large" onClick={() => handleCopy(inviteUrl)}>复制链接</Button>
        {/*<Button className="invite-url-btn" type="primary" ghost size="large">分享</Button>*/}
      </div>
    </div>
    <div className="invite-section">
      <div className="invite-section-title">我的邀请记录</div>
      <div className="invite-data">
        <div className="invite-data-item">
          <div className="invite-data-item-label">邀请成功人数 :</div>
          <div className="invite-data-item-value">0</div>
        </div>
        <div className="invite-data-item">
          <div className="invite-data-item-label">已获取生成金币 :</div>
          <div className="invite-data-item-value">0</div>
        </div>
      </div>
    </div>
    <div className="more-rewards">
      <div className="more-rewards-title">更多奖励</div>
      <div className="all-rewards">
        <div className="reward-item">
          <div className="one-reward">01</div>
          <div className="two-reward"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjtJREFUeNrsmk8og2Ecx7fXRMyfy9aKi5V/xQ2xUohlRQ05khMHB+WkxgUtkTs5OGwOHBypkZtNKCKZrB3koHGQacllvg+Pelvva+/77p3edz2/+rR69nt/7/N5ev68vb3GZDJpEItoNJraNAkWwRJYBuIXK49esAJCYDz1T7vdLngRJ+MGRjADyqnIHrCoKJAPVmndBjAKSqReLEekGVSmjNwFaFdBogocg2k6YCQKgCsbIm6BtgpwBGZl1uLHMB2QZoH/BrIhMijSbgILYF/mVCsEa2AHlInkuOiUU02kHtSmyXGCK9ApoV4dOAUTafKIYIeaIoMS82zgAMyBPJGcMXAOGiXW7FNTxC1jyhCBeSpk47WbgQ9sgmIZ9Xokbal+ny9tUpvD8SZnK+TFExgBL2Ab1CiokQgFg2nFTfS3mp4N3aA0NQmFZN8d8vyplkmtIpGDlwzuIT3b7k10EZ/Qg05PUUrXbhdoJWvEq0MJfpC+ezk6nfQeTk5oTegwzJwhR4KJaFHkLQc83jl6qOg9AkTEA151LEH67iEiYXIygl0Q15FAnPaZ9D38+6x1B4b4WRarNaMXC5FIRLAddTPq/XMsZszpXcso9DooEAgYsvSqR5U+fz+TOJ3sQGQiTISJMBEmwkSYCBNhIkyEiTARJsJEdC6S0GB/E0pErjQocqNExK9BkS0lIhvgUkMSt2Bdicgn6AfXGpAg05x8jfShdNd6BE1gyvDz7Ujinxf2Gb13C3j4K/lLgAEASNZosoJG9LAAAAAASUVORK5CYII=" /></div>
          <div className="three-reward">需邀请5个新用户解锁</div>
          <div className="four-reward">1日VIP</div>
        </div>
        <div className="reward-item">
          <div className="one-reward">02</div>
          <div className="two-reward"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjtJREFUeNrsmk8og2Ecx7fXRMyfy9aKi5V/xQ2xUohlRQ05khMHB+WkxgUtkTs5OGwOHBypkZtNKCKZrB3koHGQacllvg+Pelvva+/77p3edz2/+rR69nt/7/N5ev68vb3GZDJpEItoNJraNAkWwRJYBuIXK49esAJCYDz1T7vdLngRJ+MGRjADyqnIHrCoKJAPVmndBjAKSqReLEekGVSmjNwFaFdBogocg2k6YCQKgCsbIm6BtgpwBGZl1uLHMB2QZoH/BrIhMijSbgILYF/mVCsEa2AHlInkuOiUU02kHtSmyXGCK9ApoV4dOAUTafKIYIeaIoMS82zgAMyBPJGcMXAOGiXW7FNTxC1jyhCBeSpk47WbgQ9sgmIZ9Xokbal+ny9tUpvD8SZnK+TFExgBL2Ab1CiokQgFg2nFTfS3mp4N3aA0NQmFZN8d8vyplkmtIpGDlwzuIT3b7k10EZ/Qg05PUUrXbhdoJWvEq0MJfpC+ezk6nfQeTk5oTegwzJwhR4KJaFHkLQc83jl6qOg9AkTEA151LEH67iEiYXIygl0Q15FAnPaZ9D38+6x1B4b4WRarNaMXC5FIRLAddTPq/XMsZszpXcso9DooEAgYsvSqR5U+fz+TOJ3sQGQiTISJMBEmwkSYCBNhIkyEiTARJsJEdC6S0GB/E0pErjQocqNExK9BkS0lIhvgUkMSt2Bdicgn6AfXGpAg05x8jfShdNd6BE1gyvDz7Ujinxf2Gb13C3j4K/lLgAEASNZosoJG9LAAAAAASUVORK5CYII=" /></div>
          <div className="three-reward">需邀请10个新用户解锁</div>
          <div className="four-reward">3日VIP</div>
        </div>
        <div className="reward-item">
          <div className="one-reward">03</div>
          <div className="two-reward"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjtJREFUeNrsmk8og2Ecx7fXRMyfy9aKi5V/xQ2xUohlRQ05khMHB+WkxgUtkTs5OGwOHBypkZtNKCKZrB3koHGQacllvg+Pelvva+/77p3edz2/+rR69nt/7/N5ev68vb3GZDJpEItoNJraNAkWwRJYBuIXK49esAJCYDz1T7vdLngRJ+MGRjADyqnIHrCoKJAPVmndBjAKSqReLEekGVSmjNwFaFdBogocg2k6YCQKgCsbIm6BtgpwBGZl1uLHMB2QZoH/BrIhMijSbgILYF/mVCsEa2AHlInkuOiUU02kHtSmyXGCK9ApoV4dOAUTafKIYIeaIoMS82zgAMyBPJGcMXAOGiXW7FNTxC1jyhCBeSpk47WbgQ9sgmIZ9Xokbal+ny9tUpvD8SZnK+TFExgBL2Ab1CiokQgFg2nFTfS3mp4N3aA0NQmFZN8d8vyplkmtIpGDlwzuIT3b7k10EZ/Qg05PUUrXbhdoJWvEq0MJfpC+ezk6nfQeTk5oTegwzJwhR4KJaFHkLQc83jl6qOg9AkTEA151LEH67iEiYXIygl0Q15FAnPaZ9D38+6x1B4b4WRarNaMXC5FIRLAddTPq/XMsZszpXcso9DooEAgYsvSqR5U+fz+TOJ3sQGQiTISJMBEmwkSYCBNhIkyEiTARJsJEdC6S0GB/E0pErjQocqNExK9BkS0lIhvgUkMSt2Bdicgn6AfXGpAg05x8jfShdNd6BE1gyvDz7Ujinxf2Gb13C3j4K/lLgAEASNZosoJG9LAAAAAASUVORK5CYII=" /></div>
          <div className="three-reward">需邀请20个新用户解锁</div>
          <div className="four-reward">7日VIP</div>
        </div>
        <div className="reward-item">
          <div className="one-reward">04</div>
          <div className="two-reward"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjtJREFUeNrsmk8og2Ecx7fXRMyfy9aKi5V/xQ2xUohlRQ05khMHB+WkxgUtkTs5OGwOHBypkZtNKCKZrB3koHGQacllvg+Pelvva+/77p3edz2/+rR69nt/7/N5ev68vb3GZDJpEItoNJraNAkWwRJYBuIXK49esAJCYDz1T7vdLngRJ+MGRjADyqnIHrCoKJAPVmndBjAKSqReLEekGVSmjNwFaFdBogocg2k6YCQKgCsbIm6BtgpwBGZl1uLHMB2QZoH/BrIhMijSbgILYF/mVCsEa2AHlInkuOiUU02kHtSmyXGCK9ApoV4dOAUTafKIYIeaIoMS82zgAMyBPJGcMXAOGiXW7FNTxC1jyhCBeSpk47WbgQ9sgmIZ9Xokbal+ny9tUpvD8SZnK+TFExgBL2Ab1CiokQgFg2nFTfS3mp4N3aA0NQmFZN8d8vyplkmtIpGDlwzuIT3b7k10EZ/Qg05PUUrXbhdoJWvEq0MJfpC+ezk6nfQeTk5oTegwzJwhR4KJaFHkLQc83jl6qOg9AkTEA151LEH67iEiYXIygl0Q15FAnPaZ9D38+6x1B4b4WRarNaMXC5FIRLAddTPq/XMsZszpXcso9DooEAgYsvSqR5U+fz+TOJ3sQGQiTISJMBEmwkSYCBNhIkyEiTARJsJEdC6S0GB/E0pErjQocqNExK9BkS0lIhvgUkMSt2Bdicgn6AfXGpAg05x8jfShdNd6BE1gyvDz7Ujinxf2Gb13C3j4K/lLgAEASNZosoJG9LAAAAAASUVORK5CYII=" /></div>
          <div className="three-reward">需邀请50个新用户解锁</div>
          <div className="four-reward">15日VIP</div>
        </div>
        <div className="reward-item">
          <div className="one-reward">05</div>
          <div className="two-reward"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjtJREFUeNrsmk8og2Ecx7fXRMyfy9aKi5V/xQ2xUohlRQ05khMHB+WkxgUtkTs5OGwOHBypkZtNKCKZrB3koHGQacllvg+Pelvva+/77p3edz2/+rR69nt/7/N5ev68vb3GZDJpEItoNJraNAkWwRJYBuIXK49esAJCYDz1T7vdLngRJ+MGRjADyqnIHrCoKJAPVmndBjAKSqReLEekGVSmjNwFaFdBogocg2k6YCQKgCsbIm6BtgpwBGZl1uLHMB2QZoH/BrIhMijSbgILYF/mVCsEa2AHlInkuOiUU02kHtSmyXGCK9ApoV4dOAUTafKIYIeaIoMS82zgAMyBPJGcMXAOGiXW7FNTxC1jyhCBeSpk47WbgQ9sgmIZ9Xokbal+ny9tUpvD8SZnK+TFExgBL2Ab1CiokQgFg2nFTfS3mp4N3aA0NQmFZN8d8vyplkmtIpGDlwzuIT3b7k10EZ/Qg05PUUrXbhdoJWvEq0MJfpC+ezk6nfQeTk5oTegwzJwhR4KJaFHkLQc83jl6qOg9AkTEA151LEH67iEiYXIygl0Q15FAnPaZ9D38+6x1B4b4WRarNaMXC5FIRLAddTPq/XMsZszpXcso9DooEAgYsvSqR5U+fz+TOJ3sQGQiTISJMBEmwkSYCBNhIkyEiTARJsJEdC6S0GB/E0pErjQocqNExK9BkS0lIhvgUkMSt2Bdicgn6AfXGpAg05x8jfShdNd6BE1gyvDz7Ujinxf2Gb13C3j4K/lLgAEASNZosoJG9LAAAAAASUVORK5CYII=" /></div>
          <div className="three-reward">需邀请100个新用户解锁</div>
          <div className="four-reward">30日VIP</div>
        </div>
      </div>
    </div>
  </div>)
}
