import './index.scss';
import Icon from '@ant-design/icons';
import {DevelopmentSvg} from "@/components/svg";
import CommonHeader from "@/components/CommonHeader";

function Draw() {
  return (
    <div className="draw-container">
      {/* 头部 */}
      <CommonHeader title="AI绘画" />
      <div className="draw-body">
        <Icon component={DevelopmentSvg} className="development-icon"></Icon>
        <div className="development-icon-text">开发中...</div>
      </div>
    </div>
  )
}

export default Draw;
