import { request } from '@/utils';

// --------  支付/金币相关 ---------
// 订单-确认
export const orderPrepareApi = (params) =>
  request.get('/v1/user/payment-orders/prepare', { params })

// 订单-支付（根据通道id生成订单号）
export const orderPaymentApi = (data) =>
  request.post('/v1/user/payment-orders', data)

// 获取订单支付的状态
export const getOrderStatusApi = (order) =>
  request.get(`/v1/user/payment-orders/${order}/status`)

// 设置支付状态（开发用） status_id：unpaid=未支付,failed=已失败,paid=已支付
export const updateOrderStatusApi = (order, status_id) =>
  request.put(`/v1/user/payment-orders/${order}/status`, { status_id })

// 订单-列表（分页）
export const getOrderPaymentApi = (params) =>
  request.get('/v1/user/payment-orders', { params })

// 金币明细（分页）
export const getCoinLogsApi = (params) =>
  request.get('/v1/user/coins-logs', { params })
