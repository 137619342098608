// 写一个方法
class AntdUtils {
  message = null;
  notification = null;
  modal = null;
  setLoading = null;

  setMessageInstance(instance) {
    this.message = instance;
  }

  setNotificationInstance(instance) {
    this.notification = instance;
  }

  setModalInstance(instance) {
    this.modal = instance;
  }

  saveLoadingMethod(fn) {
    this.setLoading = fn;
  }
}

export const antdUtils = new AntdUtils();
