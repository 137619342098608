// 封装和token相关的方法

const TOKEN_KEY = 'token_key';
const USER_ID_KEY = 'user_id_key';
const THEME_KEY = 'theme_key';
const IS_MOCK_KEY = 'is_mock_key';

export function setStorageToken(token) {
  localStorage.setItem(TOKEN_KEY, token)
}

export function getStorageToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function removeStorageToken() {
  localStorage.removeItem(TOKEN_KEY)
}

// 获取用户id

export function setStorageUserId(id) {
  localStorage.setItem(USER_ID_KEY, id)
}

export function getStorageUserId() {
  return localStorage.getItem(USER_ID_KEY)
}

export function removeStorageUserId() {
  localStorage.removeItem(USER_ID_KEY)
}

// 用户主题
export function setStorageTheme(theme) {
  localStorage.setItem(THEME_KEY, theme)
}

export function getStorageTheme() {
  return localStorage.getItem(THEME_KEY)
}

export function removeStorageTheme() {
  localStorage.removeItem(THEME_KEY)
}

// 模拟
export function setStorageIsMock(isMock) {
  localStorage.setItem(IS_MOCK_KEY, isMock)
}

export function getStorageIsMock() {
  const res = JSON.parse(localStorage.getItem(IS_MOCK_KEY));
  return res === null ? true : res;
}

export function removeStorageIsMock() {
  localStorage.removeItem(IS_MOCK_KEY)
}

