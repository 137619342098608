import copy from 'copy-to-clipboard';
import { App } from 'antd';

export default function() {
  const { message } = App.useApp();

  const handleCopy = (text, showSuccessMessage = true) => {
    if (copy(text)) {
      showSuccessMessage && message.success('复制成功');
    } else {
      message.error('复制失败');
    }
  };

  return { handleCopy };
}
