import {Modal, Table} from 'antd';
import {useEffect, useState} from 'react';
import {getCoinLogsApi} from "@/api/payment";
import {useSelector} from "react-redux";

export default function CoinLogsModal(props) {
  const { open, onOpen } = props;
  const { userId } = useSelector(state => state.user);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: '事件',
      align: 'center',
      dataIndex: 'event_name',
      width: '20%',
    },
    {
      title: '变动',
      align: 'center',
      dataIndex: 'coins_changes',
      render: (text) => <span className={text > 0 ? 'success-color' : 'error-color'}>{text}</span>,
      width: '12%',
    },
    {
      title: '明细',
      align: 'center',
      dataIndex: 'old_coins',
      render: (text, row) => <div>{row.old_coins} => <span>{row.new_coins}</span></div>,
      width: '30%',
    },
    {
      title: '备注',
      align: 'center',
      dataIndex: 'remark',
      width: '16%',
    },
    {
      title: '时间',
      align: 'center',
      dataIndex: 'created_at',
      width: '22%',
    },
  ];

  useEffect(() => {
    if (open && userId) {
      getCoinLogs();
    }
  }, [open, JSON.stringify(tableParams)]);

  async function getCoinLogs() {
    try {
      setTableLoading(true);
      const res = await getCoinLogsApi({
        page_no: tableParams.pagination.current,
        page_size: tableParams.pagination.pageSize
      });
      setTableData(res.list);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total_count,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    setTableParams({ pagination });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
    }
  };

  return (
    <Modal title="金币明细一览表"
           width="80%"
           open={open}
           footer={null}
           onCancel={() => onOpen(false)}>
      <div className="coin-logs-modal">
        <Table className="coin-logs-table"
               columns={columns}
               rowKey={(row) => row.id}
               pagination={tableParams.pagination}
               loading={tableLoading}
               onChange={handleTableChange}
               dataSource={tableData} />
      </div>
    </Modal>
  )
}
