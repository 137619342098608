import { configureStore } from '@reduxjs/toolkit';

import counterReducer from "./modules/counterStore";
import userReducer from "./modules/userStore";
import chatReducer from "./modules/chatStore";
import commonReducer from "./modules/commonStore";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    chat: chatReducer,
    common: commonReducer,
  }
});

export default store;
