import { fetchEventSource } from '@fortaine/fetch-event-source';
import { antdUtils } from '@/utils';
import { getStorageToken } from '@/utils'

const EventStreamContentType = 'text/event-stream';
function openCallback() {
  console.log('openCallback');
}

function messageCallback() {
  console.log('messageCallback');
}

function closeCallback() {
  console.log('closeCallback');
}

function errorCallback() {
  console.log('errorCallback');
}

export default function fetchES(url, data = {}, callback = {}) {
  // 默认的配置
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    SUCCESS_CODE: [0],
    UN_AUTH_CODE: [401],
    data,
    openCallback,
    messageCallback,
    closeCallback,
    errorCallback
  }
  // 合并参数
  Object.assign(config, callback);

  class RetriableError extends Error { }
  class FatalError extends Error { }

  return fetchEventSource(config.baseURL + url, {
    method: 'POST',
    headers: {
      'Accept': "text/event-stream",
      'Content-Type': 'application/json',
      'Token': getStorageToken(),
    },
    body: JSON.stringify(config.data),
    signal: new AbortController().signal,
    async onopen(response) {
      const contentType = response.headers.get('content-type')
      if (response.ok) {
        if (contentType === EventStreamContentType) {
          // 流数据格式
          console.log('open 流数据连接成功')
          config.openCallback && config.openCallback();
          return
        } else {
          // 普通json格式
          const json = await response.json()
          const code = json.code
          const message = json.message
          if (code === 0) {
            config.openCallback && config.openCallback();
            config.messageCallback && config.messageCallback(json.data);
            config.closeCallback && config.closeCallback();
          } else if (code == 401) {
            // antdUtils.message && antdUtils.message.error(message)
            throw new FatalError("未登录");
          } else {
            // antdUtils.message && antdUtils.message.error(message)
            throw new FatalError(message);
          }
        }
      } else {
        throw new FatalError("服务异常，请联系客服");
      }
    },
    onmessage(msg) {
      try {
        const res = JSON.parse(msg.data);
        if (config.SUCCESS_CODE.includes(res.code)) {
          config.messageCallback && config.messageCallback(res);
        } else {
          // 错误的返回结果，解析响应对象的消息报错到前端
          console.log('服务端返回错误消息，需要解析响应对象的消息报错到前端：');
          console.log(res.error);
          config.messageCallback && config.messageCallback({ content: res.error, isError: true });
        }
      } catch (e) {
        console.log(e);
        // config.messageCallback && config.messageCallback({ content: e.message, isError: true });
      }
      // if the server emits an error message, throw an exception
      // so it gets handled by the onerror callback below:
      if (msg.event === 'FatalError') {
        antdUtils.message && antdUtils.message.error(msg.data)
        config.messageCallback && config.messageCallback({ content: msg.data, isError: true });
        throw new FatalError(msg.data);
      }
    },
    onclose() {
      console.log("传输结束，断开链接。Connection closed by the server");
      config.closeCallback && config.closeCallback();
    },
    onerror(err) {
      console.log('----触发onerror---');
      console.dir(err);
      config.errorCallback && config.errorCallback(err);
      if (err instanceof FatalError) {
        console.log(err)
        antdUtils.message && antdUtils.message.error(err.message)
        throw err; // rethrow to stop the operation
      } else {
        // do nothing to automatically retry. You can also
        // return a specific retry interval here.
        throw err;
      }
    }
  });
}
