// 全局配置
export default {
  siteTheme: process.env.REACT_APP_SITE_THEME || 'dark', // 初始主题（dark-暗色 light-亮色） 开发中
  siteTitle: process.env.REACT_APP_SITE_TITLE || '星空智能',
  siteDescription: process.env.REACT_APP_SITE_DESCRIPTION || '快速高效的AI助理',
  monetaryUnit: process.env.REACT_APP_MONETARY_UNIT || '金币', // 货币单位
  initModel: '', // 默认算法模型 开发中
  developmentCompany: process.env.REACT_APP_DEVELOPMENT_COMPANY || '开发企业', // 开发企业
  feedbackEmail: process.env.REACT_APP_FEEDBACK_EMAIL || 'test123.@163.com', // 反馈邮箱
}
