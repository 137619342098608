import { request } from './request';
import {
  setStorageToken,
  getStorageToken,
  removeStorageToken,
  setStorageUserId,
  getStorageUserId,
  removeStorageUserId,
} from './token';
import {
  setStorageDraft,
  getStorageDrafts,
  removeStorageDrafts,
  setStorageChatConfig,
  getStorageChatConfig,
  removeStorageChatConfig,
} from './draft';
import { antdUtils } from './antd';
import { NavContext } from './context';
import SSE from './sse';
import fetchES from './fetchEventSource';
import {
  connect_dataStore,
  getUserInfo_dataStore,
  saveUserInfo_dataStore,
  saveConversations_dataStore,
  getConversation_dataStore,
  initFormApp_dataStore,
  getFormApp_dataStore,
  updateFormAppMessages_dataStore,
  updateFormApp_dataStore
} from './dataStore';
import { createConversationObj } from "@/utils/chat";

const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
const phoneReg = /^1[3-9]\d{9}$/;


export {
  request,
  // local
  setStorageToken,
  getStorageToken,
  removeStorageToken,
  setStorageUserId,
  getStorageUserId,
  removeStorageUserId,
  // session
  setStorageDraft,
  getStorageDrafts,
  removeStorageDrafts,
  setStorageChatConfig,
  getStorageChatConfig,
  removeStorageChatConfig,
  antdUtils,
  NavContext,
  SSE,
  emailReg,
  phoneReg,
  fetchES,
  connect_dataStore,
  getUserInfo_dataStore,
  saveUserInfo_dataStore,
  saveConversations_dataStore,
  getConversation_dataStore,
  initFormApp_dataStore,
  getFormApp_dataStore,
  updateFormAppMessages_dataStore,
  updateFormApp_dataStore,
  createConversationObj,
};
