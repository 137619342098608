import { useState, useEffect, useRef } from 'react';
import {getOrderStatusApi} from "@/api/payment";
import { loopInterval, loopMaxTime, qrcodeExpiredTime } from '@/config/recharge'

export default function useOrderLoop() {
  const timerRef = useRef(null);
  const startLoopTime = useRef(0); // 开始时间（毫秒）
  const [statusId, setStatusId] = useState('');
  // 二维码倒计时
  const qrcodeTimerRef = useRef(null);
  const [qrcodeExpiredCount, setQrcodeExpiredCount] = useState(0);
  const [isQrcodeExpired, setIsQrcodeExpired] = useState(false);

  // 监听订单状态的变化，成功或者失败则停止
  useEffect(() => {
    if (setStatusId && ['paid', 'failed'].includes(statusId)) {
      console.log('监听到订单支付成功/失败, 结束');
      resetLoop();
      resetCountDownQrCode();
    }
  }, [statusId]);

  useEffect(() => {
    if (qrcodeExpiredCount <= 0) {
      resetCountDownQrCode();
    }
  }, [qrcodeExpiredCount])

  async function requestApi(order_no) {
    try {
      const res = await getOrderStatusApi(order_no);
      console.log('--获取到订单状态-', res.status_id);
      setStatusId(res.status_id);
    } catch (e) {
      console.log(e);
    }
  }

  // 开始轮训
  function startLoop(order_no) {
    console.log('---- 开始 startLoop ----');
    // 重置订单状态
    setStatusId('');
    // 1、先记录轮训时间
    startLoopTime.current = new Date().getTime();
    // 2、设置轮训
    timerRef.current = setInterval(() => {
      // 每次轮训时，查看时间是否已经到截止上限
      const curTime = new Date().getTime();
      if ((curTime - startLoopTime.current) >= loopMaxTime) {
        // 到达上限，停止
        console.log('监听到轮训达到上限5分钟, 结束');
        resetLoop();
      } else {
        // 没到上限，继续请求
        requestApi(order_no);
      }
    }, loopInterval)

    // 返回轮训id
    return timerRef.current;
  }

  // 重置轮训
  function resetLoop() {
    console.log('---- 重置，停止 resetLoop ----');
    timerRef.current && clearInterval(timerRef.current);
    startLoopTime.current = 0;
  }

  // 二维码倒计时开始
  function startCountDownQrCode(qrcodeCreateTime) {
    setIsQrcodeExpired(false);
    // 将最新时间 - 创建时间，得到差值，开始计时
    const willExpiredTime = qrcodeCreateTime + qrcodeExpiredTime;
    const diffTime = willExpiredTime - new Date().getTime();
    if (diffTime < 0) {
      // 过期了
      setIsQrcodeExpired(true);
      return;
    }

    setQrcodeExpiredCount(Math.ceil(diffTime / 1000));

    qrcodeTimerRef.current = setInterval(() => {
      console.log('----二维码倒计时还在执行----');
      setQrcodeExpiredCount((prevState) => prevState - 1);
    }, 1000)

    return qrcodeTimerRef.current;
  }

  // 重置二维码倒计时
  function resetCountDownQrCode() {
    qrcodeTimerRef.current && clearInterval(qrcodeTimerRef.current);
    setQrcodeExpiredCount(0);
    setIsQrcodeExpired(true);
  }

  return {
    startLoop,
    resetLoop,
    statusId,
    setStatusId,
    startCountDownQrCode,
    resetCountDownQrCode,
    qrcodeExpiredCount,
    isQrcodeExpired
  }
};
