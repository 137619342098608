import {useState} from "react";
import { ChinaSvg } from '@/components/svg/area';
import Icon from '@ant-design/icons';

// 区号映射表
const AreaCodeMap = {
  china: {
    areaCode: '+86',
    icon: ChinaSvg
  }
}

function OptionLabel(props) {
  return (<div style={{ display: 'flex', alignItems: 'center' }}>
    <Icon style={{marginRight: '8px', fontSize: '22px'}} component={props.icon}></Icon>
    <span>{props.areaCode}</span>
  </div>)
}

export default function usePhoneAreaCode() {
  const areaOptions = Object.keys(AreaCodeMap).map((key) => ({
    label: <OptionLabel { ...AreaCodeMap[key] } />,
    value: AreaCodeMap[key].areaCode
  }));

  const [areaCode, setAreaCode] = useState(AreaCodeMap.china.areaCode);

  const resetAreaCode = () => {
    setAreaCode(AreaCodeMap.china.areaCode)
  }

  return { areaCode, setAreaCode, areaOptions, resetAreaCode }
}
