import globalConfig from '@/config';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginVisible } from '@/store/modules/userStore';
import { Button, Input, App } from 'antd';
import {useEffect, useState} from "react";
import {CoinSvg} from "@/components/svg";
import Icon from '@ant-design/icons';

export default function CoinPay(props) {
  const { show, onBuy } = props;
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const { userId, systemSettings, plan, rmb_to_coins_exchange_rate } = useSelector((state) => state.user);
  const [activeCoinPayId, setActiveCoinPayId] = useState('');
  const [coinPayOptions, setCoinPayOptions] = useState([]);
  const [customPrice, setCustomPrice] = useState('');
  const [customCoins, setCustomCoins] = useState('');

  useEffect(() => {
    if (systemSettings.buy_coins_quick_amounts && systemSettings.buy_coins_quick_amounts.length && userId) {
      const arr = [];

      const discount = plan.coins_discount;

      systemSettings.buy_coins_quick_amounts.forEach(coin => {
        // 根据当前coin和用户折扣，计算购买价格
        if (rmb_to_coins_exchange_rate > 0) {
          const price = ((coin / rmb_to_coins_exchange_rate) * (1 - discount)).toFixed(2);
          arr.push({
            price,
            coin,
            id: coin,
          })
        }
      })

      setCoinPayOptions(arr);
      setCustomCoins(systemSettings.place_order_min_amount * rmb_to_coins_exchange_rate)
    }
  }, [systemSettings, userId]);

  useEffect(() => {
    const price = Number.parseFloat(customPrice);
    if (Number.isFinite(price) && price > 0) {
      setCustomCoins(Math.ceil((price / (1 - plan.coins_discount) * rmb_to_coins_exchange_rate)).toFixed());
    } else {
      setCustomCoins(0);
    }
  }, [customPrice])

  function handleSelectCoinPay(id) {
    setActiveCoinPayId(id);
  }

  function getDiscountDescription(plan) {
    const discountStr = plan.coins_discount * 100 + '%';
    if (plan.level_id === 0) {
      return '（无购买优惠）'
    } else {
      return `（优惠${discountStr}）`;
    }
  }

  function handleBuy() {
    const params = {
      product_type_id: 'coins',
    }

    if (!activeCoinPayId) {
      return message.warning('请选择金币数量');
    }

    if (activeCoinPayId === 'custom') {
      // 如果是自定义金额的，进行验证（大于最小金额）
      if (!Number.isFinite(+customPrice) || (+customPrice < systemSettings.place_order_min_amount)) {
        return message.warning('购买金额不能低于 ¥' + systemSettings.place_order_min_amount);
      }
      params.coins = customCoins;
    } else {
      params.coins = activeCoinPayId;
    }

    onBuy(params);
  }

  if (userId) {
    return (
      <div className="coin-pay-view" style={{ display: show ? 'none' : 'flex' }}>
        <div className="coin-pay-title">购买{globalConfig.monetaryUnit}</div>
        {
          coinPayOptions.map(item => {
            return (<div key={item.id}
                         className={ activeCoinPayId === item.id ? 'coin-pay-select coin-pay-select-active' : 'coin-pay-select'}
                         onClick={() => handleSelectCoinPay(item.id)} >
              <div className="coin-pay-select-inner">
                <div className="coin-pay-select-left">¥ { item.price }</div>
                <div className="coin-pay-select-right">
                  <Icon component={CoinSvg} className="coin-icon"></Icon>
                  { item.coin }
                </div>
              </div>
            </div>)
          })
        }
        <div className={ activeCoinPayId === 'custom' ? 'coin-pay-select custom-select coin-pay-select-active' : 'coin-pay-select custom-select'}
             onClick={() => handleSelectCoinPay('custom')}>
          <div className="coin-pay-select-inner">
            <div className="coin-pay-select-custom">
              <div className="custom-title">自定义</div>
              <div className="custom-sub-title">金额</div>
              <div className="custom-input-warp">
                <Input value={customPrice}
                       onChange={(e) => setCustomPrice(e.target.value)}
                       prefix={<div>¥</div>}
                       placeholder={`最低金额${systemSettings.place_order_min_amount}`}
                       className="custom-input"></Input>
                <Input disabled
                       prefix={<Icon component={CoinSvg} className="coin-icon"></Icon>}
                       value={customCoins}
                       className="custom-input"></Input>
              </div>
            </div>
          </div>
        </div>
        <div className="coin-pay-price">
          当前订阅: {plan.level_name} { getDiscountDescription(plan) }
          {/*<span className="link-text">升级套餐</span>*/}
        </div>
        <Button className="coin-pay-btn"
                type="primary"
                size="large"
                shape="round" onClick={handleBuy}>购买</Button>
      </div>
    )
  } else {
    return (<div className="coin-pay-un-auth">
      购买金币
      <span className="link-text"
            onClick={() => dispatch(setLoginVisible(true))}>前往登录
      </span>
    </div>)
  }
}
