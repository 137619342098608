import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './reset.css';
import './index.css';
// store
import store from "./store";
import {Provider, useDispatch, useSelector} from 'react-redux';
// import { fetchSea } from "@/store/modules/userStore";
import { setSiteTitle, setSiteDescription } from "@/store/modules/commonStore";
// router
import { RouterProvider } from 'react-router-dom';
import router from '@/router';
// antd
import { ConfigProvider, App, theme } from 'antd';
import zhCN from "antd/locale/zh_CN";
import antdThemeConfig from '@/config/antd-theme';
import { antdUtils, connect_dataStore } from "@/utils";

const root = ReactDOM.createRoot(document.getElementById('root'));

function Root() {
  const dispatch = useDispatch();
  const { theme: appTheme } = useSelector((state) => state.common);
  const { token } = useSelector((state) => state.user);
  const [ antdTheme, setAntdTheme ] = useState(antdThemeConfig);
  const { notification, message, modal } = App.useApp();

  // 连接本地数据库
  connect_dataStore();

  useEffect(() => {
    setAntdTheme({
      ...antdTheme,
      algorithm: appTheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm
    })

    const rootEl = document.body;
    rootEl && (rootEl.className = appTheme + '-theme');
  }, [appTheme]);

  useEffect(() => {
    if (!antdUtils.message || !antdUtils.message.error) {
      antdUtils.setModalInstance(modal);
      antdUtils.setMessageInstance(message);
      antdUtils.setNotificationInstance(notification);
    }
  }, [notification, message, modal]);

  useEffect(() => {
    // 动态设置网站的标题和描述
    dispatch(setSiteTitle());
    dispatch(setSiteDescription());
    // 获取海接口数据
    // dispatch(fetchSea()).then();
    // 获取app列表
    // dispatch(fetchApps());
  }, [])

  return (
    <ConfigProvider
      locale={zhCN}
      theme={antdTheme}
    >
      <App rootClassName="config-app">
        {/* 路由 */}
        <RouterProvider router={router} />
      </App>
    </ConfigProvider>
  )
}

root.render(
  // <React.StrictMode></React.StrictMode>
  <Provider store={store}>
    <Root />
  </Provider>
);
