import { Modal, Button } from 'antd';
import globalConfig from '@/config';

export default function(props) {
  const { open, onOpen } = props;

  const handleCancel = () => {
    onOpen(false);
  }

  return (
    <Modal title="隐私政策"
           width="60vw"
           open={open}
           style={{ top: '5vh', minWidth: '620px' }}
           styles={{ body: { height: '80vh' }}}
           footer={null}
           onCancel={handleCancel}>
      <div className="large-modal-inner">
        <div className="modal-content">
          <div className="_markdown-body">
            <div className="paragraph">更新日期：2024年2月23日</div>
            <div className="paragraph">欢迎您使用{ globalConfig.siteTitle }服务！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，{ globalConfig.siteTitle }服务提供者（或简称“我们”）制定本《隐私政策》（下称“本政策”／“本隐私政策”），帮助您充分了解在您使用我们服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
            </div>
            <div className="paragraph">在您开始使用我们的服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以<strong>粗体</strong>标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。<strong>如对本政策内容有任何疑问、意见或建议，您可通过电子邮件<a
              href={'mailto:' + globalConfig.feedbackEmail}>{globalConfig.feedbackEmail}</a>与我们联系。</strong></div>
            <div className="paragraph"
                 >您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。
            </div>
            <div className="paragraph"><strong>1.适用范围</strong></div>
            <div className="paragraph">适用范围本政策适用于{ globalConfig.siteTitle }服务提供者以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。<strong>我们及关联公司就其向您提供的产品或服务单独设有隐私政策的，则相应产品或服务适用相应隐私政策。</strong>
            </div>
            <div className="paragraph"
                 >除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。
            </div>
            <div className="paragraph"><strong>2.信息收集及使用</strong></div>
            <div className="paragraph"
                 >在您使用我们的产品/服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
            </div>
            <div className="paragraph"
                 >（1）为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；
            </div>
            <div className="paragraph"
                 >（2）为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。我们会为您提供的各项具体功能场景包括：
            </div>
            <div className="paragraph"><strong>2.1成为我们的用户及账户管理</strong></div>
            <div className="paragraph">为注册成为{ globalConfig.siteTitle }的用户，您需要提供您在中华人民共和国境内的<strong>手机号码</strong>用来接收验证码，以实现账号的注册/登录。如果您仅使用浏览、搜索服务，您不需要注册成为用户并提供上述信息。我们可能会根据您的单独同意从第三方处获取您的第三方账户信息（例如您在该第三方账户下的昵称、头像，具体以您的授权内容为准），并与您的账户进行绑定，使您可在第三方处直接登录、使用我们的产品及/或服务。我们可能经您单独同意后向第三方提供您的账户信息（头像、昵称及页面提示的其他信息），使您可以便捷地实现第三方账户的注册或登录，我们将在您单独同意的授权范围内使用您的相关信息。
            </div>
            <div className="paragraph"
                 >为维护您的基本资料，改善您的服务体验，您可皇行编辑您的<strong>头像、昵称</strong>。您还可以选择填写您的<strong>个人介绍、邮箱地址</strong>等信息，以获得我们提供的更多权益，此类信息属于基本功能的非必要信息，如您不提供这些信息，并不影响您使用服务的基本功能。
            </div>
            <div className="paragraph"
                 >为满足相关法律规定及监管要求、确保用户身份真实性、保障系统和服务安全，基于实现特殊类型服务（例如报名活动、领取奖励、用户权益兑换）以及其他依法需要核验用户真实身份的场景（包括依法保护未成年人权益、打击电信网络诈骗、实现反欺诈等风险控制、行政执法或司法诉讼中相关主体认定）的必要，您需要进行身份认证。为实现认证目的，您需要提供您的身份认证信息（包括姓名、身份证等相关信息，根据实际情况可能有所不同，以页面提示为准），并授权我们通过国家权威可信身份认证机构进行信息核验，我们将依法记录、保存认证信息及认证结果，这些信息仅供完成认证目的，或其他法律法规所规定的用途，未经您明示授权不会用作其他目的。
            </div>
            <div className="paragraph"><strong>2.2智能交互服务</strong></div>
            <div className="paragraph"
                 >我们基于生成式人工智能模型技术向你提供文本、图片、视频等生成服务。为了向你提供以上服务，我们可能会收集你主动输入的信息（包括文字、图片、语音、视频、模型）、内容指令等。
            </div>
            <div className="paragraph"><strong>2.3浏览与搜索</strong></div>
            <div className="paragraph"
                 >当你使用内容浏览与搜索时，我们会收集您的搜索记录，包括搜索词、浏览记录/时间、搜索的时间/次数。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地设备端，并可向您展示搜索历史记录。
            </div>
            <div className="paragraph">
              <strong>请您理解单独的搜索词信息无法识别您的个人身份，其不属于您的个人信息，因此我们有权以其他的目的对其进行合理使用。</strong>
            </div>
            <div className="paragraph"><strong>2.4下单与支付</strong></div>
            <div className="paragraph">
              <strong>当您在购买我们的产品及/或服务时，例如充值会员/加购包，我们需要根据服务类型收集如下部分或全部的个人信息，包括：交易服务信息、交易金额、下单/支付时间、订单编号、订单状态、支付方式、支付状态，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务。充值记录、消费记录属于敏感个人信息，此类信息属于该功能的必要信息，若您不提供这类信息，您可能无法正常完成上述活动。</strong>
            </div>
            <div className="paragraph"><strong>2.5通知及互动</strong></div>
            <div className="paragraph"><strong>2.5.1消息通知</strong></div>
            <div className="paragraph"
                 >我们在运营中可能会通过您在使用产品及/或服务的过程中所提供的联系方式（如联系电话、联系邮箱、微信账号），向您同时发送一种或多种类型的通知，例如服务通知、身份验证、安全验证、用户使用体验调研等。我们也可能会以短信、电子邮件、微信订阅号、站内消息、弹窗推送的方式向您发送您可能感兴趣的服务、功能或活动的商业性信息及其他商品或服务信息。如您不愿接收我们的商业性信息及其他商品或服务信息，您可以通过短信、电子邮件等消息中提供的退订方式以及直接联系我们的客服进行退订或关闭，如您希望关闭弹窗推送信息，您也可以通过弹窗页面上的“跳过"按钮或相关按钮进行关闭。
            </div>
            <div className="paragraph"><strong>2.5.2活动参与</strong></div>
            <div className="paragraph"
                 >当您参与我们举办的活动时，我们可能需要收集及使用您的相关个人信息（包括但不限于您的昵称、头像、联系方式、收货信息），以帮助您实现活动报名、参与、享受活动权益、抽奖、兑奖等，我们将在相关场景向您另行告知具体收集及使用范围并征得您的同意。如相关活动由我们与关联公司或第三方联合举办的，我们会在获取您的同意后与该关联方或第三方共享必要信息，以保障您在活动中获得一致、完整的服务。
            </div>
            <div className="paragraph"><strong>2.6客户服务及争议处理</strong></div>
            <div className="paragraph">当您与我们联系或提出争议纠纷处理申请时，为了保障您的账户及系统安全，<strong>我们需要您提供必要的个人信息以核验您的用户身份。</strong>
            </div>
            <div className="paragraph">
              <strong>为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账户信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。</strong>
            </div>
            <div className="paragraph"
                 >为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
            </div>
            <div className="paragraph"><strong>2.7安全保障</strong></div>
            <div className="paragraph"
                 >为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或{ globalConfig.siteTitle }相关协议、规则的情况，我们可能在应用程序中嵌入我们关联公司开发的应用安全
              SDK 收集您的设备信息（例如IMEI、MAC
              地址等）、服务日志信息，并可能使用或整合您的账户信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您的账户风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </div>
            <div className="paragraph"><strong>2.8图片处理</strong></div>
            <div className="paragraph"
                 >您上次的图片，我们将以您主动上传的原始图片为基础使用人工智能模型生成，如果图片包含肖像信息，我们处理您主动上传的原始肖像信息。因这类信息是提供本服务必须收集的信息，我们会按照法律的要求，在收集、传输和存储的过程中对您的该类信息采取严格的加密措施，以确保您的信息安全。我们仅提供图像处理服务，不会提取识别信息，不会用于识别用途，服务完成后，系统将自动删除上述信息，不予保留。就前述部分信息上传时，我们也会请求您授权相机、相册权限。
            </div>
            <div className="paragraph"><strong>2.9其它附加服务</strong></div>
            <div className="paragraph"><strong>2.9.1基于系统权限的服务</strong></div>
            <div className="paragraph"
                 >为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的附加服务中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用{ globalConfig.siteTitle }的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。您可自行决定这些权限随时的开启或关闭。
            </div>
            <div className="paragraph"
                 >请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
            </div>
            <div className="paragraph"><strong>2.9.2Cookie和同类技术服务</strong></div>
            <div className="paragraph">当您使用我们的服务时，我们可能会使用Cookie和同类技术向您的设备发送一个或多个
              Cookie 或匿名标识符（以下简称 "Cookie" ），以收集、标识和存储您访问、使用{ globalConfig.siteTitle }的信息。我们承诺，不会将 Cookie
              用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie
              主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复填与表单、输入搜索内容的步骤和流程。
            </div>
            <div className="paragraph"
                 >大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。
            </div>
            <div className="paragraph"><strong>2.10收集和使用个人信息的其它规则</strong></div>
            <div className="paragraph"
                 >2.10.11若您提供的信息中含有其他用户的个人信息，在向{ globalConfig.siteTitle }提供这些个人信息之前，您需确保您已经取得合法的授权。
            </div>
            <div className="paragraph"
                 >2.10.12若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先征得您的同意。
            </div>
            <div className="paragraph"><strong>2.11征得授权同意的例外</strong></div>
            <div className="paragraph"
                 >请你理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用你的个人信息不必事先征得你的授权同意：
            </div>
            <div className="paragraph">a.与我们履行法律法规规定的义务相关的；</div>
            <div className="paragraph">b.与国家安全、国防安全直接相关的；</div>
            <div className="paragraph">c.与公共安全、公共卫生、重大公共利益直接相关的；</div>
            <div className="paragraph">d.与刑事侦查、起诉、审判和判决执行等直接相关的；</div>
            <div className="paragraph"
                 >e.出于维护你或他人的生命、财产等重大合法权益但又很难得到本人授权同意的；
            </div>
            <div className="paragraph">f.你自行向社会公众公开的个人信息；</div>
            <div className="paragraph">g.根据个人信息主体要求签订和履行合同所必需的；</div>
            <div className="paragraph"
                 >h.从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </div>
            <div className="paragraph"
                 >i.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
            </div>
            <div className="paragraph">j.为开展合法的新闻报道所必需的；</div>
            <div className="paragraph"
                 >k.为学术研究机构，基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </div>
            <div className="paragraph">l.法律法规规定的其他情形。</div>
            <div className="paragraph">
              <strong>特别提示你注意，如信息无法单独或结合其他信息识别到你的个人身份，其不属于法律意义上你的个人信息；当你的信息可以单独或结合其他信息识别到你的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他你的个人信息结合使用时，这些信息在结合使用期间，将作为你的个人信息按照本隐私政策处理与保护。</strong>
            </div>
            <div className="paragraph">
              <strong>3.数据使用过程中涉及的合作方及转移、公开个人信息</strong></div>
            <div className="paragraph"><strong>3.1数据使用过程中涉及的合作方</strong></div>
            <div className="paragraph">3.1.1我们与合作方合作时，将遵守以下原则</div>
            <div className="paragraph"
                 >（1）合法正当与最小必要原则：数据处理应当具有合法性基础，具有正当的目的，并以实现处理目的最小范围为限。
            </div>
            <div className="paragraph"
                 >（2）用户知情权与决定权最大化原则：数据处理过程中充分尊重用户对其个人信息处理享有的知情权与决定权。
            </div>
            <div className="paragraph"
                 >（3）安全保障能力最强化原则：我们将采取必要措施保障所处理个人信息的安全，审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作协议的有关要求。
            </div>
            <div className="paragraph">3.1.2合作方的范围</div>
            <div className="paragraph"
                 >若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。
            </div>
            <div className="paragraph">3.1.3委托处理</div>
            <div className="paragraph"
                 >我们可能委托授权合作方处理您的个人信息，以便授权合作方代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作方只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作方将您的信息用于我们未委托的用途，其将单独征得您的同意。
            </div>
            <div className="paragraph"
                 >3.1.4对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。
            </div>
            <div className="paragraph">3.1.5合作场景</div>
            <div className="paragraph">（1）为实现业务功能</div>
            <div className="paragraph">A.商品/服务物流配送与售后服务</div>
            <div className="paragraph"
                 >为实现提供的商品/服务的需求（例如用户权益兑换、奖品发放涉及的商品/服务配送及物流查询、享受售后服务等），我们会将与您相关的商品/服务及其数量、配送相关信息及处理所涉问题所必要的个人信息（以实现具体服务目的，在"最小必要范围提供用户的个人信息）提供给相关服务的合作商（如仓储、物流服务提供方）。
            </div>
            <div className="paragraph">B.联合营销活动</div>
            <div className="paragraph"
                 >为确保您的完整参与、帮助合作商向您发放奖励我们会将向您发放奖励所必需的信息提供给与我们开展联合营销活动的合作商，例如：涉及合作商负责线下寄送的情形，我们需要向合作商提供收货人信息（收货人姓名、收货人联系电话、收货地址等）
            </div>
            <div className="paragraph">C.下单与支付</div>
            <div className="paragraph"
                 >为完成订单支付、保障交易安全、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构，收集与交易进度相关的您的交易账号、支付账号、订单信息、支付信息，将您的交易进度相关信息共享给上述服务提供者充值记录、消费记录属于敏感个人信息，收集该等信息是实现消费功能所必需，否则无法完成交易。
            </div>
            <div className="paragraph">（2）安全与统计分析</div>
            <div className="paragraph"
                 >我们非常重视产品及/或服务的安全性，为保障用户的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、账号及日志信息。
            </div>
            <div className="paragraph">（3）其它合作场景</div>
            <div className="paragraph"
                 >除上述合作场景外，我们还可能会将信息委托给支持我们业务的其他合作方处理包括受我们委托提供技术基础设施服务、分析我们服务的使用方式、提供商家会员服务或者其他客户服务。
            </div>
            <div className="paragraph"
                 >为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中可能会嵌入授权合作方的SDK或其他类似的应用程序。我们会对授权合作方获取有关信息的应用程序接口
              (API) 、 SDK 进行严格的安全检测，并与授权合作方约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作方单独取得您同意的除外。
            </div>
            <div className="paragraph"><strong>3.2转移</strong></div>
            <div className="paragraph"
                 >如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。
            </div>
            <div className="paragraph"><strong>3.3公开披露</strong></div>
            <div className="paragraph">我们仅会在以下情况下，公开披露您的个人信息：</div>
            <div className="paragraph"
                 >3.3.1基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；
            </div>
            <div className="paragraph">
              <strong>3.3.2如果我们确定您出现违反法律法规或严重违反{ globalConfig.siteTitle }相关协议及规则的情况，或为保护{ globalConfig.siteTitle }用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及{ globalConfig.siteTitle }已对您采取的措施。</strong>
            </div>
            <div className="paragraph"><strong>3.4停止运营</strong></div>
            <div className="paragraph"
                 >如果我们停止运营产品及/或服务，将及时停止继续收集您的个人信息。我们会逐一送达通知或以公告的形式向您发送停止运营的告知，对我们所持有的与已关停的产品及/或服务相关的个人信息进行删除或匿名化处理。
            </div>
            <div className="paragraph"><strong>4.您的权利</strong></div>
            <div className="paragraph"
                 >您可以通过以下方式查阅和管理您的信息，<strong>我们会在符合法律法规要求的情形下响应您的请求：</strong>
            </div>
            <div className="paragraph"><strong>（一）查阅、更正和补充</strong></div>
            <div className="paragraph">您有权通过以下方式查阅、更正和补充您的信息；</div>
            <div className="paragraph">登录{ globalConfig.siteTitle }点击“**个人中心”**进行操作。</div>
            <div className="paragraph"><strong>（二）复制</strong></div>
            <div className="paragraph"
                 >您可以通过“（一）查阅、更正和补充”中列明的路径查询后复制您的个人信息。
            </div>
            <div className="paragraph"><strong>（三）删除</strong></div>
            <div className="paragraph"
                 >您可以通过“（一）查阅、更正和补充”中列明的路径删除您的部分信息，或申请注销账户删除您的全部信息。
            </div>
            <div className="paragraph"
                 >在以下情形中，您可以通过与我们的客服联系向我们提出删除个人信息的请求：
            </div>
            <div className="paragraph">A.如果我们处理个人信息的行为违反法律法规；</div>
            <div className="paragraph">B.如果我们收集、使用您的个人信息，却未征得您的明确同意；
            </div>
            <div className="paragraph">C.如果我们处理个人信息的行为严重违反了与您的约定;</div>
            <div className="paragraph"
                 >D.如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要;
            </div>
            <div className="paragraph">E.如果我们停止提供服务，或者保存期限已届满。</div>
            <div className="paragraph"
                 >若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
            </div>
            <div className="paragraph">
              <strong>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</strong>
            </div>
            <div className="paragraph"><strong>（四）改变授权范围</strong></div>
            <div className="paragraph"
                 >您可以通过以下方式改变您授权我们处理您信息的范围或撤回您的授权：
            </div>
            <div className="paragraph">A.登录微信客户端，点击"我-设置-个人信息与权限-授权管理
            </div>
            <div className="paragraph"
                 >B.对于您无法直接通过上述方式改变或撤回的授权，您可以通过{ globalConfig.siteTitle }页面所提供的联系方式联系我们，以行使这项权利。
            </div>
            <div className="paragraph"
                 >**请您理解对于部分类型的个人信息，例如实现{ globalConfig.siteTitle }基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。**当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
            </div>
            <div className="paragraph"><strong>（五）注销账户</strong></div>
            <div className="paragraph">您可以通过以下方式申请注销您的账户：</div>
            <div className="paragraph">A.您可以登录{ globalConfig.siteTitle }点击“**个人中心”**进行注销账户操作。
            </div>
            <div className="paragraph"
                 >B.您可以通过{ globalConfig.siteTitle }页面所提供的联系方式联系我们，帮助您进行账户注销。
            </div>
            <div className="paragraph"
                 >在您主动注销账户之后，我们将停止为您提供服务，并根据适用法律的要求删除或匿名化处理您的个人信息。
            </div>
            <div className="paragraph"><strong>（六）响应您的上述要求</strong></div>
            <div className="paragraph"
                 >对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们。我们将在15天内做出答复。
            </div>
            <div className="paragraph"
                 >为保障您的账户安全和您的个人信息安全，您向我们提出上述要求时，我们可能会先验证您的身份（如账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。
            </div>
            <div className="paragraph"
                 >对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
            </div>
            <div className="paragraph"><strong>5.信息的存储</strong></div>
            <div className="paragraph"><strong>（一）存储期限</strong></div>
            <div className="paragraph">
              <strong>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。</strong>
            </div>
            <div className="paragraph">我们判断个人信息的存储期限主要依据以下标准如下：</div>
            <div className="paragraph"
                 >A.完成与您相关的服务目的、维护相应的服务记录以应对您可能的查询或投诉;
            </div>
            <div className="paragraph">B.保证我们为您提供服务的安全和质量;</div>
            <div className="paragraph">C.您是否同意更长的留存期间;</div>
            <div className="paragraph">D.是否存在关于保留期限的其他特别约定或法律法规规定。</div>
            <div className="paragraph"
                 >在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。
            </div>
            <div className="paragraph"><strong>（二）存储位置</strong></div>
            <div className="paragraph"
                 >我们在中华人民共和国境内运营中收集和产生的个人信息，将<strong>存储在中国境内</strong>。以下情形，我们会在履行法律规定的义务及办理相关许可后，向可能的境外实体提供您的个人信息：
            </div>
            <div className="paragraph">A.适用的法律有明确规定;</div>
            <div className="paragraph">B.获得您的单独同意；</div>
            <div className="paragraph"
                 >C.您作为一方当事人的跨境合作及其他类型合同订立和履行所必需的。
            </div>
            <div className="paragraph">针对以上情形，我们会按照本政策依法保护您的个人信息安全。
            </div>
            <div className="paragraph"><strong>(三)存储安全</strong></div>
            <div className="paragraph">A.数据保护技术措施</div>
            <div className="paragraph"
                 >我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受
              SSL 协议加密保护，我们提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击，我们会部署访问控制机制，确保只有授权人员才可访问个人信息。
            </div>
            <div className="paragraph">B.数据保护管理组织措施</div>
            <div className="paragraph"
                 >我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性。我们通过培训课程和考试不断加强员工对于保护个人信息重要性的认识。
            </div>
            <div className="paragraph">C.个人信息安全事件的响应</div>
            <div className="paragraph"
                 >如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </div>
            <div className="paragraph">D.账户安全风险的防范</div>
            <div className="paragraph"
                 >在使用{ globalConfig.siteTitle }服务时，您可能会向他人披露自己的个人信息，如联络方式等。此外，您也可能通过我们的服务与第三方建立沟通。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，以免信息被窃取甚至遭遇电信网络诈骗。
            </div>
            <div className="paragraph"
                 >如您担心自己的个人信息尤其是您的账户发生泄露，请您立即通过{ globalConfig.siteTitle }客服联系我们以便我们根据您的申请采取相应措施。
            </div>
            <div className="paragraph"><strong>6.政策的更新</strong></div>
            <div className="paragraph"
                 >为给您带来更好的服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。
            </div>
            <div className="paragraph"
                 >对于重大变更，我们还会提供更为显著的通知（包括我们会通过公示的方式进行通知或向您提供弹窗提示)。
            </div>
            <div className="paragraph">本政策所指的重大变更包括但不限于：</div>
            <div className="paragraph"
                 >A.业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；
            </div>
            <div className="paragraph"
                 >B.业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式;
            </div>
            <div className="paragraph">C.个人信息共享或公开披露的主要对象发生变化；</div>
            <div className="paragraph">D.用户个人信息权利及其行使方式发生重大变化；</div>
            <div className="paragraph">E.处理个人信息保护负责人的联络方式及投诉渠道发生变化时；
            </div>
            <div className="paragraph"
                 >F.个人信息保护影响评估报告表明产品/服务存在对个人权益有重大影响时。
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button type="primary" onClick={handleCancel}>确定</Button>
        </div>
      </div>
    </Modal>
  )
}
