import {Button, Input} from "antd";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {AppTotalTag} from "@/config/chat";
import {FileExclamationOutlined, CloseOutlined} from "@ant-design/icons";

export default function CommandCenter({ chooseCommand, onOpen }) {
  const { ai_command_tags, ai_commands } = useSelector(state => state.user);

  const [searchValue, setSearchValue] = useState('');
  const [activeTag, setActiveTag] = useState(AppTotalTag);
  const [filterCommands, setFilterCommands ] = useState([]);

  useEffect(() => {
    setFilterCommands(ai_commands.filter(app => {
      const name = app.name.toLowerCase();
      if (activeTag === AppTotalTag) {
        return name.includes(searchValue.toLowerCase());
      } else {
        return app.tags.includes(activeTag) && name.includes(searchValue.toLowerCase());
      }
    }));
  }, [searchValue, activeTag])

  function handleClickCommand(command) {
    chooseCommand(command.content);
  }

  return (
    <div className="command-center">
      <div className="command-center-header">
        <div className="center-title">指令中心</div>
        <CloseOutlined className="close-icon" onClick={() => onOpen(false)}/>
      </div>
      <div className="command-center-search">
        <Input value={searchValue}
               onChange={(e) => setSearchValue(e.target.value)}
               className="search-input"
               allowClear
               placeholder="搜索指令"></Input>
      </div>
      <div className="command-center-tags">
        {
          ai_command_tags.map((item, index) => {
            return (<Button key={item + '_command_center_' + index}
                            className="search-tag"
                            size="small"
                            type={activeTag === item ? 'primary' : 'default'}
                            onClick={() => setActiveTag(item)}>{item}</Button>)
          })
        }
      </div>
      <div className="command-list">
        {
          filterCommands.length ? filterCommands.map(command => {
            return (<div key={command.name} className="command-item" onClick={() => handleClickCommand(command)}>
              {/*<div className="command-item-icon"></div>*/}
              <div className="command-item-content">
                <div className="name ellipsis">{command.name}</div>
                <div className="description ellipsis">{command.content}</div>
              </div>
            </div>)
          }) : (<div className="command-empty">
            <FileExclamationOutlined className="command-empty-icon" />
            <span>暂无该类型应用哦！</span>
          </div>)
        }
      </div>
    </div>
  )
}
