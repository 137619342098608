import './index.scss';
import { Affix} from 'antd';
import { useState, useEffect } from 'react';
import CommonHeader from "@/components/CommonHeader";
import CardFree from "./CardFree";
import CardBase from "./CardBase";
import CardStandard from "./CardStandard";
import CardPro from "./CardPro";
import CoinPay from "./CoinPay";
import OrderPrepareModal from "./OrderPrepareModal";
import { useSelector, useDispatch } from 'react-redux';
import { setLoginVisible } from '@/store/modules/userStore';
import globalConfig from '@/config';
import {orderPrepareApi} from "@/api/payment";
import {antdUtils} from "@/utils";

function Recharge() {
  const dispatch = useDispatch();
  const { userId, plans, rmb_to_coins_exchange_rate } = useSelector((state) => state.user);
  const [container, setContainer] = useState(null);
  const [tabs, setTabs] = useState([
    {
      key: 'month',
      label: '按月',
      discount: ''
    },
    {
      key: 'quarter',
      label: '按季',
      discount: ''
    },
    {
      key: 'year',
      label: '按年',
      discount: ''
    },
    {
      key: 'coin',
      label: globalConfig.monetaryUnit,
      discount: '',
    }
  ]);
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key);
  const [activeCardId, setActiveCardId] = useState('');
  const [orderPrepareModalOpen, setOrderPrepareModalOpen] = useState(false);
  const [orderPrepareParams, setOrderPrepareParams] = useState(null);
  const [orderPrepareInfo, setOrderPrepareInfo] = useState({
    product_name: '',
    amount: 0,
    payment_channels: []
  });
  const [priceMap, setPriceMap] = useState({
    month: [],
    quarter: [],
    year: [],
  });

  useEffect(() => {
    if (plans.length) {
      const free = plans.find(item => !item.scene && item.id === 'free');
      const month_standard = plans.find(item => item.scene === 'month' && item.level_id === 2);
      let month_standard_price = (month_standard && month_standard.new_price) || 0;
      let quarter_discount = 0;
      let year_discount = 0;

      const month = [free];
      const quarter = [free];
      const year = [free];

      plans.forEach(item => {

        if (item.scene === 'month') {
          month.push({
            ...item,
          });
        }

        if (item.scene === 'quarter') {
          quarter.push({
            ...item,
            unit_name: '季',
            unit_price: item.new_price * 3,
          });
          if (item.level_id === 2) {
            quarter_discount = ((month_standard_price - item.new_price) / month_standard_price * 100).toFixed();
          }
        }

        if (item.scene === 'year') {
          year.push({
            ...item,
            unit_name: '年',
            unit_price: item.new_price * 12,
          });
          if (item.level_id === 2) {
            year_discount = ((month_standard_price - item.new_price) / month_standard_price * 100).toFixed();
          }
        }
      });

      const newTabs = tabs.map(item => {
        let dis = '';
        if (item.key === 'quarter') {
          dis = quarter_discount;
        }
        if (item.key === 'year') {
          dis = year_discount;
        }
        return {
          key: item.key,
          label: item.label,
          discount: dis,
        }
      })
      setTabs(newTabs);
      setPriceMap({ month, quarter, year })

    }
  }, [plans]);

  useEffect(() => {
    if (activeTabKey === 'coin' && !userId) {
      dispatch(setLoginVisible(true));
    }
  }, [activeTabKey])

  function handleChangeActiveTab(key) {
    setActiveTabKey(key);
    handleLeavePriceCard();
  }

  function handleEnterPriceCard(id) {
    setActiveCardId(id);
  }

  function handleLeavePriceCard() {
    setActiveCardId('');
  }

  // 订单确认，发起请求，成功后打开弹窗
  async function handleOrderPrepare(info) {
    // 判断用户是否登录，未登录则弹窗
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }
    antdUtils.setLoading && antdUtils.setLoading(true);
    try {
      const res = await orderPrepareApi(info);
      setOrderPrepareInfo(res);
      setOrderPrepareParams(info);
      setOrderPrepareModalOpen(true);
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading && antdUtils.setLoading(false);
    }
  }

  function renderCardContentByLevel(item) {
    switch (item.level_id) {
      case 0: {
        return (<CardFree { ...item } rmb_to_coins_exchange_rate={rmb_to_coins_exchange_rate} />);
        break;
      };

      case 1: {
        return (<CardBase { ...item } onBuy={handleOrderPrepare} />);
        break;
      };

      case 2: {
        return (<CardStandard { ...item } onBuy={handleOrderPrepare}/>);
        break;
      };

      case 3: {
        return (<CardPro { ...item } onBuy={handleOrderPrepare}/>);
        break;
      };

      default: {
        return ''
      }
    }
  }

  return (
    <div className="recharge-container">
      {/* 头部 */}
      <CommonHeader title="购买会员" />
      {/* 主体 */}
      <div className="recharge-body" ref={setContainer}>
        <div className="recharge-title">购买享专属特权</div>
        <Affix offsetTop={0} target={() => container}>
          <div className="stick-tab">
            <div className="stick-tab-inner">
              {
                tabs.map(item => {
                  return (<div key={item.key} className={ activeTabKey === item.key ? 'tab-item tab-item-active' : 'tab-item' }
                               onClick={() => handleChangeActiveTab(item.key)}>
                    {item.label} {item.discount && (<span className="price-tip"> -节省{item.discount}%</span>)}
                  </div>)
                })
              }
            </div>
          </div>
        </Affix>
        <div className="recharge-content">
          {
            priceMap[activeTabKey] && priceMap[activeTabKey].map(item => {
              return (<div className={ activeCardId === item.id ? 'price-card price-card-active' : 'price-card' }
                           key={item.id}
                           onMouseLeave={() => handleLeavePriceCard}
                           onMouseEnter={() => handleEnterPriceCard(item.id)}>
                <div className="price-card-content">
                  { renderCardContentByLevel(item) }
                </div>
                <div className="price-card-border"></div>
              </div>)
            })
          }
          <CoinPay show={priceMap[activeTabKey]}
                   onBuy={handleOrderPrepare}></CoinPay>
        </div>
      </div>
      <OrderPrepareModal open={orderPrepareModalOpen}
                         onOpen={setOrderPrepareModalOpen}
                         prepareParams={orderPrepareParams}
                         prepareInfo={orderPrepareInfo}></OrderPrepareModal>
    </div>
  )
}

export default Recharge;
