import {useState, useEffect} from "react";
import {UndoOutlined} from "@ant-design/icons";
import {Button } from 'antd';
import {useSelector} from "react-redux";

const Type = {
  grid: 'grid',
  flex: 'flex'
}

export default function EmptyChat({ app, onChoose }) {
  const { ai_commands } = useSelector(state => state.user);
  const [commandList, setCommandList] = useState([]);
  const [type, setType] = useState(Type.grid);

  useEffect(() => {
    changeRandomList();
  }, [app]);

  // 随机从数组中取n条数据
  function getRandomAskList (list = ai_commands, number = 4) {
    // 对数组进行重新排序打乱，取前面的number个
    const res = [...list].sort(() => Math.random() - 0.5).slice(0, number);
    setCommandList(res);
  }

  function changeRandomList() {
    if (app) {
      if (app.settings.is_general) {
        // 通用聊天，从指令中心随机4个出来
        setType(Type.grid);
        getRandomAskList();
      } else {
        // 非通用的，用自身的里面指令(通过settings.commands) 随机3个出来
        if (app.settings.commands && app.settings.commands.length) {
          const arr = app.settings.commands.map(item => ({
            name: '',
            content: item
          }));
          setType(Type.flex);
          getRandomAskList(arr, 3);
        } else {
          setType(Type.grid);
          getRandomAskList();
        }
      }
    }
  }

  if (app) {
    return (
      <div className="empty-chat">
        <div className="empty-chat-avatar">
          <img className="avatar-img" src={app.avatar_url} alt=""/>
        </div>
        <div className="empty-chat-title">{app.name}</div>
        <div className="empty-chat-description">{app.greeting}</div>
        <div className="empty-chat-tip">试试这样问我：</div>
        <div className="random-btn">
          <Button icon={<UndoOutlined />} onClick={() => changeRandomList()}>换一批</Button>
        </div>
        <div className={ type === Type.grid ? 'command-examples-grid' : 'command-examples-flex'}>
          {
            commandList.map(((item, index) => {
              return (
                <div key={item.content + index} className="command-example-item" onClick={() => onChoose(item.content)}>
                  {
                    item.name && <div className="command-example-item-title">
                      {/*<div className="random-icon"></div>*/}
                      <div className="title">{ item.name }</div>
                    </div>
                  }
                  <div className="command-example-item-content ellipsis-2">
                    { item.content }
                  </div>
                </div>
              )
            }))
          }
        </div>
      </div>
    )
  } else {
    return null;
  }
}
