import {Button, Tooltip} from "antd";
import {CheckCircleFilled, InfoCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

export default function CardBase(props) {
  const { id, level_name, unit_name, unit_price, new_price, old_price, period_coins, coins_discount, onBuy  } = props;
  const { plan, rmb_to_coins_exchange_rate } = useSelector((state) => state.user);

  function handleBuy() {
    onBuy({
      product_type_id: 'plan',
      plan_id: id,
    })
  }

  return (
    <>
      <div className="price-card-header">
        <div className="price-card-header-title">{ level_name }</div>
        <div className="price-card-header-price">
          <span>¥</span>
          <span className="current-price">{new_price}</span>
          <div className="price-right">
            <div className="price-right-base">
              <span>/月</span>
              { (old_price > new_price) && <span className="original-price">{old_price}</span> }
            </div>
            { unit_name && (<div className="price-right-total">{ `按${unit_name} ¥${unit_price}/${unit_name}` }</div>)}
          </div>
        </div>
        <Button className="price-card-header-btn" type="primary" shape="round" size="large" onClick={handleBuy}>
          { plan.id === id ? '续期' : '购买' }
        </Button>
        {/*<div className="cancel-tip">随时取消</div>*/}
      </div>
      <div className="price-card-body">
        <div className="get-info">
          <div className="get-info-title">生成</div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              普通图片生成权限
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              有<span className="emphasize-color">图片加速</span>生成权限
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              <span className="emphasize-color">无视频</span>生成权限
              <Tooltip title="测试可用">
                <InfoCircleOutlined className="tip-icon" />
              </Tooltip>
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              仅限
              <div className="circle-text">个人使用</div>
            </div>
          </div>
        </div>
        <div className="get-info">
          <div className="get-info-title">金币</div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              每月<span className="emphasize-color">{period_coins}个</span>金币
              <Tooltip title="测试可用">
                <InfoCircleOutlined className="tip-icon" />
              </Tooltip>
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              花费金币进行<span className="emphasize-color">图片</span>生成<span className="emphasize-color">（普通和快速）</span>
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              <div>
                <div className="emphasize-color">可以购买金币</div>
                <div>(¥10 ≈ { Math.ceil((10 / (1 - coins_discount) * rmb_to_coins_exchange_rate)).toFixed() }金币)</div>
              </div>
            </div>
          </div>
        </div>
        <div className="get-info">
          <div className="get-info-title">功能</div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              推荐<span className="emphasize-color">多种</span>渲染风格
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              可下载<span className="emphasize-color">预览</span>结果
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              推荐<span className="emphasize-color">多种</span>渲染风格
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              花费金币进行<span className="emphasize-color">图片</span>生成<span className="emphasize-color">（普通和快速）</span>
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              可下载<span className="emphasize-color">预览</span>结果
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              有<span className="emphasize-color">图片加速</span>生成权限
            </div>
          </div>
          <div className="get-info-item">
            <CheckCircleFilled className="get-info-item-icon" />
            <div className="get-info-item-value">
              <span className="emphasize-color">无视频</span>生成权限
              <Tooltip title="测试可用">
                <InfoCircleOutlined className="tip-icon" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
