import { Table, Tag, Tooltip } from 'antd';
import {useEffect, useState, useRef} from "react";
import {getOrderPaymentApi} from "@/api/payment";
import {useSelector} from "react-redux";
import { CopyOutlined } from '@ant-design/icons';
import useCopy from "@/hooks/useCopy";

export default function Payment() {
  const { userId } = useSelector(state => state.user);
  const { handleCopy } = useCopy();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: '产品名称',
      align: 'center',
      dataIndex: 'product_name',
      width: '180px',
    },
    {
      title: '支付方式',
      align: 'center',
      dataIndex: 'payment_method_name',
      width: '120px',
    },
    {
      title: '金额(元)',
      align: 'center',
      dataIndex: 'placed_amount',
      render: (text) => <span>¥ {text}</span>,
      width: '120px',
    },
    {
      title: '订单号',
      align: 'center',
      dataIndex: 'order_no',
      render: (text) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ width: '120px', marginRight: '8px' }} className="ellipsis">{text}</span>
          <Tooltip title={text} overlayStyle={{ maxWidth: '320px' }}>
            <CopyOutlined onClick={() => handleCopy(text)}/>
          </Tooltip>
        </div>),
      width: '180px',
    },
    {
      title: '备注',
      align: 'center',
      dataIndex: 'remark',
      width: '120px',
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'status_name',
      render: (text, record) => <Tag color={record.status_id === 'unpaid' ? 'default' : record.status_id === 'paid' ? 'success' : 'error'}>{text}</Tag>,
      width: '110px',
    },
    {
      title: '时间',
      align: 'center',
      dataIndex: 'created_at',
      width: '180px',
    },
  ];

  useEffect(() => {
    if (userId) {
      getOrderHistory();
    }
  }, [userId, JSON.stringify(tableParams)]);


  async function getOrderHistory() {
    try {
      setTableLoading(true);
      const res = await getOrderPaymentApi({
        page_no: tableParams.pagination.current,
        page_size: tableParams.pagination.pageSize
      });
      setTableData(res.list);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res.total_count,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    setTableParams({ pagination });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
    };
  };

  return (<div className="payment-box">
    <div className="payment-table">
      <Table columns={columns}
             rowKey={(row) => row.id}
             pagination={tableParams.pagination}
             loading={tableLoading}
             onChange={handleTableChange}
             dataSource={tableData} />
    </div>
    {/*<div className="payment-empty">你还没有支付历史哦！</div>*/}
  </div>)
}
