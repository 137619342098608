// 封装对聊天数据进行增删改查的快捷操作
import localforage from 'localforage';
import {getStorageUserId} from "@/utils/token";
import dayjs from "dayjs";

export const connect_dataStore = () => {
  localforage.config({
    // 数据库的名称。一般使用应用程序的名字。在 localStorage 中，它作为存储在 localStorage 中的所有 key 的前缀。默认值：'localforage'
    name: 'ChatAI',
    // 数据库的版本。将来可用于升级; 目前未使用
    version: 1.0,
    // 数据仓库的名称。在 IndexedDB 中为 dataStore，仅含字母和数字和下划线。任何非字母和数字字符都将转换为下划线。
    storeName: 'chat_users',
  });
};

// 获取用户所有数据
export const getUserInfo_dataStore = async (userId) => {
  const data = await localforage.getItem(String(userId));
  if (data) {
    // 从数据库中取数据的时候，移除不需要的属性（如果有的话）
    data.conversations.forEach(conversation => {
      conversation.messages.forEach((message) => {
        delete message.loading;
      })
    })
  }
  return data;
};

// 保存用户数据（用户数据格式参考最底部的data对象
export const saveUserInfo_dataStore = async (userId, data) => {
  await localforage.setItem(String(userId), data);
};

// 获取对话对应的数据，根据对话Id
export const getConversation_dataStore = async (conversationId) => {
  const userId = getStorageUserId();
  if (!userId) {
    return null;
  }

  const data = await localforage.getItem(userId);
  if (!data) {
    return null;
  }

  return data.conversations.find(item => item.id === conversationId);
};

// 保存对话列表数据（返回bool，保存成功/失败）
export const saveConversations_dataStore = async (conversations) => {
  try {
    const userId = getStorageUserId();
    const data = await localforage.getItem(userId);
    data.conversations = conversations;
    await localforage.setItem(userId, data);
    // 返回保存成功
    return true;
  } catch (e) {
    return false;
  }
}

export const initFormApp_dataStore = async (app) => {
  try {
    const userId = getStorageUserId();
    const data = await localforage.getItem(userId);
    if (!data.formApps) {
      data.formApps = {};
    }

    if (!data.formApps[app.id]) {
      const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.formApps[app.id] = {
        appId: app.id,
        modelId: app.model_id,
        created_at: time,
        updated_at: time,
        max_tokens: app.settings.max_tokens, // 最大token
        temperature: app.settings.temperature, // 随机因子
        messages: [],
      }
    }

    await localforage.setItem(userId, data);
    // 返回保存成功
    return true;
  } catch (e) {
    return false;
  }
}

// 获取form app对应的数据，根据app id
export const getFormApp_dataStore = async (appId) => {
  const userId = getStorageUserId();
  if (!userId) {
    return null;
  }

  const data = await localforage.getItem(userId);
  if (!data) {
    return null;
  }

  return data.formApps ? data.formApps[appId] : null;
};

// 更新form app对应的消息数据，根据app id
export const updateFormAppMessages_dataStore = async (app, messages) => {
  try {
    const userId = getStorageUserId();
    const data = await localforage.getItem(userId);
    if (!data.formApps) {
      data.formApps = {};
    }

    if (!data.formApps[app.id]) {
      const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
      data.formApps[app.id] = {
        appId: app.id,
        modelId: app.model_id,
        created_at: time,
        updated_at: time,
        max_tokens: app.settings.max_tokens, // 最大token
        temperature: app.settings.temperature, // 随机因子
        messages: messages,
      }
    }

    data.formApps[app.id].updated_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.formApps[app.id].messages = messages;

    await localforage.setItem(userId, data);
    // 返回保存成功
    return true;
  } catch (e) {
    return false;
  }
};

// 保存form app对应的数据，根据app id
export const updateFormApp_dataStore = async (appId, settings) => {
  const userId = getStorageUserId();
  if (!userId) {
    return false;
  }

  const data = await localforage.getItem(userId);
  if (!data) {
    return false;
  }

  Object.assign(data.formApps[appId], settings);

  await localforage.setItem(userId, data);

  return true;
};

const data = {
  // 用户所有数据
  userID: {
    userId: 1, // 用户id
    config: {}, // 预留本地配置项，包括如主题、声音选择、数据模型选择...等等
    // 用户的对话列表
    conversations: [
      {
        id: 'conversation_uuid_1', // 对话的key是通过uuid生成的唯一标识
        appId: 0,
        modelId: 'gpt-3.5-turbo', // 用户在该对话中选择的模型
        name: '会话名称',
        isTop: false,
        created_at: '会话创建时间',
        updated_at: '会话修改时间',
        context_number: 9, // 上下文数量
        max_tokens: 2000, // 最大token
        temperature: 1, // 随机因子
        messages: [
          {
            created_at: '2022-12-12 10:10:10',
            role: 'user', // 用户发的消息
            id: 1,
            content: '消息内容',
            ok: true,
          },
          {
            created_at: '2022-12-12 10:10:10',
            role: 'assistant', // AI发的消息
            id: 6, // ai的id
            content: '消息内容',
            ok: false,
          },
          {
            created_at: '2022-12-12 10:10:10',
            role: 'client', // 客户端发的消息（分割线）
            id: 1, // 1-分割线
            content: '',
            ok: false,
          }
        ],
      }
    ],
    // 用户的app对话数据
    formApps: {
      appId_1: {
        appId: 0,
        modelId: 'gpt-3.5-turbo',
        created_at: '消息创建时间',
        updated_at: '消息修改时间',
        // context_number: 9, // 上下文数量
        max_tokens: 2000, // 最大token
        temperature: 1, // 随机因子
        messages: [
          {
            created_at: '2022-12-12 10:10:10',
            role: 'user', // 用户发的消息
            id: 1,
            content: `
              # title1
              value2
              
              # title2
              value2
            `,
            ok: true,
          },
          {
            created_at: '2022-12-12 10:10:10',
            role: 'assistant', // AI发的消息
            id: 6, // ai的id
            content: '消息内容',
            ok: false,
          }
        ]
      }
    }
  }
}
