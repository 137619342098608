import './index.scss';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme, setIsBarHide  } from '@/store/modules/commonStore';
// antd（注：动态生成的，如modal等组件，带主题的，需要引用App组件里的useApp()使用）
// import { App, theme, Modal, QRCode } from 'antd';
import { Tooltip } from 'antd';
import Icon, { SettingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import {DarkThemeSvg, LightThemeSvg} from "@/components/svg";

function Navbar({ list, active, onChange }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { theme, isBarHide } = useSelector(state => state.common);


  useEffect(() => {
    dispatch(setIsBarHide(isBarHide));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location && location.pathname) {
      const pathMatch = location.pathname.match(/\/.*\//);
      let pathname = location.pathname;
      if (pathMatch) {
        pathname = pathMatch[0].slice(0, -1);
      }
      const navItem = list.find(item => item.path === pathname);
      onChange(navItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function toggleTheme(e) {
    dispatch(setTheme(theme === 'dark' ? 'light' : 'dark'));
  }

  function toggleBarHide() {
    dispatch(setIsBarHide(!isBarHide));
  }

  return (
    <div className="navbar-inner">
      {/* 上半部分 */}
      <div className="navbar-inner-top">
        {/* 导航项 */}
        { list.map(item => {
          const NavIcon = item.icon;
          return (
            <div key={item.id}
                 onClick={() => navigate(item.path)}
                 className={ item.id === active ? 'navbar-item active-item' : 'navbar-item' }>
              <Tooltip title={isBarHide ? item.name : ''} placement="right">
                <div className="navbar-item-icon">
                  { item.isSvg ? <Icon component={NavIcon} /> : <NavIcon /> }
                </div>
              </Tooltip>
              { !isBarHide && <div className="navbar-item-text">{ item.name }</div> }
              <div className="navbar-item-select"></div>
            </div>
          )
        }) }
      </div>
      {/* 下半部分 */}
      <div className="navbar-inner-bottom">
        {/* 主题 */}
        <div className="navbar-item" onClick={toggleTheme}>
          <div className="navbar-item-icon theme-icon">
            <Icon component={ theme === 'dark' ? DarkThemeSvg : LightThemeSvg } />
          </div>
          { !isBarHide && <div className="navbar-item-text">主 题</div> }
          <div className="navbar-item-select"></div>
        </div>
        {/*<div className="navbar-item">*/}
        {/*  <div className="navbar-item-icon">*/}
        {/*    <SettingOutlined />*/}
        {/*  </div>*/}
        {/*  { !isBarHide && <div className="navbar-item-text">设 置</div> }*/}
        {/*  <div className="navbar-item-select"></div>*/}
        {/*</div>*/}
        {/*<Dropdown*/}
        {/*  placement="topRight"*/}
        {/*  menu={{ items, }}*/}
        {/*>*/}
        {/*</Dropdown>*/}
      </div>
      {/* 折叠悬浮按钮 */}
      <div className="navbar-inner-float" onClick={toggleBarHide}>
        { isBarHide ? <LeftOutlined /> : <RightOutlined />}
      </div>
    </div>
  )
}

export default Navbar;
