import logo from "@/images/logo.png";
import {App, Button, Form, Input, Select, ConfigProvider, theme, Spin} from "antd";
import {MailOutlined, MobileOutlined, UserOutlined} from "@ant-design/icons";
import {antdUtils, emailReg, phoneReg} from "@/utils";
import UserAgreementModal from "@/components/UserInfoChangeModal/UserAgreementModal";
import PrivacyPolicyModal from "@/components/UserInfoChangeModal/PrivacyPolicyModal";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState, useRef} from "react";
import usePhoneAreaCode from "@/hooks/usePhoneAreaCode";
import useCountDown from "@/hooks/useCountDown";
import {fetchLogin} from "@/store/modules/userStore";
import {checkUserEmailApi, checkUserPhoneApi, getPhoneCodeApi, sendEmailCodeApi, sendPhoneCodeApi} from "@/api/user";
import {useNavigate,useLocation} from "react-router-dom";

const initAuthType = 'email';

export default function Register() {
  const navigate = useNavigate();
  const locationRoute = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { notification, message, modal } = App.useApp();
  const [authType, setAuthType] = useState(initAuthType); // email phone
  const { areaCode, setAreaCode, areaOptions, resetAreaCode } = usePhoneAreaCode();
  const [form] = Form.useForm();
  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();
  const [loading, setLoading] = useState(false);

  const [modalUserAgreement, setModalUserAgreement] = useState(false);
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);

  let invite_code = useRef('');

  useEffect(() => {
    antdUtils.setModalInstance(modal);
    antdUtils.setMessageInstance(message);
    antdUtils.setNotificationInstance(notification);
  }, [notification, message, modal]);

  useEffect(() => {
    setAuthType(initAuthType);
    resetAreaCode();
    form && form.resetFields();
    getInviteCode();
  }, [])

  function getInviteCode() {
    const searchStr = locationRoute.search;
    if (searchStr.startsWith('?')) {
      const searchArr = searchStr.slice(1).split('&');
      const hasInviteStr = searchArr.find(item => item.includes('invite='));
      if (hasInviteStr) {
        invite_code.value = hasInviteStr.split('=')[1];
      }
    }
  }

  const onFinish = async (values) => {
    // 根据当前条件准备参数
    const { input_username, input_password } = values;

    const params = {
      auth_type: authType,
      auth_method: 'code',
      code: input_password,
      invite_code: invite_code.value
    };

    if (authType === 'email') {
      params.email = input_username;
    }

    if (authType === 'phone') {
      params.phone_number = areaCode + input_username;
      params.phone_area_code = areaCode;
    }

    console.log(params);
    setLoading(true);
    dispatch(fetchLogin(params, true)).then(res => {
      setLoading(false);
      if (token) {
        navigate('/chat')
      }
    }).catch(e => {
      setLoading(false);
    });
  };

  const handleChangeAuthTypeFromCode = () => {
    if (authType === 'email') {
      setAuthType('phone');
    }

    if (authType === 'phone') {
      setAuthType('email');
    }

    form.resetFields();
  }

  const getFormCode = async () => {
    form.resetFields(['input_password']);
    // 1、验证是否是邮箱/手机号
    const { input_username } = form.getFieldsValue();

    // 如果是邮箱，验证后发送验证码
    if (authType === 'email') {
      const isEmail = emailReg.test(input_username);
      if (!isEmail) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        setLoading(true);
        setCountLoading(true);
        await sendEmailCodeApi(input_username);
        startCountDown();

        // 发送成功后
        message.success('验证码已发送');
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
        setLoading(false);
      }
    }

    // 如果是手机号，验证发送验证码（测试）
    if (authType === 'phone') {
      const isPhone = phoneReg.test(input_username);
      if (!isPhone) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        setLoading(true);
        setCountLoading(true);
        await sendPhoneCodeApi(areaCode +input_username, areaCode)
        startCountDown();

        // 发送成功后
        message.success('验证码已发送');

        // 注：这里模拟获取手机code，直接填充（后面去掉）
        if (process.env.REACT_APP_ENV === 'dev') {
          const res = await getPhoneCodeApi(areaCode + input_username);
          form.setFieldValue('input_password', res.auth_code);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
        setLoading(false);
      }
    }
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-header">
          <img className="login-title-logo" src={logo} alt=""/>
          <div className="login-title register-title">
            <span className="login-title-text">注 册</span>
          </div>
          <div className="login-des">已经有账号了？<span className="custom-link" onClick={() => navigate('/chat')}>开启聊天</span></div>
        </div>
        <div className="login-body">
          <div className="form-container">
            <div className="form-sub-header">
              <div className="form-sub-header-left">
                { authType === 'email' ? <MailOutlined /> : (authType === 'phone' ? <MobileOutlined /> : <UserOutlined />) }
                <span className="form-sub-title">{ authType === 'email' ? '邮箱' : (authType === 'phone' ? '手机' : '用户名')  }</span>
              </div>
              <div className="form-sub-header-right">
                <Button  type="link" onClick={handleChangeAuthTypeFromCode}>
                  { authType === 'email' ? '切换手机号' : '切换邮箱'}
                </Button>
              </div>
            </div>
            {/* form */}
            <ConfigProvider
              theme={{
                algorithm: theme.defaultAlgorithm
              }}
            >
              <Form
                form={form}
                validateTrigger="onBlur"
                onFinish={onFinish}
                autoComplete="off"
              >

                { authType === 'email' && (<Form.Item
                  name="input_username"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('邮箱不能为空');
                        }

                        // 验证邮箱，验证手机号
                        const isEmail = emailReg.test(value);
                        if (isEmail) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('请输入正确的邮箱');
                        }
                      }
                    }
                  ]}
                >
                  <Input placeholder="请输入邮箱" />
                </Form.Item>) }

                { authType === 'phone' &&  (<div className="form-item-phone">
                  <Select
                    className="form-item-phone-select"
                    value={areaCode}
                    onChange={(value) => setAreaCode(value)}
                    options={areaOptions}
                  />
                  <Form.Item
                    name="input_username"
                    style={{ flex: 1 }}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject('手机号不能为空');
                          }

                          // 验证邮箱，验证手机号
                          const isPhone = phoneReg.test(value);
                          if (isPhone) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('请输入正确的手机号');
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="请输入手机号" />
                  </Form.Item>
                </div>) }

                <div className="form-item-code">
                  <Form.Item
                    style={{ flex: 1 }}
                    name="input_password"
                    rules={[
                      {
                        required: true,
                        message: '验证码不能为空!',
                      },
                      {
                        len: 6,
                        message: '验证码为6位数!',
                      },
                    ]}
                  >
                    <Input placeholder="请输入验证码" />
                  </Form.Item>
                  <Button className="form-item-code-btn"
                          onClick={getFormCode}
                          disabled={(count > 0 && count < 60) || countLoading}
                          loading={countLoading}
                  >
                    { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
                  </Button>
                </div>

                <Form.Item>
                  <Button className="form-btn" type="primary" htmlType="submit">
                    注册
                  </Button>
                </Form.Item>
              </Form>
            </ConfigProvider>

            <div className="form-mark">
              注册代表您已同意
              <span className="link-text" onClick={() => setModalUserAgreement(true)}>用户协议</span>
              和<
              span className="link-text" onClick={() => setModalPrivacyPolicy(true)}>隐私策略</span>，
              未注册用户将自动创建账号
            </div>
          </div>
        </div>
      </div>
      <UserAgreementModal open={modalUserAgreement} onOpen={setModalUserAgreement}></UserAgreementModal>
      <PrivacyPolicyModal open={modalPrivacyPolicy} onOpen={setModalPrivacyPolicy}></PrivacyPolicyModal>
      {/* 全局loading */}
      <Spin spinning={loading} fullscreen style={{ zIndex: 9998 }} />
    </div>
  )
}
