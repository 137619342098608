import {App, Button, Form, Input, Modal, Select} from 'antd';
import { useState, useEffect } from 'react';
import useCountDown from '@/hooks/useCountDown';
import {
  sendEmailCodeApi,
  sendPhoneCodeApi,
  getPhoneCodeApi,
  checkForgetPasswordApi, resetUserPasswordApi
} from "@/api/user";
import {antdUtils, emailReg, phoneReg} from '@/utils';
import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import './index.scss';
import usePhoneAreaCode from "@/hooks/usePhoneAreaCode";
import {useSelector} from "react-redux";

export default function(props) {
  const { open, onOpen } = props;
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [authType, setAuthType] = useState('email');
  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();
  const { areaCode, setAreaCode, areaOptions, resetAreaCode } = usePhoneAreaCode();
  const { isMock } = useSelector((state) => state.common);

  useEffect(() => {
    // 打开模态框的时候触发，清除form数据
    if (open) {
      setAuthType('email');
      resetAreaCode();
      form.resetFields();
    }
  }, [open])

  const handleCancel = () => {
    onOpen(false);
  }

  const handleChangeAuthType = () => {
    if (authType === 'email') {
      setAuthType('phone');
    }

    if (authType === 'phone') {
      setAuthType('email');
    }

    form.resetFields();
  }

  const getFormCode = async () => {
    // 1、验证是否是邮箱/手机号
    const { input_username } = form.getFieldsValue();

    // 如果是邮箱，验证后发送验证码
    if (authType === 'email') {
      const isEmail = emailReg.test(input_username);
      if (!isEmail) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        antdUtils.setLoading(true);
        // 检查邮箱/手机号
        await checkForgetPasswordApi({
          auth_type: authType,
          email: input_username,
        });

        // 通过后，发送验证码
        setCountLoading(true);
        await sendEmailCodeApi(input_username, isMock);

      } catch (e) {
        setCountLoading(false);
        return;
      } finally {
        antdUtils.setLoading(false);
      }
    }

    // 如果是手机号，验证发送验证码（测试）
    if (authType === 'phone') {
      const isPhone = phoneReg.test(input_username);
      if (!isPhone) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        antdUtils.setLoading(true);
        // 检查邮箱/手机号
        await checkForgetPasswordApi({
          auth_type: authType,
          phone_number: areaCode + input_username,
        });

        // 通过后，发送验证码
        setCountLoading(true);
        await sendPhoneCodeApi(areaCode + input_username, areaCode, isMock)
        // 注：这里模拟获取手机code，直接填充（后面去掉）
        if (process.env.REACT_APP_ENV === 'dev') {
          const res = await getPhoneCodeApi(areaCode + input_username);
          form.setFieldValue('code', res.auth_code);
        }
      } catch (e) {
        setCountLoading(false);
        return;
      } finally {
        antdUtils.setLoading(false);
      }
    }

    setCountLoading(false);
    startCountDown();
    // 发送成功后
    message.success('验证码已发送');
  }

  const onFinish = async (values) => {
    // 根据当前条件准备参数
    const { input_username, code, password } = values;
    // 准备参数
    const params = {};

    if (authType === 'email') {
      Object.assign(params, {
        auth_type: 'email',
        email: input_username,
        code,
        password
      })
    }

    if (authType === 'phone') {
      Object.assign(params, {
        auth_type: 'phone',
        phone_number: areaCode + input_username,
        code,
        password
      })
    }

    try {
      antdUtils.setLoading(true);
      await resetUserPasswordApi(params);
      message.success('重置密码成功！')
      // 关闭模态框
      onOpen(false);
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading(false);
    }
  }

  return (
    <Modal title="忘记密码"
           open={open}
           footer={null}
           onCancel={handleCancel}>

      <div className="form-modal">
        <div className="form-sub-header">
          <div className="form-sub-header-left">
            { authType === 'email' ? <MailOutlined /> : <MobileOutlined /> }
            <span className="form-sub-title">{ authType === 'email' ? '邮箱验证' : '手机验证' }</span>
          </div>
          <div className="form-sub-header-right">
            <Button type="link" onClick={handleChangeAuthType}>
              { authType === 'email' ? '使用手机号验证' : '使用邮箱验证' }
            </Button>
          </div>
        </div>

        <Form
          form={form}
          validateTrigger="onBlur"
          onFinish={onFinish}
          autoComplete="off"
        >

          { authType === 'email' && (<Form.Item
            name="input_username"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('邮箱不能为空');
                  }

                  // 验证
                  const isEmail = emailReg.test(value);
                  if (isEmail) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('请输入正确的邮箱');
                  }
                }
              }
            ]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>) }

          { authType === 'phone' &&  (<div className="form-item-phone">
            <Select
              className="form-item-phone-select"
              value={areaCode}
              onChange={(value) => setAreaCode(value)}
              options={areaOptions}
            />
            <Form.Item
              name="input_username"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject('手机号不能为空');
                    }

                    // 验证
                    const isPhone = phoneReg.test(value);
                    if (isPhone) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('请输入正确的手机号');
                    }
                  }
                }
              ]}
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
          </div>) }


          <div className="form-item-code">
            <Form.Item
              style={{ flex: 1 }}
              name="code"
              rules={[
                {
                  required: true,
                  message: '验证码不能为空!',
                },
                {
                  len: 6,
                  message: '验证码为6位数!',
                },
              ]}
            >
              <Input placeholder="请输入验证码" />
            </Form.Item>
            <Button className="form-item-code-btn"
                    onClick={getFormCode}
                    disabled={(count > 0 && count < 60) || countLoading}
                    loading={countLoading}
            >
              { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
            </Button>
          </div>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码不能为空!',
              },
              {
                min: 6,
                max: 18,
                message: '请输入6-18位密码',
              },
            ]}
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>

          <Form.Item
            name="retry_password"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('确认密码不能为空')
                  }

                  const password = form.getFieldValue('password');
                  if (value !== password) {
                    return Promise.reject('两次密码不一致')
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder="请输入确认新密码" />
          </Form.Item>

          <Form.Item>
            <Button className="form-btn" type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
