import {App, Button, Form, Input, Modal, Select} from 'antd';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {antdUtils, phoneReg} from "@/utils";
import useCountDown from "@/hooks/useCountDown";
import {
  checkUserPhoneApi,
  getPhoneCodeApi,
  sendPhoneCodeApi,
  updateUserPhoneApi
} from "@/api/user";
import {fetchUserInfo} from "@/store/modules/userStore";
import usePhoneAreaCode from "@/hooks/usePhoneAreaCode";

export default function ChangePhoneModal(props) {
  const { open, onOpen } = props;

  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { phone_number } = useSelector((state) => state.user);
  const { isMock } = useSelector((state) => state.common);

  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();
  const { areaCode, setAreaCode, areaOptions } = usePhoneAreaCode();

  useEffect(() => {
    // 打开模态框的时候触发，清除form数据
    if (open) {
      form.resetFields();
    }
  }, [open])

  const handleCancel = () => {
    onOpen(false);
  }

  const getFormCode = async () => {
    const { input_username } = form.getFieldsValue();

    const isPhone = phoneReg.test(input_username);
    if (!isPhone) {
      form.validateFields(['input_username']);
      return;
    }

    try {
      setCountLoading(true);
      await sendPhoneCodeApi(areaCode + input_username, areaCode, isMock)

      setCountLoading(false);
      startCountDown();
      // 发送成功后
      message.success('验证码已发送');

      // 注：这里模拟获取手机code，直接填充（后面去掉）
      if (process.env.REACT_APP_ENV === 'dev') {
        const res = await getPhoneCodeApi(areaCode +input_username);
        form.setFieldValue('code', res.auth_code);
      }

    } catch (e) {
      console.log(e);
    } finally {
      setCountLoading(false);
    }
  }

  const onFinish = async (values) => {
    const { code, input_username } = values;
    const passPhoneNumber = areaCode + input_username;
    try {
      antdUtils.setLoading(true);
      // 先检查新手机是否合法
      await checkUserPhoneApi({ phone_number: passPhoneNumber });
      // 合法的，采取请求
      await updateUserPhoneApi({
        phone_number: passPhoneNumber,
        phone_area_code: areaCode,
        code,
      });
      await dispatch(fetchUserInfo())
      message.success('更新手机号成功！')
      antdUtils.setLoading(false);
      // 关闭模态框
      onOpen(false);
    } catch (e) {
      console.log(e);
      antdUtils.setLoading(false);
    }
  }

  return (
    <Modal title={ phone_number ? '修改手机号码' : '设置手机号码' }
           open={open}
           footer={null}
           onCancel={handleCancel}>
      <div className="form-modal">
        <Form
          form={form}
          validateTrigger="onBlur"
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="form-item-phone">
            <Select
              className="form-item-phone-select"
              value={areaCode}
              onChange={(value) => setAreaCode(value)}
              options={areaOptions}
            />
            <Form.Item
              name="input_username"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject('手机号不能为空');
                    }

                    // 验证邮箱，验证手机号
                    const isPhone = phoneReg.test(value);
                    if (isPhone) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('请输入正确的手机号');
                    }
                  }
                }
              ]}
            >
              <Input placeholder="请输入新的手机号码" />
            </Form.Item>
          </div>

          <div className="form-item-code">
            <Form.Item
              style={{ flex: 1 }}
              name="code"
              rules={[
                {
                  required: true,
                  message: '验证码不能为空!',
                },
                {
                  len: 6,
                  message: '验证码为6位数!',
                },
              ]}
            >
              <Input placeholder="请输入验证码" />
            </Form.Item>
            <Button className="form-item-code-btn"
                    onClick={getFormCode}
                    disabled={(count > 0 && count < 60) || countLoading}
                    loading={countLoading}
            >
              { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
            </Button>
          </div>

          <Form.Item>
            <Button className="form-btn" type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
