import { createBrowserRouter } from 'react-router-dom';
import Layout from '@/pages/layout';
import Home from '@/pages/home';
import Join from '@/pages/join';
import NotFound from '@/pages/NotFound';
import Chat from '@/pages/chat';
import Draw from '@/pages/draw';
import BotApps from '@/pages/bot_apps';
import FormApps from '@/pages/form_apps';
import Recharge from '@/pages/recharge';
import User from '@/pages/user';
import Account from "@/pages/user/Account";
import Member from "@/pages/user/Member";
import Invite from "@/pages/user/Invite";
import Payment from "@/pages/user/Payment";
import { RouterPath } from '@/config/nav';


const router = createBrowserRouter([
  {
    path: RouterPath.home,
    element: <Home />,
  },
  {
    path: RouterPath.join,
    element: <Join />,
  },
  {
    path: RouterPath.chat,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Chat />,
      },
    ]
  },
  {
    path: RouterPath.draw,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Draw />,
      },
    ]
  },
  {
    path: RouterPath.bot,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <BotApps />,
      },
    ]
  },
  {
    path: RouterPath.app,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <FormApps />,
      },
    ]
  },
  {
    path: RouterPath.recharge,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Recharge />,
      },
    ]
  },
  {
    path: RouterPath.user,
    element: <Layout />,
    children: [
      {
        element: <User />,
        children: [
          {
            index: true,
            element: <Member />,
          },
          {
            path: RouterPath.account,
            element: <Account />,
          },
          {
            path: RouterPath.invite,
            element: <Invite />,
          },
          {
            path: RouterPath.payment,
            element: <Payment />,
          }
        ]
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
])

export default router;

