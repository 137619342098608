import { Avatar, Button, App, Popover } from 'antd';
import Icon, { PlusOutlined, EllipsisOutlined, PushpinOutlined, EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveConversationId, setConversations, updateConversationTop, updateConversationName, deleteConversation } from '@/store/modules/chatStore';
import { setLoginVisible } from '@/store/modules/userStore';
import {createConversationObj} from "@/utils";
import dayjs from "dayjs";
import {TopSvg} from "@/components/svg";
import EditConversationNameModal from "@/pages/chat/EditConversationNameModal";

function ChatBar() {
  const dispatch = useDispatch();
  const { message, modal } = App.useApp();
  const { conversations, activeConversationId, appsMap, isChatBarHide } = useSelector((state) => state.chat);
  const { userId, default_ai_app } = useSelector((state) => state.user);
  // 置顶的
  const [conversationPopOpenMap, setConversationPopOpenMap] = useState({});
  // 修改对话名称的
  const [editConversationNameModalOpen, setEditConversationNameModalOpen] = useState(false);
  const editConversation = useRef(null);

  useEffect(() => {
    if (conversations && conversations.length) {
      const map = {};
      conversations.forEach(item => {
        map[item.id] = false
      })
      setConversationPopOpenMap(map);
    }
  }, [conversations])

  // 切换对话
  function handleClickConversation(item) {
    dispatch(setActiveConversationId(item.id));
  }

  // 创建对话
  function handleCreateConversation() {
    let appId = null;
    if (conversations && conversations.length) {
      appId = conversations[0].appId;
    } else {
      message.error('请求异常，请刷新页面/联系客服');
      return;
    }

    // 登录验证
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    const defaultAppId = default_ai_app.id;
    const app = appsMap[appId];
    let conversation = null;

    if (defaultAppId === appId) {
      // 判断是否创建默认AI的复制体

      const copyEmptyConversations = conversations.filter((item) => {
        if (item.isNewChat && item.appId === defaultAppId && !item.messages.length) {
          return true;
        }
      });
      // 如果已经存在空对话了，则切换过去，不存在则创建对话
      if (copyEmptyConversations.length >= 1) {
        conversation = copyEmptyConversations[0];
        message.success('已是最新对话');
      } else {
        conversation = createConversationObj(app);
        // 排序
        const newConversations = [...conversations];
        // 找到非置顶的第一个位置
        const curNonTopIndex = newConversations.findIndex(item => !item.isTop);
        console.log(curNonTopIndex);
        if (curNonTopIndex > 0) {
          newConversations.splice(curNonTopIndex, 0, conversation);
        } else {
          newConversations.push(conversation);
        }

        dispatch(setConversations(newConversations));
      }
    } else {
      // 确定是应用，判断对话中是否已存在(直接切换），不存在，则创建
      conversation = conversations.find(item => item.appId === appId);
      if (!conversation) {
        conversation = createConversationObj(app, 'bot');
        dispatch(setConversations([...conversations, conversation]));
      }
    }

    dispatch(setActiveConversationId(conversation.id));
  }

  // 展示对话的简介
  function getConversationDescription(conversation) {
    if (default_ai_app.id === conversation.appId && !conversation.isNewChat) {
      return appsMap[conversation.appId].introduction;
    }

    const length = conversation.messages.length;
    if (length) {
      const content = conversation.messages[length - 1].content;
      return content ? content.slice(0, 30) : appsMap[conversation.appId].introduction;
    } else {
      return appsMap[conversation.appId].introduction;
    }
  }

  // 展示对话的时间
  function getConversationTime(conversation) {
    if (default_ai_app.id === conversation.appId && !conversation.isNewChat) {
      return '';
    }

    const conversationDate = dayjs(conversation.updated_at);
    const isToday = conversationDate.isSame(dayjs(), 'day');
    if (isToday) {
      return conversationDate.format('HH:mm')
    } else {
      return conversationDate.format('MM-DD HH:mm')
    }
  }

  // 打开操作
  function setConversationActionOpen(id, open) {
    const newMap = {...conversationPopOpenMap};
    newMap[id] = open;
    setConversationPopOpenMap(newMap);
  }


  function handleGroupItemActionClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }

  // 设置置顶/取消置顶
  function setConversationTop(conversation) {
    dispatch(updateConversationTop({ conversationId: conversation.id, isTop: !conversation.isTop }));
    setConversationActionOpen(conversation.id, false);
  }

  // 弹出删除窗口
  function showDeleteModal(conversation) {
    setConversationActionOpen(conversation.id, false);
    modal.confirm({
      title: '确定要删除该对话吗？',
      content: <div className="warning-color">注：将同时删除该对话的所有历史记录</div>,
      okText: '删除',
      onOk: () => {
        dispatch(deleteConversation(conversation.id));
      }
    })
  }

  // 弹出修改对话窗口
  function showEditNameModal(conversation) {
    setConversationActionOpen(conversation.id, false);
    editConversation.current = conversation;
    setEditConversationNameModalOpen(true);
  }

  // 修改对话名称
  function setConversationName(name) {
    dispatch(updateConversationName({ conversationId: editConversation.current.id, name }));
  }

  return (
    <div className="chat-bar"
         style={{ width: isChatBarHide ? '0' : 'var(--chat-bar-width-pc)', opacity: isChatBarHide ? 0 : 1 }}>
      {/* 搜索部分 */}
      <div className="chat-search-bar">
        <Button className="add-btn" type="primary" icon={<PlusOutlined />} onClick={() => handleCreateConversation()}>新建对话</Button>
      </div>
      {/* 对话列表 */}
      <div className="chat-group">
        { !!conversations.length && conversations.map((item, index) => {
          return (
            <div key={item.id}
                 onClick={ () => handleClickConversation(item) }
                 className={ activeConversationId === item.id ? 'chat-group-item active-chat-group' : 'chat-group-item' }>
              <div className="chat-group-item-avatar">
                <Avatar size={42} src={appsMap[item.appId].avatar_url}></Avatar>
                { item.isTop && <Icon className="avatar-is-top" component={TopSvg} /> }
              </div>
              <div className="chat-group-item-main">
                <div className="chat-group-item-title">
                  <div className="ellipsis chat-group-item-name">{ item.name }</div>
                  <div className="chat-group-item-time">{getConversationTime(item)}</div>
                </div>
                <div className="ellipsis chat-group-item-description">{ getConversationDescription(item) }</div>
                {
                  !!userId && index > 0 && <div className="group-item-action">
                    <Popover trigger="click"
                             rootClassName="conversation-action-popover"
                             open={conversationPopOpenMap[item.id]}
                             onOpenChange={(bool) => setConversationActionOpen(item.id, bool)}
                             content={
                               <div className="group-item-action-popover">
                                 <div className="action-item" onClick={() => setConversationTop(item)}>
                                   <PushpinOutlined className="action-item-icon" />
                                   <div className="action-item-text">{ item.isTop ? '取消置顶' : '置顶' }</div>
                                 </div>
                                 {
                                   item.isNewChat && (<div className="action-item" onClick={() => showEditNameModal(item)}>
                                     <EditOutlined className="action-item-icon" />
                                     <div className="action-item-text">编辑对话名称</div>
                                   </div>)
                                 }
                                 <div className="action-item error-color" onClick={() => showDeleteModal(item)}>
                                   <MinusCircleOutlined className="action-item-icon" />
                                   <div className="action-item-text">从对话列表删除</div>
                                 </div>
                               </div>
                             }>
                      <Button type="primary"
                              className={ conversationPopOpenMap[item.id] ? 'action-btn action-btn-active' : 'action-btn' }
                              onClick={handleGroupItemActionClick}
                              icon={<EllipsisOutlined className="more-action-icon" />}></Button>
                    </Popover>
                  </div>
                }
              </div>
            </div>
          )}) }
      </div>
      <EditConversationNameModal
        open={editConversationNameModalOpen}
        onOpen={setEditConversationNameModalOpen}
        conversation={editConversation.current}
        onFinish={setConversationName}
      ></EditConversationNameModal>
    </div>
  )
}

export default ChatBar;
