// 登录组件（注：动态生成的，如modal等组件，带主题的，需要引用App组件里的useApp()使用）
import {Form, Input, Button, Tabs, App, Radio, Select, Modal} from 'antd';
import Icon, {MailOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchLogin } from '@/store/modules/userStore';
import logo from '@/images/logo.png';
import useCountDown from '@/hooks/useCountDown';
import { sendEmailCodeApi, sendPhoneCodeApi, getPhoneCodeApi } from "@/api/user";
import { WechatSvg, GoogleSvg } from '@/components/svg'
import ForgetPasswordModal from "@/components/UserInfoChangeModal/ForgetPasswordModal";
import UserAgreementModal from "@/components/UserInfoChangeModal/UserAgreementModal";
import PrivacyPolicyModal from "@/components/UserInfoChangeModal/PrivacyPolicyModal";
import { emailReg, phoneReg } from '@/utils';
import usePhoneAreaCode from "@/hooks/usePhoneAreaCode";
import globalConfig from '@/config';

const initAuthMethod = 'code';
const initAuthType = 'email';
const loginTypeOptions = [
  { label: '邮箱', value: 'email' },
  { label: '手机号', value: 'phone' },
  { label: '用户名', value: 'username' }
];

const LoginModal = (props) => {
  const { open, onOpen } = props;
  const dispatch = useDispatch();
  const { isMock } = useSelector((state) => state.common);
  const { message } = App.useApp();
  const [authMethod, setAuthMethod] = useState(initAuthMethod); // code password
  const [authType, setAuthType] = useState(initAuthType); // email phone username
  const { areaCode, setAreaCode, areaOptions, resetAreaCode } = usePhoneAreaCode();
  const [form] = Form.useForm();
  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();

  const [modalOpenForgetPassword, setModalOpenForgetPassword] = useState(false);
  const [modalUserAgreement, setModalUserAgreement] = useState(false);
  const [modalPrivacyPolicy, setModalPrivacyPolicy] = useState(false);

  useEffect(() => {
    if (open) {
      setAuthMethod(initAuthMethod);
      setAuthType(initAuthType);
      resetAreaCode();
      form.resetFields();
    }
  }, [open])

  const onFinish = async (values) => {
    // 根据当前条件准备参数
    const { input_username, input_password } = values;

    const params = {
      auth_type: authType,
      auth_method: authMethod,
      [authMethod]: input_password,
    };

    if (authType === 'email') {
      params.email = input_username;
    }

    if (authType === 'phone') {
      params.phone_number = areaCode + input_username;
      params.phone_area_code = areaCode;
    }

    if (authType === 'username') {
      params.username = input_username;
    }

    dispatch(fetchLogin(params));
  };

  const handleChangeAuthMethod = (activeTabKey) => {
    setAuthMethod(activeTabKey);
    setAuthType(initAuthType);
    form.resetFields();
  }

  const handleChangeAuthTypeFromCode = () => {
    if (authType === 'email') {
      setAuthType('phone');
    }

    if (authType === 'phone') {
      setAuthType('email');
    }

    form.resetFields();
  }

  const handleChangeAuthTypeFromPassword = (e) => {
    setAuthType(e.target.value);
    form.resetFields();
  }

  const getFormCode = async () => {
    form.resetFields(['input_password']);
    // 1、验证是否是邮箱/手机号
    const { input_username } = form.getFieldsValue();

    // 如果是邮箱，验证后发送验证码
    if (authType === 'email') {
      const isEmail = emailReg.test(input_username);
      if (!isEmail) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        setCountLoading(true);
        await sendEmailCodeApi(input_username, isMock);
        startCountDown();

        // 发送成功后
        message.success('验证码已发送');
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
      }
    }

    // 如果是手机号，验证发送验证码（测试）
    if (authType === 'phone') {
      const isPhone = phoneReg.test(input_username);
      if (!isPhone) {
        form.validateFields(['input_username']);
        return;
      }

      try {
        setCountLoading(true);
        await sendPhoneCodeApi(areaCode +input_username, areaCode, isMock)
        startCountDown();

        // 发送成功后
        message.success('验证码已发送');

        // 注：这里模拟获取手机code，直接填充（后面去掉）
        if (process.env.REACT_APP_ENV === 'dev') {
          const res = await getPhoneCodeApi(areaCode + input_username);
          form.setFieldValue('input_password', res.auth_code);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
      }
    }
  }

  const handleCancel = () => {
    onOpen(false);
  }

  return (
    <Modal title="登录"
           width="700px"
           open={open}
           footer={null}
           onCancel={handleCancel}>
      <div className="login-container">
        <div className="login-box">
          <div className="login-header">
            <div className="login-title">
              <img className="login-title-logo" src={logo} alt=""/>
              <span className="login-title-text">{globalConfig.siteTitle}</span>
            </div>
            <div className="login-des">{ globalConfig.siteDescription }</div>
          </div>
          <div className="login-body">
            <Tabs
              activeKey={authMethod}
              centered
              items={[ { label: '验证码登录', key: 'code' }, { label: '密码登录', key: 'password' } ]}
              onChange={handleChangeAuthMethod}
            />

            <div className="form-container">
              <div className="form-sub-header">
                <div className="form-sub-header-left">
                  { authType === 'email' ? <MailOutlined /> : (authType === 'phone' ? <MobileOutlined /> : <UserOutlined />) }
                  <span className="form-sub-title">{ authType === 'email' ? '邮箱' : (authType === 'phone' ? '手机' : '用户名')  }</span>
                </div>
                <div className="form-sub-header-right">
                  {
                    authMethod === 'code' ? (<Button  type="link" onClick={handleChangeAuthTypeFromCode}>
                      { authType === 'email' ? '切换手机号' : '切换邮箱'}
                    </Button>) : <Radio.Group options={loginTypeOptions} onChange={handleChangeAuthTypeFromPassword} value={authType} />
                  }
                </div>
              </div>
              {/* form */}
              <Form
                form={form}
                validateTrigger="onBlur"
                onFinish={onFinish}
                autoComplete="off"
              >

                { authType === 'email' && (<Form.Item
                  name="input_username"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('邮箱不能为空');
                        }

                        // 验证邮箱，验证手机号
                        const isEmail = emailReg.test(value);
                        if (isEmail) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('请输入正确的邮箱');
                        }
                      }
                    }
                  ]}
                >
                  <Input placeholder="请输入邮箱" />
                </Form.Item>) }

                { authType === 'phone' &&  (<div className="form-item-phone">
                  <Select
                    className="form-item-phone-select"
                    value={areaCode}
                    onChange={(value) => setAreaCode(value)}
                    options={areaOptions}
                  />
                  <Form.Item
                    name="input_username"
                    style={{ flex: 1 }}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject('手机号不能为空');
                          }

                          // 验证邮箱，验证手机号
                          const isPhone = phoneReg.test(value);
                          if (isPhone) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('请输入正确的手机号');
                          }
                        }
                      }
                    ]}
                  >
                    <Input placeholder="请输入手机号" />
                  </Form.Item>
                </div>) }

                { authType === 'username' && (<Form.Item
                  name="input_username"
                  rules={[
                    {
                      required: true,
                      message: '用户名不能为空',
                    },
                    {
                      type: 'string',
                      min: 6,
                      max: 18,
                      pattern: /^[a-zA-Z]/,
                      message: '用户名必须以字母开头，包含字母、数字或者"_"组成的6-18位字符',
                    },
                  ]}
                >
                  <Input placeholder="请输入用户名" />
                </Form.Item>) }

                { authMethod === 'code' && (<div className="form-item-code">
                  <Form.Item
                    style={{ flex: 1 }}
                    name="input_password"
                    rules={[
                      {
                        required: true,
                        message: '验证码不能为空!',
                      },
                      {
                        len: 6,
                        message: '验证码为6位数!',
                      },
                    ]}
                  >
                    <Input placeholder="请输入验证码" />
                  </Form.Item>
                  <Button className="form-item-code-btn"
                          onClick={getFormCode}
                          disabled={(count > 0 && count < 60) || countLoading}
                          loading={countLoading}
                  >
                    { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
                  </Button>
                </div>) }

                { authMethod === 'password' && (<Form.Item
                  name="input_password"
                  rules={[
                    {
                      required: true,
                      message: '密码不能为空!',
                    },
                    {
                      min: 6,
                      max: 18,
                      message: '请输入6-18位密码',
                    },
                  ]}
                >
                  <Input.Password placeholder="请输入密码" />
                </Form.Item>) }

                <Form.Item>
                  <Button className="form-btn" type="primary" htmlType="submit">
                    { authMethod === 'code' ? '登录 / 注册' : '登录' }
                  </Button>
                </Form.Item>
              </Form>

              <div className="form-mark">
                登录代表您已同意
                <span className="link-text" onClick={() => setModalUserAgreement(true)}>用户协议</span>
                和<
                span className="link-text" onClick={() => setModalPrivacyPolicy(true)}>隐私策略</span>，
                未注册用户将自动创建账号
              </div>

              <div className="form-tip">
                <div className="form-tip-left">
                  <span>其他登录方式：</span>
                  <div className="other-auth-type">
                    <div className="auth-type-icon wechat-icon" onClick={ () => message.success('开发中')}>
                      <Icon component={ WechatSvg }></Icon>
                    </div>
                    <div className="auth-type-icon wechat-icon" onClick={ () => message.success('开发中')}>
                      <Icon component={ GoogleSvg }></Icon>
                    </div>
                  </div>
                </div>
                <div className="form-tip-right">
                  { authMethod === 'password' && (
                    <div className="forget-password-tip">
                      <Button type="link" onClick={() => setModalOpenForgetPassword(true)}>忘记密码？</Button>
                    </div>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
        <ForgetPasswordModal open={modalOpenForgetPassword} onOpen={setModalOpenForgetPassword}></ForgetPasswordModal>
        <UserAgreementModal open={modalUserAgreement} onOpen={setModalUserAgreement}></UserAgreementModal>
        <PrivacyPolicyModal open={modalPrivacyPolicy} onOpen={setModalPrivacyPolicy}></PrivacyPolicyModal>
      </div>
    </Modal>
  )
}

export default LoginModal;
