
import { v4 as uuidV4} from 'uuid';
import dayjs from "dayjs";

// type: 'bot'-智能体 'init'-初始化唯一助手 'chat'-普通聊天（唯一助手的复制体）
export const createConversationObj = (app, type = 'chat') => {
  const uuid = uuidV4();
  const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const settings = app.settings || {};

  return {
    appId: app.id,
    id: uuid,
    modelId: app.model_id,
    name: type === 'chat' ? '新的对话' : app.name,
    isTop: type === 'init',
    isNewChat: type === 'chat',
    created_at: time,
    updated_at: time,
    context_number: settings.max_context || 3, // 上下文数量
    max_tokens: settings.max_tokens || 2000, // 最大token
    temperature: settings.temperature || 1, // 随机因子
    messages: [
      // {
      //   created_at: '2022-12-12 10:10:10',
      //   role: 'user',
      //   id: 1,
      //   content: '消息内容',
      //   ok: true,
      // },
      // {
      //   created_at: '2022-12-12 10:10:10',
      //   role: 'assistant',
      //   id: 6,
      //   content: '消息内容',
      //   ok: false,
      // }
    ],
  }
};

