import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveConversationId,
  setConversations,
  setIsChatBarHide,
  updateActiveConversationMessages,
  updateConversationName,
} from '@/store/modules/chatStore';
import { setLoginVisible, fetchUserInfo } from '@/store/modules/userStore';
import {useState, useRef, useEffect, useMemo} from 'react';
import Icon, { MoreOutlined, EditOutlined, CheckOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Tooltip, Input, Button, Popover, App, Radio } from 'antd';
import {
  MenuFoldSvg,
  MenuOpenSvg,
  HistorySvg,
  DeleteSvg,
  ToolSvg,
  VoiceSvg,
  KeyboardSvg,
  SendSvg,
  CopySvg, ExportSvg
} from '@/components/svg';
import { Element, scrollSpy, animateScroll, scroller } from 'react-scroll';
import {setStorageChatConfig, getStorageChatConfig, createConversationObj, getConversation_dataStore} from '@/utils';
import { chatApi } from '@/api/chat';

import CommonHeader from "@/components/CommonHeader";
import ChatHistoryModal from "@/components/ChatHistoryModal";
import EmptyChat from "@/pages/chat/EmptyChat";
import SettingPopover from "@/pages/chat/SettingPopover";
import CommandCenter from "@/pages/chat/CommandCenter";
import useCopy from "@/hooks/useCopy";
import dayjs from "dayjs";
import {ChatRightWindowWidth} from "@/config/chat";
import Markdown from "@/components/markdown";
import {getStorageDrafts, setStorageDraft} from "@/utils";
import { throttle, debounce } from 'lodash';
import {getStoragePassInput, removeStoragePassInput, setStoragePassInput} from "@/utils/draft";

function ChatBox() {
  const dispatch = useDispatch();
  const { message, modal } = App.useApp();
  const os = navigator.userAgent.match(/Windows|Mac|Linux/i)[0];
  const isMac = os === 'Mac';
  const storageChatConfig = getStorageChatConfig();

  const { isChatBarHide, appsMap, conversations, activeConversation, activeConversationId, activeConversationApp } = useSelector((state) => state.chat);
  const { userId, avatar_url, default_ai_app, plan, username, email_mask, phone_mask, nickname } = useSelector((state) => state.user);

  const curUserName = useMemo(() => {
    return username || nickname || email_mask || phone_mask;
  }, [username, email_mask, phone_mask, nickname]);

  // 输入框的值
  const [inputValue, setInputValue] = useState('');
  const chatInputRef = useRef(null);
  // 消息框数据
  const [currentMessages, setCurrentMessages] = useState([]);
  // 发送方式
  const [sendType, setSendType] = useState('enter');
  const [sendTypeOpen, setSendTypeOpen] = useState(false);
  // 右侧窗口
  const [rightWindowOpen, setRightWindowOpen] = useState(false);
  // 其他
  const [chatHistoryModalOpen, setChatHistoryModalOpen] = useState(false);
  // 测试发送方式
  const [isMock, setIsMock] = useState(storageChatConfig ? storageChatConfig.isMock : false);
  const [isStream, setIsStream] = useState(storageChatConfig ? storageChatConfig.isStream : true);

  // 延迟滚动到底部
  const scrollToBottom = throttle(() => {
    animateScroll.scrollToBottom({
      containerId: 'chatContainerElement',
      smooth: true,
      delay: 0,
      duration: 100,
    });
  }, 100);

  // 延迟保存草稿
  const saveInputDraft = debounce(([text]) => {
    setStorageDraft(activeConversationId, text)
  }, 300);
  const { handleCopy } = useCopy();

  useEffect(() => {
    if (userId) {
      const steamDefaultValue = plan.level_id > 0 ? true : false;
      setIsMock(storageChatConfig ? storageChatConfig.isMock : false);
      setIsStream(storageChatConfig ? storageChatConfig.isStream : steamDefaultValue);
    }
  }, [userId]);

  useEffect(() => {
    if (activeConversation) {
      // 设置最新的对话消息
      if (default_ai_app.id === activeConversation.appId && !activeConversation.isNewChat) {
        // 第一个不允许有消息
        setCurrentMessages([]);
      } else {
        setCurrentMessages(activeConversation.messages);
      }

      // 从内存中找，发送消息
      const passInputObj = getStoragePassInput();
      if (passInputObj[activeConversation.id]) {
        setTimeout(() => {
          sendMessage(passInputObj[activeConversation.id]);
          dispatch(setIsChatBarHide(false));
          removeStoragePassInput();
        }, 50)
      }

      // 重置input输入框（从草稿中找）
      resetInput();
      // 渲染完成，滚动到底部
      setTimeout(() => {
        animateScroll.scrollToBottom({
          containerId: 'chatContainerElement',
          smooth: true,
          delay: 0,
          duration: 0,
        });
      })

      // scrollSpy.update();
    }
  }, [activeConversationId]);

  useEffect(() => {
    if (userId) {
      setStorageChatConfig({ isMock, isStream })
    }
  }, [isMock, isStream]);

  useEffect(() => {
    saveInputDraft(inputValue);
  }, [inputValue]);

  // 切换ChatBar显示/隐藏
  function toggleChatBar(status) {
    dispatch(setIsChatBarHide(status))
  }

  // 打开历史记录模态框
  function openHistoryModal() {
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    setChatHistoryModalOpen(true);
  }

  function changeSendType(type) {
    setSendType(type);
    setSendTypeOpen(false);
  }

  function onOpenSendTypeChange(bool) {
    setSendTypeOpen(bool);
  }

  function handleOnPressEnter(e) {
    if (sendType === 'enter') {
      // 如果发送方式是 enter，按下ctrlKey/metaKey，则回车
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
      if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
        setInputValue(inputValue + '\r\n');
      } else {
        sendMessage();
      }

    } else {
      // 如果发送方式 ctrl + enter，则按下了ctrlKey/metaKey才会发送消息，否则回车，不用管
      if (isMac && e.metaKey) {
        sendMessage();
      }

      if (!isMac && e.ctrlKey) {
        sendMessage();
      }
    }
  }

  async function sendMessage(text = '') {
    const value = text ? text : inputValue.trim();
    if (value) {
      // 如果写内容了
      // 1、判断是否登录，未登录则打开弹窗
      if (!userId) {
        dispatch(setLoginVisible(true));
        return;
        // dialog.history.push({
        //   userId: -1,
        //   content: <div><span className="link-text" onClick={() => dispatch(setLoginVisible(true))}>请登录</span>，游客模式下不支持该功能。</div>,
        //   createTime: new Date().getTime(),
        // });
      }

      // 判断是否是第一个空页面对话
      const defaultAppId = default_ai_app.id;
      let conversation = null;
      if (defaultAppId === activeConversation.appId && !activeConversation.isNewChat) {

        // 判断是否存在空对话
        const copyEmptyConversations = conversations.filter((item) => {
          if (item.isNewChat && item.appId === defaultAppId && !item.messages.length) {
            return true;
          }
        });
        // 如果已经存在空对话了，则切换过去，不存在则创建对话
        if (copyEmptyConversations.length >= 1) {
          conversation = copyEmptyConversations[0];
        } else {
          conversation = createConversationObj(appsMap[defaultAppId]);
          dispatch(setConversations([...conversations, conversation]));
        }
        setStoragePassInput(conversation.id, value);
        // 创建新对话，并把消息带过去
        dispatch(setActiveConversationId(conversation.id));

        return ;
      }

      // 2、已登录，则推送到对话最新历史里（滚动条到最底）
      const messages = [...currentMessages];
      let needChangeConversationName = false;

      messages.push({
        created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        role: 'user', // 用户发的消息
        id: userId,
        content: value,
        ok: true,
      });
      setCurrentMessages([...messages]);

      // 如果当前对话是复制体，且没有消息记录，则把对话名称修改成截取前20个字符
      // const conversationIndex = conversations.findIndex(item => item.id === activeConversationId);
      if (activeConversation.isNewChat && activeConversationApp.id === default_ai_app.id && !activeConversation.messages.length) {
        needChangeConversationName = true;
      }

      // 更新消息，准备发送请求
      dispatch(updateActiveConversationMessages({ type: 'add', messages: [...messages] }));

      console.log('---- 发送请求 ----');
      let output = '';
      messages.push({
        created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        role: 'assistant',
        id: activeConversationApp.id,
        content: '',
        loading: true,
        ok: true,
      });
      setCurrentMessages([...messages]);

      // 根据配置里的参数，取上下文
      let maxContext = activeConversationApp.settings.max_context || 3;
      if (activeConversation.hasOwnProperty('context_number')) {
        maxContext = activeConversation.context_number;
      }
      const historyMessages = [...activeConversation.messages].filter(item => !!item.ok).reverse().slice(0, maxContext * 2).map(item => {
        return {
          role: item.role,
          content: item.content
        }
      });

      const params = {
        max_tokens: activeConversationApp.settings.max_tokens,
        temperature: activeConversationApp.settings.temperature,
      };
      if (activeConversation.hasOwnProperty('max_tokens')) {
        params.max_tokens = activeConversation.max_tokens;
      }
      if (activeConversation.hasOwnProperty('temperature')) {
        params.temperature = activeConversation.temperature;
      }

      chatApi({
        "ai_app_type_id": 'chat',
        "conversation_id": activeConversation.id,
        "question": value,
        "is_mock": isMock,
        "is_stream": isStream,
        "ai_app_id": activeConversationApp.id,
        "ai_model_id": activeConversation.modelId,
        "context_messages": historyMessages,
        ...params
      }, {
        openCallback: () => {
          console.log('---- 请求成功 open ----');
        },
        messageCallback: (data) => {
          output += data.content;
          const msg = messages[messages.length - 1];

          if (msg.loading) {
            msg.loading = false;
          }
          msg.content = output;

          if (data.isError) {
            msg.ok = false;
          }

          setCurrentMessages([...messages]);
          scrollToBottom();
          if (needChangeConversationName) {
            dispatch(updateConversationName({ conversationId: activeConversationId, name: messages[0].content.slice(0, 20) }));
            needChangeConversationName = false;
          }
        },
        closeCallback: () => {
          console.log('closeCallback');

          const msg = messages[messages.length - 1];

          if (msg.loading) {
            msg.loading = false;
          }
          // 在这里更新金币余额
          dispatch(fetchUserInfo());
          dispatch(updateActiveConversationMessages({ type: 'add', messages: [...messages] }));
          setTimeout(() => {
            animateScroll.scrollToBottom({
              containerId: 'chatContainerElement',
              smooth: true,
              delay: 0,
              duration: 500
            });
          },50)
        },
        errorCallback: (e) => {
          console.log('errorCallback');

          const msg = messages[messages.length - 1];

          if (msg.loading) {
            msg.loading = false;
          }
          if (!msg.content) {
            msg.ok = false;
            msg.content = e.message;
          }

          setCurrentMessages([...messages]);
          dispatch(updateActiveConversationMessages({ type: 'add', messages: [...messages] }));
          dispatch(fetchUserInfo());

          setTimeout(() => {
            animateScroll.scrollToBottom({
              containerId: 'chatContainerElement',
              smooth: true,
              delay: 0,
              duration: 500
            });
          },50)
        }
      }).then(res => {
        console.log(res);
      }).catch(e => {
        // console.log(e);
      })

      setTimeout(() => {
        animateScroll.scrollToBottom({
          containerId: 'chatContainerElement',
          smooth: true,
          delay: 0,
          duration: 500
        });
      },50)
      // 2、清空输入框
      setInputValue('');
    } else {
      // 如果没有写内容，则不发送，清空输入框
      setInputValue('');
    }
  }

  function deleteMessage(msgIndex) {
    const messages = [...currentMessages];
    messages.splice(msgIndex, 1);
    setCurrentMessages(messages);
    dispatch(updateActiveConversationMessages({ type: 'delete', messages: messages }));
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'chatContainerElement',
        smooth: true,
        delay: 0,
        duration: 500
      });
    },50)
  }

  function deleteMessages() {
    if (!currentMessages.length) {
      message.warning('暂无历史记录');
      return;
    }

    modal.confirm({
      title: '确定要删除当前对话所有历史记录吗？',
      content: <div className="warning-color">注：删除后将不可恢复</div>,
      okText: '删除',
      onOk: () => {
        setCurrentMessages([]);
        dispatch(updateActiveConversationMessages({ type: 'delete', messages: [] }));
        setTimeout(() => {
          animateScroll.scrollToBottom({
            containerId: 'chatContainerElement',
            smooth: true,
            delay: 0,
            duration: 500
          });
        },50)
      }
    })
  }

  // 空页面，选择了示例
  function chooseText(text) {
    setInputValue(text);
    // 聚焦
    chatInputRef.current && chatInputRef.current.focus();
  }

  function resetInput() {
    const drafts = getStorageDrafts();
    setInputValue(drafts[activeConversationId] || '');
    chatInputRef.current && chatInputRef.current.focus();
  }

  // 打开右侧窗口
  function toggleRightWindow() {
    setRightWindowOpen(!rightWindowOpen);
  }

  function scrollToMessage(message) {
    setChatHistoryModalOpen(false);
    // 根据消息唯一性，从消息列表中找到对应的位置，滚动过去
    const messageId = message.role + '_' + message.id + '_' + message.created_at;
    scroller.scrollTo(messageId, {
      containerId: 'chatContainerElement',
      smooth: true,
      delay: 100,
      duration: 500,
    });
  }

  // 导出text
  async function handleExport() {
    // 如果是空对话，则显示暂无历史记录
    if (!activeConversation.isNewChat && activeConversationApp.id === default_ai_app.id) {
      return message.warning('暂无对话历史记录');
    }

    // 准备文本
    const curConversation = await getConversation_dataStore(activeConversationId);
    if (curConversation && curConversation.messages) {
      const allMessages = curConversation.messages;
      if (!allMessages.length) {
        return message.warning('暂无对话历史记录');
      }

      let str = '';
      allMessages.forEach((item) => {
        let master = '';
        if (item.role === 'user') {
          master = curUserName;
        }
        if (item.role === 'assistant') {
          master = activeConversationApp.name;
        }
        str += `[${item.created_at}] ${master}\r\n${item.content}\r\n\r`;
      })

      // 创建一个Blob对象，类型为纯文本
      const blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
      // 创建一个指向Blob对象的URL
      const url = URL.createObjectURL(blob);
      // 创建一个a标签
      const a = document.createElement("a");

      // 设置a标签属性
      a.href = url;
      a.download = '导出会话_' + dayjs().format('YYYY-MM-DD HH-mm-ss') + '.txt';

      // 模拟a标签点击，触发下载
      document.body.appendChild(a);
      a.click();

      // 清理并移除元素和对象URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      message.warning('暂无对话历史记录');
    }
  }

  return (
    <div className="chat-box" style={{ width: isChatBarHide ? '100%' : 'calc(100% - var(--chat-bar-width-pc))' }}>
      {/* 头部 */}
      <CommonHeader title={activeConversationApp && activeConversationApp.name} />

      <div className="chat-body">
        <div className="chat-body-left" style={{ width: rightWindowOpen ? `calc(100% - ${ChatRightWindowWidth}})` : '100%' }}>
          <div className="chat-list" id="chatContainerElement">
            {
              currentMessages.length ? (currentMessages.map((item, index) => {
                  return (
                    <Element key={item.created_at + index}
                             name={item.role + '_' + item.id + '_' + item.created_at}
                             className={ item.role === 'user' ? 'chat-item chat-item-self' : 'chat-item' }>
                      { item.role === 'assistant' ?
                        <Avatar className="chat-avatar" size={42} src={appsMap[item.id] && appsMap[item.id].avatar_url}></Avatar> :
                        userId ? <Avatar className="chat-avatar" size={42} src={avatar_url}></Avatar> :
                          <Avatar className="chat-avatar" size={42} icon={<UserOutlined />}></Avatar>
                      }
                      <div className="chat-item-content">
                        <div className={ item.ok ? 'chat-item-content-text' : 'chat-item-content-text chat-item-content-text-error' }>
                          <Markdown message={item}></Markdown>
                        </div>
                        {
                          !item.loading && (
                            <>
                              <div className="chat-item-content-time">
                                { item.created_at }
                              </div>
                              <div className="chat-item-content-actions">
                                <Tooltip title="复制">
                                  <Icon component={ CopySvg }
                                        className="chat-action-icon"
                                        onClick={ () => handleCopy(item.content) }></Icon>
                                </Tooltip>

                                <Tooltip title="删除">
                                  <Icon component={ DeleteSvg }
                                        className="chat-action-icon"
                                        onClick={() => deleteMessage(index)}></Icon>
                                </Tooltip>
                              </div>
                            </>
                          )
                        }
                      </div>
                    </Element>
                  )
                })) : (<EmptyChat app={activeConversationApp} onChoose={chooseText}></EmptyChat>)
            }
          </div>
          <div className="chat-footer">
            <div className="move-footer-line"></div>
            <div className="chat-toolbar">
              <div className="chat-toolbar-left">
                { isChatBarHide ?
                  <Tooltip title="展开聊天列表">
                    <Icon component={ MenuOpenSvg } className="chat-toolbar-icon" onClick={() => toggleChatBar(false)} />
                  </Tooltip> :
                  <Tooltip title="折叠聊天列表">
                    <Icon component={ MenuFoldSvg } className="chat-toolbar-icon" onClick={() => toggleChatBar(true)} />
                  </Tooltip>
                }
                <Tooltip title="对话记录">
                  <Icon component={ HistorySvg } className="chat-toolbar-icon" onClick={() => openHistoryModal()} />
                </Tooltip>
                <Tooltip title="清除对话记录">
                  <Icon component={ DeleteSvg } className="chat-toolbar-icon" onClick={() => deleteMessages()} />
                </Tooltip>
                <Tooltip title="导出对话记录txt">
                  <Icon component={ ExportSvg } className="chat-toolbar-icon" onClick={() => handleExport()} />
                </Tooltip>
                <Popover content={<SettingPopover />} title="参数调整" trigger="click">
                  <Tooltip title="参数调整" zIndex={888}>
                    <Icon component={ ToolSvg } className="chat-toolbar-icon" />
                  </Tooltip>
                </Popover>
                <Tooltip title="语音对话">
                  <Icon component={ VoiceSvg } className="chat-toolbar-icon" />
                </Tooltip>
              </div>
              <div className="chat-toolbar-right">
                {
                  activeConversationApp && activeConversationApp.settings.is_general ? (<Button type={ rightWindowOpen ? 'primary' : 'default'} size="small" onClick={toggleRightWindow}>
                    { (activeConversationApp && activeConversationApp.settings.is_general) ? '指令中心' : '其他' }
                  </Button>) : null
                }
                <Popover trigger="click"
                         open={sendTypeOpen}
                         onOpenChange={onOpenSendTypeChange}
                         content={
                           <div className="send-type-list">
                             <div className={ sendType === 'enter' ? 'send-type-item send-type-item-active' : 'send-type-item' }
                                  onClick={() => changeSendType('enter')}
                             >
                               { sendType === 'enter' && <CheckOutlined className="send-type-item-active-icon" /> }
                               <span>Enter 发送</span>
                             </div>
                             <div className={ sendType === 'ctrl+enter' ? 'send-type-item send-type-item-active' : 'send-type-item' }
                                  onClick={() => changeSendType('ctrl+enter')}
                             >
                               { sendType === 'ctrl+enter' && <CheckOutlined className="send-type-item-active-icon" /> }
                               <span>{ isMac ? '⌘' : 'Ctrl' } + Enter 发送</span>
                             </div>
                           </div>
                         }>
                  <Tooltip title="发送方式" zIndex={0}>
                    <Icon component={ KeyboardSvg } className="chat-toolbar-icon" />
                  </Tooltip>
                </Popover>
              </div>
            </div>
            <div className="chat-input">
              <Input.TextArea
                ref={chatInputRef}
                className="chat-input-textarea"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="说点什么吧..."
                autoSize={false}
                onPressEnter={handleOnPressEnter}
              />
            </div>
            <div className="chat-actions">
              {
                process.env.REACT_APP_ENV === 'dev' && (<div className="test-part">
                  <div className="test-part-send-type">
                    <span>测试切换发送选项：</span>
                    <Radio.Group buttonStyle="solid"
                                 onChange={(e) => setIsStream(e.target.value)} value={isStream}
                                 style={{ margin: '0 20px 0 20px' }}>
                      <Radio.Button value={false}>普通模式</Radio.Button>
                      <Radio.Button value={true}>高速模式</Radio.Button>
                    </Radio.Group>
                    <Radio.Group buttonStyle="solid"
                                 onChange={(e) => setIsMock(e.target.value)} value={isMock}>
                      <Radio.Button value={true}>模拟发送</Radio.Button>
                      <Radio.Button value={false}>真实发送</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>)
              }
              <div className="send-part">
                <span className="send-type-tip">{ sendType === 'enter' ? '↵ 发送 / ⌘ ↵ 换行' : '⌘ ↵ 发送 / ↵ 换行' }</span>
                <Button className="send-btn"
                        type="primary"
                        disabled={!inputValue}
                        onClick={sendMessage}
                        icon={<Icon component={ SendSvg } className="send-icon" />} size="middle" />
              </div>
            </div>
          </div>
        </div>
        <div className="chat-body-right" style={{ width: rightWindowOpen ? ChatRightWindowWidth : '0' }}>
          {
            (activeConversationApp && activeConversationApp.settings.is_general) ?
              <CommandCenter chooseCommand={chooseText} onOpen={setRightWindowOpen} /> : (
              <div>
                <div>右侧窗口</div>
                <div>非通用的  is_general 为false 时的窗口</div>
                <Button type="primary" onClick={() => setRightWindowOpen(false)}>关闭</Button>
              </div>
            )
          }
        </div>
      </div>

      <ChatHistoryModal
        type="chat"
        app={activeConversationApp}
        conversation_id={activeConversation ? activeConversation.id : null}
        onSelect={scrollToMessage}
        open={chatHistoryModalOpen}
        onOpen={setChatHistoryModalOpen}></ChatHistoryModal>
    </div>
  )
}

export default ChatBox;
