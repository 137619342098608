import {useSelector, useDispatch} from "react-redux";
import {Row, Col, Slider, InputNumber, Select} from "antd";
import {useEffect, useState} from 'react';
import {updateConversationSettings} from '@/store/modules/chatStore';


const IntegerStep = ({ min = 0, max, value, onChange, step = 1 }) => {
  const [inputValue, setInputValue] = useState(1);

  function handleChange(newValue) {
    setInputValue(newValue);
  }

  function onChangeComplete(value) {
    setInputValue(value);
    onChange(value);
  }

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Row>
      <Col span={12}>
        <Slider
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          onChangeComplete={onChangeComplete}
          value={typeof inputValue === 'number' ? inputValue : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={min}
          max={max}
          step={step}
          style={{
            marginLeft: '16px',
          }}
          value={inputValue}
          onChange={onChangeComplete}
        />
      </Col>
    </Row>
  );
};

export default function SettingPopover({ app }) {
  const dispatch = useDispatch();

  const { appsMap, conversations, activeConversation, activeConversationId, activeConversationApp } = useSelector((state) => state.chat);
  const { chat_ai_models } = useSelector((state) => state.user);

  const [modelValue, setModelValue] = useState(null);

  useEffect(() => {
    setModelValue(activeConversation.modelId);
  }, [activeConversation]);

  function onChangeContext(value) {
    dispatch(updateConversationSettings({
      conversationId: activeConversationId,
      settings: {
        context_number: value,
      }
    }))
  }

  function onChangeToken(value) {
    dispatch(updateConversationSettings({
      conversationId: activeConversationId,
      settings: {
        max_tokens: value,
      }
    }))
  }

  function onChangeTemperature(value) {
    dispatch(updateConversationSettings({
      conversationId: activeConversationId,
      settings: {
        temperature: value,
      }
    }))
  }

  function onChangeModelId(value) {
    console.log(value);
    dispatch(updateConversationSettings({
      conversationId: activeConversationId,
      settings: {
        modelId: value,
      }
    }))
  }

  return (
    <div className="setting-pop-content">
      <div className="setting-pop-item">
        <div className="setting-pop-item-label">模型</div>
        <div className="setting-pop-item-value">
          <Select
            style={{ width: '150px' }}
            value={modelValue}
            onChange={onChangeModelId}
            options={chat_ai_models}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        </div>
      </div>
      <div className="setting-pop-item">
        <div className="setting-pop-item-label">上下文数</div>
        <div className="setting-pop-item-value">
          <IntegerStep min={0} max={activeConversationApp.settings.max_context} value={activeConversation.hasOwnProperty('context_number') ? activeConversation.context_number : activeConversationApp.settings.max_context} onChange={onChangeContext}/>
        </div>
      </div>
      <div className="setting-pop-item">
        <div className="setting-pop-item-label">最大token</div>
        <div className="setting-pop-item-value">
          <IntegerStep min={0} max={activeConversationApp.settings.max_tokens} value={activeConversation.hasOwnProperty('max_tokens') ? activeConversation.max_tokens : activeConversationApp.settings.max_tokens} onChange={onChangeToken}/>
        </div>
      </div>
      <div className="setting-pop-item">
        <div className="setting-pop-item-label">随机因子</div>
        <div className="setting-pop-item-value">
          <IntegerStep min={0} max={2} step={0.1} value={activeConversation.hasOwnProperty('max_tokens') ? activeConversation.temperature : activeConversationApp.settings.temperature} onChange={onChangeTemperature}/>
        </div>
      </div>
    </div>
  )
};
