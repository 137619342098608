import { createSlice } from '@reduxjs/toolkit';
import globalConfig from '@/config/index';
import {antdUtils} from "@/utils";
import {getStorageIsMock, getStorageTheme, setStorageIsMock, setStorageTheme} from "@/utils/token";

const CommonStore = createSlice({
  name: 'common',
  // 初始化状态
  initialState: {
    theme: getStorageTheme() || globalConfig.siteTheme,
    isBarHide: false,
    isMock: getStorageIsMock(),
  },
  // 同步方法
  reducers: {
    // 设置主题（dark/light)
    setTheme(state, action) {
      antdUtils.setLoading && antdUtils.setLoading(true);
      const changeTheme = action.payload || globalConfig.siteTheme;
      state.theme = changeTheme;
      setStorageTheme(changeTheme);
      setTimeout(() => {
        antdUtils.setLoading && antdUtils.setLoading(false);
      }, 1000)
    },
    // 设置网站标题
    setSiteTitle(state, action) {
      document.title = action.payload || globalConfig.siteTitle;
    },
    // 设置网站描述
    setSiteDescription(state, action) {
      const children = action.payload || globalConfig.siteDescription;

      const descriptionElement = document.querySelector('meta[name="description"]');
      if (descriptionElement) {
        descriptionElement.setAttribute('content', children);
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'description');
        meta.setAttribute('content', children);
        document.head.appendChild(meta);
      }
    },
    // 设置isBarHide
    setIsBarHide(state, action) {
      state.isBarHide = action.payload;
      document.body.style.setProperty('--nav-width-pc', state.isBarHide ? '70px' : '160px');
    },
    setIsMock(state, action) {
      state.isMock = action.payload;
      setStorageIsMock(action.payload);
    }
  }
})

const { setTheme, setSiteTitle, setSiteDescription, setIsBarHide, setIsMock } = CommonStore.actions;

const reducer = CommonStore.reducer;

export { setTheme, setSiteTitle, setSiteDescription, setIsBarHide, setIsMock };
export default reducer;
