export const ChinaSvg = () => (
  <svg t="1710577192562" className="icon" viewBox="0 0 1365 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
       p-id="49235" width="1em" height="1em">
    <path
      d="M0 0m157.190104 0l943.140625 0q157.190104 0 157.190104 157.190104l0 628.760416q0 157.190104-157.190104 157.190105l-943.140625 0q-157.190104 0-157.190104-157.190105l0-628.760416q0-157.190104 157.190104-157.190104Z"
      fill="#F93939" p-id="49236"></path>
    <path
      d="M1137.741973 0H119.778859C53.601825 0 0 56.274057 0 125.752083v691.636458c0 69.478026 53.601825 125.752083 119.778859 125.752084h1017.963114c66.177034 0 119.778859-56.274057 119.77886-125.752084V125.752083c0-69.478026-53.601825-125.752083-119.77886-125.752083z"
      fill="#F93939" p-id="49237"></path>
    <path
      d="M329.234673 454.593781l-88.026458 48.571742 16.740746-102.959518-71.128522-72.857613 98.401005-14.93306 44.013229-93.685302 44.013229 93.685302L471.570312 327.348392l-71.285712 72.936208 16.897936 102.880923-87.947863-48.571742zM538.926272 188.628125h59.88943v62.876042h-59.88943v-62.876042zM598.894297 314.380208h59.889429v62.876042h-59.889429V314.380208z m0 125.752083h59.889429v62.876042h-59.889429v-62.876042z m-59.88943 125.752084h59.88943V628.760416h-59.88943v-62.876041z"
      fill="#FFDA2C" p-id="49238"></path>
  </svg>
)
