import {App, Button, Form, Input, Modal} from 'antd';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {antdUtils, emailReg} from "@/utils";
import useCountDown from "@/hooks/useCountDown";
import {checkUserEmailApi,sendEmailCodeApi,updateUserEmailApi} from "@/api/user";
import {fetchUserInfo} from "@/store/modules/userStore";

export default function ChangeEmailModal(props) {
  const { open, onOpen } = props;

  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { email } = useSelector((state) => state.user);
  const { isMock } = useSelector((state) => state.common);

  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();

  useEffect(() => {
    // 打开模态框的时候触发，清除form数据
    if (open) {
      form.resetFields();
    }
  }, [open])

  const handleCancel = () => {
    onOpen(false);
  }

  const getFormCode = async () => {
    const { input_username } = form.getFieldsValue();

    const isEmail = emailReg.test(input_username);
    if (!isEmail) {
      form.validateFields(['input_username']);
      return;
    }

    try {
      setCountLoading(true);
      await sendEmailCodeApi(input_username, isMock)
      startCountDown();

      // 发送成功后
      message.success('验证码已发送');
    } catch (e) {
      console.log(e);
    } finally {
      setCountLoading(false);
    }
  }

  const onFinish = async (values) => {
    const { code, input_username } = values;
    try {
      antdUtils.setLoading(true);
      // 先检查新邮箱是否合法
      await checkUserEmailApi({ email: input_username });
      // 合法的，采取请求
      await updateUserEmailApi({
        email: input_username,
        code,
      });
      await dispatch(fetchUserInfo())
      message.success('更新邮箱成功！')
      antdUtils.setLoading(false);
      // 关闭模态框
      onOpen(false);
    } catch (e) {
      console.log(e);
      antdUtils.setLoading(false);
    }
  }

  return (
    <Modal title={ email ? '修改邮箱' : '设置邮箱' }
           open={open}
           footer={null}
           onCancel={handleCancel}>
      <div className="form-modal">
        <Form
          form={form}
          validateTrigger="onBlur"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="input_username"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('邮箱不能为空');
                  }

                  // 验证
                  const isEmail = emailReg.test(value);
                  if (isEmail) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('请输入正确的邮箱');
                  }
                }
              }
            ]}
          >
            <Input placeholder="请输入新的邮箱" />
          </Form.Item>

          <div className="form-item-code">
            <Form.Item
              style={{ flex: 1 }}
              name="code"
              rules={[
                {
                  required: true,
                  message: '验证码不能为空!',
                },
                {
                  len: 6,
                  message: '验证码为6位数!',
                },
              ]}
            >
              <Input placeholder="请输入验证码" />
            </Form.Item>
            <Button className="form-item-code-btn"
                    onClick={getFormCode}
                    disabled={(count > 0 && count < 60) || countLoading}
                    loading={countLoading}
            >
              { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
            </Button>
          </div>

          <Form.Item>
            <Button className="form-btn" type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
