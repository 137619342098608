import { request } from '@/utils';

// -------- /v1/auth 认证相关 ---------
// 登录（注册一体）
export const loginApi = (data) => request.post('/v1/auth/login', data);

// 退出登录
export const logoutApi = () => request.post('/v1/auth/logout');

// 发送邮箱验证码
export const sendEmailCodeApi = (email, is_mock = false) =>
  request.post('/v1/auth/email-code', { email, is_mock });

// 发送手机验证码
export const sendPhoneCodeApi = (phone_number, phone_area_code, is_mock = false) =>
  request.post('/v1/auth/phone-code', { phone_number, phone_area_code, is_mock });

// 获取手机验证码（开发测试用）
export const getPhoneCodeApi = (phone_number) =>
  request.get('/v1/auth/code', {
    params : { identifier: phone_number },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

// -------- /v1/user 用户相关 ---------
// 获取用户信息
export const getUserInfoApi = () => request.get('/v1/user/profile');

// 上传图片地址
export const uploadAvatarApi = (formData) =>
  request.put('/v1/user/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

// 修改用户信息（用户名/昵称）
export const updateUserInfoApi = (data) =>
  request.put('/v1/user/profile', data);

// 检查新邮箱是否合法
export const checkUserEmailApi = (data, config = {}) =>
  request.post('/v1/user/new-email/check', data, config);

// 修改邮箱
export const updateUserEmailApi = (data) => {
  const params = { ...data, auth_code: data.code };
  delete params.code;
  request.put('/v1/user/email', params);
}


// 检查新手机号是否合法
export const checkUserPhoneApi = (data, config = {}) =>
  request.post('/v1/user/new-phone/check', data, config);

// 修改手机号
export const updateUserPhoneApi = (data) => {
  const params = { ...data, auth_code: data.code };
  delete params.code;
  request.put('/v1/user/phone', params);
}

// 设置密码
export const updateUserPasswordApi = (data) => {
  const params = { ...data, auth_code: data.code };
  delete params.code;
  request.put('/v1/user/password', params);
}

// 检查重置密码的邮箱/手机号
export const checkForgetPasswordApi = (data) =>
  request.put('/v1/user/password/check-reset', data);

// 重置密码
export const resetUserPasswordApi = (data) => {
  const params = { ...data, auth_code: data.code };
  delete params.code;
  request.put('/v1/user/password/reset', params)
};

