import { Button, App} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {fetchLogout} from '@/store/modules/userStore';
import { useState } from 'react';
import ChangePasswordModal from "@/components/UserInfoChangeModal/ChangePasswordModal";
import ChangeEmailModal from "@/components/UserInfoChangeModal/ChangeEmailModal";
import ChangePhoneModal from "@/components/UserInfoChangeModal/ChangePhoneModal";
import ChangeUserInfoModal from "@/components/UserInfoChangeModal/ChangeUserInfoModal";

const EditModalType = {
  changeEmail: 'change-email',
  changePassword: 'change-password',
  changePhone: 'change-phone',
  changeUserInfo: 'change-userinfo',
}

function Account() {
  const dispatch = useDispatch();
  const { userId, username, email_mask, phone_mask, nickname, avatar_url, is_password_set } = useSelector((state) => state.user)
  const { modal } = App.useApp();

  const [modalOpenChangePassword, setModalOpenChangePassword] = useState(false);
  const [modalOpenChangeEmail, setModalOpenChangeEmail] = useState(false);
  const [modalOpenChangePhone, setModalOpenChangePhone] = useState(false);
  const [modalOpenChangeUserInfo, setModalOpenChangeUserInfo] = useState(false);

  const logout = () => {
    modal.confirm({
      title: '确定要退出登录吗？',
      onOk: () => dispatch(fetchLogout())
    })
  }

  function openModal(type) {
    if (type === EditModalType.changeUserInfo) {
      setModalOpenChangeUserInfo(true);
    }

    if (type === EditModalType.changeEmail) {
      setModalOpenChangeEmail(true);
    }

    if (type === EditModalType.changePhone) {
      setModalOpenChangePhone(true);
    }

    if (type === EditModalType.changePassword) {
      setModalOpenChangePassword(true);
    }
  }

  return (
    <div className="account-box">
      <div className="account-box-header">
        <img className="account-avatar" src={avatar_url} alt="" />
        <div className="account-info">
          <div className="account-info-username">{ username || email_mask || phone_mask }</div>
          <div className="account-info-id">ID： { userId }</div>
          { nickname ? (<div className="account-info-nickname">{ nickname }</div>) : (<div className="account-info-nickname">暂无昵称，<span className="link-text" onClick={() => openModal(EditModalType.changeUserInfo)}>去填写</span></div>) }
          <Button className="edit-btn" shape="round" onClick={() => openModal(EditModalType.changeUserInfo)}>编辑</Button>
        </div>
      </div>
      <div className="account-edit-item">
        <div className="account-edit-item-left">
          <div className="left-label">邮箱</div>
          <div className="left-value">{ email_mask ? (<div className="success-color">已设置</div>) : (<div>未设置，设置后即可用该邮箱登录</div>) }</div>
        </div>
        <div className="account-edit-item-right">
          <Button className="edit-item-right-btn" shape="round" onClick={() => openModal(EditModalType.changeEmail)}>{ email_mask ? '修改邮箱' : '设置邮箱' }</Button>
        </div>
      </div>

      <div className="account-edit-item">
        <div className="account-edit-item-left">
          <div className="left-label">手机</div>
          <div className="left-value">{ phone_mask ? (<div className="success-color">已设置</div>) : (<div>未设置，设置后即可用该手机号登录</div>) }</div>
        </div>
        <div className="account-edit-item-right">
          <Button className="edit-item-right-btn" shape="round" onClick={() => openModal(EditModalType.changePhone)}>{ phone_mask ? '修改手机' : '设置手机' }</Button>
        </div>
      </div>

      <div className="account-edit-item">
        <div className="account-edit-item-left">
          <div className="left-label">密码</div>
          <div className="left-value">{ is_password_set ? (<div className="success-color">已设置</div>) : (<div>未设置，为账户添加密码后，你便可以使用自己的密码登录</div>) }</div>
        </div>
        <div className="account-edit-item-right">
          <Button className="edit-item-right-btn" shape="round" onClick={() => openModal(EditModalType.changePassword)}>{ is_password_set ? '修改密码' : '设置密码' }</Button>
        </div>
      </div>

      <div className="account-operation">
        <Button className="account-operation-btn" shape="round" danger onClick={ logout }>退出登录</Button>
      </div>

      <ChangePasswordModal open={modalOpenChangePassword} onOpen={setModalOpenChangePassword} />
      <ChangeEmailModal open={modalOpenChangeEmail} onOpen={setModalOpenChangeEmail} />
      <ChangePhoneModal open={modalOpenChangePhone} onOpen={setModalOpenChangePhone} />
      <ChangeUserInfoModal open={modalOpenChangeUserInfo} onOpen={setModalOpenChangeUserInfo} />
    </div>
  )
}
export default Account;
