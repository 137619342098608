import {App, Avatar, Button, Input, Modal, Upload} from 'antd';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {antdUtils} from "@/utils";
import {updateUserInfoApi, uploadAvatarApi} from "@/api/user";
import {fetchUserInfo, setUserAvatar} from "@/store/modules/userStore";
import Icon from "@ant-design/icons";
import {UploadSvg} from "@/components/svg";
export default function ChangeUserInfoModal(props) {
  const { open, onOpen } = props;

  const dispatch = useDispatch();
  const { message } = App.useApp();
  const { username, nickname, avatar_url } = useSelector((state) => state.user)

  const [editUsername, setEditUsername] = useState('');
  const [editNickname, setEditNickname] = useState('');

  useEffect(() => {
    if (!open) {
      setEditUsername(username);
      setEditNickname(nickname);
    }
  }, [open])

  const handleCancel = () => {
    onOpen(false);
  }

  const beforeUpload = (file) => {
    const isImg = file.type.startsWith('image/');
    if (!isImg) {
      message.error(`${file.name} 不是一个图片文件`);
    }
    return isImg || Upload.LIST_IGNORE;
  }

  const uploadAvatarChange = (info) => {
    // if (info.file.status === 'uploading') {
    //   antdUtils.setLoading(true);
    // }

    if (info.file.status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully`);
      // antdUtils.setLoading(false);
      if (info.file.response) {
        message.success('上传头像成功');
        const avatar_url = info.file.response.avatar_url;
        dispatch(setUserAvatar(avatar_url));
      }
    }

    // if (info.file.status === 'error') {
    //   message.error(`${info.file.name} 文件上传失败`);
    //   antdUtils.setLoading(false);
    // }
  }

  const updateUserInfo = async () => {
    try {
      antdUtils.setLoading(true);
      await updateUserInfoApi({
        username: editUsername,
        nickname: editNickname
      });
      message.success('更新成功');
      dispatch(fetchUserInfo());
      handleCancel();
      antdUtils.setLoading(false);
    } catch (e) {
      console.log(e);
      antdUtils.setLoading(false);
    }
  }

  const customUploadAvatarRequest = (option) => {
    const { onSuccess, onError, file } = option;
    const formData = new FormData();
    formData.append('file', file);
    antdUtils.setLoading(true);
    uploadAvatarApi(formData).then(res => {
      antdUtils.setLoading(false);
      onSuccess(res, file);
    }).catch((e) => {
      antdUtils.setLoading(false);
      onError(e)
    })
  }

  return (
    <Modal title="编辑用户信息"
           open={open}
           footer={null}
           onCancel={handleCancel}>
      <div className="change-user-info-modal">
        <div className="person-avatar">
          <Avatar className="person-avatar-img" size={82} src={avatar_url}></Avatar>
          <Upload showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={customUploadAvatarRequest}
                  onChange={uploadAvatarChange}>
            <Button className="person-avatar-btn" icon={<Icon component={ UploadSvg } />}>上传头像</Button>
          </Upload>
        </div>
        <div className="person-edit-item">
          <div className="person-edit-item-value">
            <Input value={editUsername}
                   onChange={(e) => setEditUsername(e.target.value)}
                   placeholder="未填写"
                   allowClear
                   prefix={<div className="person-edit-item-label">用户名</div>}></Input>
          </div>
        </div>
        <div className="person-edit-item">
          <div className="person-edit-item-value">
            <Input value={editNickname}
                   onChange={(e) => setEditNickname(e.target.value)}
                   placeholder="未填写"
                   allowClear
                   prefix={<div className="person-edit-item-label">昵称</div>}></Input>
          </div>
        </div>
        <div className="person-operation">
          <Button className="person-operation-btn" type="primary" onClick={ updateUserInfo }>更新信息</Button>
        </div>
      </div>
    </Modal>
  )
}
