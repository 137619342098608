import {App, Button, Form, Input, Modal} from 'antd';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserInfo } from '@/store/modules/userStore';
import useCountDown from '@/hooks/useCountDown';
import {sendEmailCodeApi, sendPhoneCodeApi, getPhoneCodeApi, updateUserPasswordApi} from "@/api/user";
import { antdUtils } from '@/utils';
import { MailOutlined, MobileOutlined } from "@ant-design/icons";
import './index.scss';

export default function ChangePasswordModal(props) {
  const { open, onOpen } = props;
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { is_password_set, email, phone_number, phone_mask, email_mask, phone_area_code } = useSelector((state) => state.user);
  const { isMock } = useSelector((state) => state.common);

  const [authType, setAuthType] = useState('email');
  const { count, startCountDown, countLoading, setCountLoading } = useCountDown();

  useEffect(() => {
    // 打开模态框的时候触发，清除form数据
    if (open) {
      form && form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (email && phone_number) {
      setAuthType('email');
    } else if (email) {
      setAuthType('email');
    } else if (phone_number) {
      setAuthType('phone');
    }
  }, [email, phone_number])

  const handleCancel = () => {
    onOpen(false);
  }

  const handleChangeAuthType = () => {
    if (authType === 'email') {
      setAuthType('phone');
    }

    if (authType === 'phone') {
      setAuthType('email');
    }
  }

  const getFormCode = async () => {
    // 如果是邮箱，发送验证码
    if (authType === 'email') {

      try {
        setCountLoading(true);
        await sendEmailCodeApi(email, isMock);
        startCountDown();

        // 发送成功后
        message.success('验证码已发送');
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
      }
    }

    // 如果是手机号 发送验证码（测试）
    if (authType === 'phone') {
      try {
        setCountLoading(true);
        await sendPhoneCodeApi(phone_number, phone_area_code || '+86', isMock)

        startCountDown();
        // 发送成功后
        message.success('验证码已发送');

        // 注：这里模拟获取手机code，直接填充（后面去掉）
        if (process.env.REACT_APP_ENV === 'dev') {
          const res = await getPhoneCodeApi(phone_number);
          form.setFieldValue('code', res.auth_code);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setCountLoading(false);
      }
    }
  }

  const onFinish = async (values) => {
    console.log(values);
    // 根据当前条件准备参数
    const { code, password } = values;
    // 准备参数
    const params = {};

    if (authType === 'email') {
      Object.assign(params, {
        auth_type: 'email',
        // email,
        code,
        password
      })
    }

    if (authType === 'phone') {
      Object.assign(params, {
        auth_type: 'phone',
        // phone_number,
        code,
        password
      })
    }

    try {
      antdUtils.setLoading(true);
      await updateUserPasswordApi(params);
      dispatch(fetchUserInfo())
      message.success('更新密码成功！')
      antdUtils.setLoading(false);
      // 关闭模态框
      onOpen(false);
    } catch (e) {
      console.log(e);
      antdUtils.setLoading(false);
    }
  }

  return (
    <Modal title={is_password_set ? '修改密码' : '设置密码'}
           open={open}
           footer={null}
           onCancel={handleCancel}>

      <div className="form-modal">
        <div className="form-sub-header">
          <div className="form-sub-header-left">
            { authType === 'email' ? <MailOutlined /> : <MobileOutlined /> }
            <span className="form-sub-title">{ authType === 'email' ? '邮箱验证' : '手机验证' }</span>
          </div>
          <div className="form-sub-header-right" style={{ display: email && phone_number ? 'block' : 'none' }}>
            <Button type="link" onClick={handleChangeAuthType}>
              { authType === 'email' ? '使用手机号验证' : '使用邮箱验证' }
            </Button>
          </div>
        </div>

        <Form
          form={form}
          validateTrigger="onBlur"
          onFinish={onFinish}
          autoComplete="off"
        >

          { authType === 'email' && <div className="form-item"><Input value={email_mask} disabled /></div> }

          { authType === 'phone' && <div className="form-item"><Input value={phone_mask} disabled /></div> }


          <div className="form-item-code">
            <Form.Item
              style={{ flex: 1 }}
              name="code"
              rules={[
                {
                  required: true,
                  message: '验证码不能为空!',
                },
                {
                  len: 6,
                  message: '验证码为6位数!',
                },
              ]}
            >
              <Input placeholder="请输入验证码" />
            </Form.Item>
            <Button className="form-item-code-btn"
                    onClick={getFormCode}
                    disabled={(count > 0 && count < 60) || countLoading}
                    loading={countLoading}
            >
              { count >= 60 ? '获取验证码' : `${count}s后重新获取` }
            </Button>
          </div>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '密码不能为空!',
              },
              {
                min: 6,
                max: 18,
                message: '请输入6-18位密码',
              },
            ]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>

          <Form.Item
            name="retry_password"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject('确认密码不能为空')
                  }

                  const password = form.getFieldValue('password');
                  if (value !== password) {
                    return Promise.reject('两次密码不一致')
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder="请输入确认密码" />
          </Form.Item>

          <Form.Item>
            <Button className="form-btn" type="primary" htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
