import axios from "axios";
import { antdUtils } from '@/utils';
import { getStorageToken  } from '@/utils';
import store from '@/store';
import router from '@/router';
import { clearUserStore } from '@/store/modules/userStore';

// 成功的状态码
const SUCCESS_CODE = [0];
const UN_AUTH_CODE = [401];

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

// 请求拦截
request.interceptors.request.use((config) => {
  // 设置token
  const token = getStorageToken();
  if (token) {
    config.headers.Token = token;
  }
  console.log(config);
  return config;
}, (error) => {
  return Promise.reject(error)
});

// 响应拦截
request.interceptors.response.use((response) => {
  // console.log(response);
  const baseURL = response.config.baseURL;
  // 本站点发出的请求，返回code规则
  if (baseURL.startsWith(process.env.REACT_APP_API_URL)) {
    const { code, message:msg, data } = response.data;
    if (SUCCESS_CODE.includes(code)) {
      return response.data.data
    } else if (UN_AUTH_CODE.includes(code)) {
      antdUtils.message && antdUtils.message.error(msg);
      // 移除本地存储
      store.dispatch(clearUserStore());
      // router.navigate('/');
      return response.data
    } else {
      if (!response.config.hideErrorMessage) {
        antdUtils.message && antdUtils.message.error(msg);
      }
      return Promise.reject(response.data);
    }
  }

  return response.data;
}, (error) => {
  console.log('------response响应error-----', error);
  if (error && error.message) {
    antdUtils.message && antdUtils.message.error(error.message);
  }
  return Promise.reject(error)
});

export { request }
