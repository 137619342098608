import {AppstoreFilled, SketchCircleFilled, UserOutlined, WechatFilled} from "@ant-design/icons";
import {DrawSvg, IntelligentSvg} from "@/components/svg";

export const NavPage = {
  chat: 'chat',
  draw: 'draw',
  botApps: 'bot_apps',
  formApps: 'form_apps',
  recharge: 'recharge',
  user: 'user',
};

export const RouterPath = {
  home: '/',
  join: '/join',
  chat: '/chat',
  draw: '/draw',
  bot: '/bot',
  app: '/app',
  recharge: '/recharge',
  user: '/user',
  account: '/user/account',
  invite: '/user/invite',
  payment: '/user/payment',
};

export const navList =  [
  {
    id: NavPage.chat,
    path: RouterPath.chat,
    name: 'AI聊天',
    icon: WechatFilled,
  },
  {
    id: NavPage.draw,
    name: 'AI绘画',
    path: RouterPath.draw,
    icon: DrawSvg,
    isSvg: true,
  },
  {
    id: NavPage.botApps,
    name: '智能体',
    path: RouterPath.bot,
    icon: IntelligentSvg,
    isSvg: true
  },
  {
    id: NavPage.formApps,
    name: '应用中心',
    path: RouterPath.app,
    icon: AppstoreFilled,
  },
  {
    id: NavPage.recharge,
    name: '购买会员',
    path: RouterPath.recharge,
    icon: SketchCircleFilled,
  },
  {
    id: NavPage.user,
    name: '个人中心',
    path: RouterPath.user,
    icon: UserOutlined,
  },
]
