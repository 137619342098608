import './NotFound.scss';

function NotFound() {
  return (
    <div className="not-found-page">
      Page Not Found
    </div>
  )
}

export default NotFound;
