import {Button, Modal, Tabs, QRCode, Popover, App} from 'antd';
import {useState, useEffect, useRef, useMemo} from 'react';
import {AlipayPaySvg, WechatPaySvg} from "@/components/svg";
import Icon, {QuestionCircleOutlined, RedoOutlined, CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {orderPaymentApi, updateOrderStatusApi} from "@/api/payment";
import {antdUtils} from "@/utils";
import Img_wechat from '@/images/wechat_pay.png';
import Img_alipay from '@/images/alipay_pay.png';
import {useSelector, useDispatch} from "react-redux";
import { fetchUserInfo } from '@/store/modules/userStore';
import useOrderLoop from "@/hooks/useOrderLoop";
import {useNavigate} from "react-router-dom";
import {RouterPath} from "@/config/nav";
import { qrcodeExpiredTime } from '@/config/recharge'

const wechatId = 'wechat';
const alipayId = 'alipay';

const payTypeImgMap = {
  [wechatId]: Img_wechat,
  [alipayId]: Img_alipay,
};

const tabItemsMap = {
  [wechatId]: {
    key: wechatId,
    label: <div className="pay-type-tab-label"><Icon component={WechatPaySvg} className="pay-type-icon"></Icon><span>微信支付</span></div>,
  },
  [alipayId]: {
    key: alipayId,
    label: <div className="pay-type-tab-label"><Icon component={AlipayPaySvg} className="pay-type-icon"></Icon><span>支付宝支付</span></div>,
  },
};

const ErrorType = {
  emptyChannel: 'empty-channel', // 没有支付通道
}

const OrderStatusType = {
  paid: 'paid',
  unpaid: 'unpaid',
  failed: 'failed',
}

const productType = {
  plan: 'plan', // 套餐
  coins: 'coins', // 金币
}

export default function OrderPrepareModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { plan } = useSelector((state) => state.user);
  const { open, onOpen, prepareInfo, prepareParams } = props;
  const [tabItems, setTabItems] = useState([]);
  const [payType, setPayType] = useState('');
  const [qrcodeIconId, setQrcodeIconId ] = useState('');
  const [channels, setChannels ] = useState([]);
  const [activeChannelId, setActiveChannelId ] = useState('');
  const [orderInfo, setOrderInfo] = useState(null);
  const orderMap = useRef({});
  const [errorType, setErrorType] = useState('');

  const { startLoop, resetLoop, statusId, setStatusId, startCountDownQrCode, resetCountDownQrCode, qrcodeExpiredCount, isQrcodeExpired } = useOrderLoop();

  useEffect(() => {
    if (!open) {
      setActiveChannelId('');
      setQrcodeIconId('');
      setOrderInfo(null);
      orderMap.current = {};
      setErrorType('');
      setTabItems([]);
      setChannels([]);
      setPayType('');

      resetLoop();
      setStatusId('');
      resetCountDownQrCode();
    }
  }, [open]);

  useEffect(() => {
    if (prepareInfo) {
      // 对传过来的数据进行处理
      if (!prepareInfo.payment_channels.length) {
        // 没有任何支付通道，联系客服
        setErrorType(ErrorType.emptyChannel)
        return;
      } else {
        setErrorType('');
      }

      const hasWechat = prepareInfo.payment_channels.find(item => item.payment_method_id === wechatId);
      const hasAlipay = prepareInfo.payment_channels.find(item => item.payment_method_id === alipayId);
      const tabArr = [];
      if (hasWechat) {
        tabArr.push(tabItemsMap[wechatId]);
      }
      if (hasAlipay) {
        tabArr.push(tabItemsMap[alipayId]);
      }

      setTabItems(tabArr);

      // 设置通道里的第一个
      if (prepareInfo.payment_channels && prepareInfo.payment_channels.length > 0) {
        const firstChannel = prepareInfo.payment_channels[0];
        const type = firstChannel.payment_method_id;
        changePayType(type);
        // selectChannel(prepareInfo.payment_channels[0].id, type);
      }
    }
  }, [prepareInfo]);

  useEffect(() => {
    if (orderInfo) {
      // 如果有订单信息，开始轮循（先重置）
      console.log('监听到二维码切换或刷新, 结束');
      resetLoop();
      startLoop(orderInfo.order_no);
      // 订单信息变化，获取到二维码创建时间
      resetCountDownQrCode();
      startCountDownQrCode(orderInfo.create_at);
    }
  }, [orderInfo]);

  const formatTime = useMemo(() => {
    if (qrcodeExpiredCount > 0) {
      if (qrcodeExpiredCount > 60) {
        return Math.floor(qrcodeExpiredCount / 60) + '分' + qrcodeExpiredCount % 60 + '秒'
      } else {
        return qrcodeExpiredCount + '秒'
      }
    } else {
      return ''
    }
  }, [qrcodeExpiredCount])

  function changePayType(type) {
    setPayType(type);
    const arr = prepareInfo.payment_channels.filter(item => item.payment_method_id === type);
    setChannels(arr);
  }

  async function refreshQrcode() {
    try {
      antdUtils.setLoading && antdUtils.setLoading(true);
      const res = await orderPaymentApi({
        ...prepareParams,
        payment_channel_id: activeChannelId,
      })
      res.create_at = new Date().getTime();
      orderMap.current[activeChannelId] = res;
      setOrderInfo(res);
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading && antdUtils.setLoading(false);
    }
  }

  async function selectChannel(id, iconId) {
    setActiveChannelId(id);

    const info = orderMap.current[id];
    if (info) {
      // 如果有该通道的数据
      // 判断是否过期（超过5分钟，未过期则直接渲染）
      const curTime = new Date().getTime();
      if ((curTime - info.create_at) < qrcodeExpiredTime) {
        setOrderInfo(orderMap.current[id]);
        setQrcodeIconId(iconId);
        return;
      }
    }

    // 其他情况，则发送请求
    try {
      antdUtils.setLoading && antdUtils.setLoading(true);
      const res = await orderPaymentApi({
        ...prepareParams,
        payment_channel_id: id,
      })
      res.create_at = new Date().getTime();
      orderMap.current[id] = res;
      setOrderInfo(res);
      setQrcodeIconId(iconId);
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading && antdUtils.setLoading(false);
    }
  }

  const handleCancel = () => {
    onOpen(false);
  }

  const updateOrderStatus = async (status_id) => {
    if (isQrcodeExpired) {
      message.warning('支付二维码已过期，请刷新二维码');
      return;
    }

    try {
      antdUtils.setLoading && antdUtils.setLoading(true);
      await updateOrderStatusApi(orderInfo.order_no, status_id);
      // setStatusId(status_id);
      await dispatch(fetchUserInfo());
    } catch (e) {
      console.log(e);
    } finally {
      antdUtils.setLoading && antdUtils.setLoading(false);
    }
  }

  const goPath = (path) => {
    handleCancel();
    navigate(path);
  };

  const resetChannel = () => {
    refreshQrcode();
  }

  return (
    <Modal title="确认订单"
           open={open}
           width="800px"
           footer={null}
           onCancel={handleCancel}>
      <div className="order-prepare-modal">
        <div className="order-prepare-title">
          <div className="title-name">{ prepareInfo.product_name }</div>
          <div className="title-price">¥ <span>{ prepareInfo.amount }</span></div>
        </div>
        {
          // 无支付通道时样式
          errorType === ErrorType.emptyChannel &&
          (<div className="empty-channel gray-color">
            -- 暂无支付通道，请联系客服 --
          </div>)
        }
        {
          // 支付成功时样式
          !errorType && statusId === OrderStatusType.paid &&
          (<div className="success-order">
            <div className="success-order-info">
              <CheckCircleFilled className="success-order-icon success-color" />
              <span className="success-order-text success-color">支付成功</span>
            </div>
            <div className="success-order-no">
              订单号：{ orderInfo.order_no }
            </div>
            <div className="success-order-btns">
              <Button className="success-order-btn" onClick={() => goPath(RouterPath.payment)}>查看订单</Button>
              <Button className="success-order-btn" type="primary" onClick={() => goPath(RouterPath.chat)}>返回聊天</Button>
            </div>
          </div>)
        }
        {
          // 支付错误时样式
          !errorType && statusId === OrderStatusType.failed &&
          (<div className="success-order">
            <div className="success-order-info">
              <CloseCircleFilled className="success-order-icon error-color" />
              <span className="success-order-text error-color">支付失败</span>
            </div>
            <div className="success-order-btns">
              {/*<Button className="success-order-btn" onClick={() => goPath(RouterPath.payment)}>返回</Button>*/}
              <Button className="success-order-btn" type="primary" onClick={() => resetChannel()}>重新选择通道</Button>
            </div>
          </div>)
        }
        {
          // 未支付时样式
          !errorType && (!statusId || statusId === OrderStatusType.unpaid) && !!prepareInfo && !!prepareParams && (<div>
            <div className="order-prepare-des">
              {
                // 同一个套餐时
                prepareParams.product_type_id === productType.plan && plan.id === prepareParams.plan_id &&
                (
                  <div className="des-item">
                    <div>
                      您当前是 <span className="primary-color large-font">{ plan.name }</span> 套餐，剩余有效期 <span className="error-color large-font">{ prepareInfo.left_days }</span> 天
                    </div>
                    <div>
                      续期后累计有效期 ≈ <span className="success-color large-font">{ prepareInfo.total_days }</span> 天，预计到期时间：<span className="primary-color large-font">{ prepareInfo.plan_end_at }</span>
                    </div>
                  </div>
                )
              }

              {
                // 不同的套餐
                prepareParams.product_type_id === productType.plan && plan.id !== prepareParams.plan_id &&
                (
                  <div className="des-item">
                    <div>
                      您当前是 <span className="primary-color large-font">{ plan.name }</span> 套餐，剩余有效期 <span className="error-color large-font">{ prepareInfo.left_days }</span> 天，
                      变更后将自动折算为新套餐有效期 ≈ <span className="primary-color large-font">{ prepareInfo.old_days }</span> 天
                      <Popover content="折算有效期 = 变更前会员的剩余有效期 ×（变更前原价 ÷ 变更后原价），折算天数向上取整。" title="">
                        <QuestionCircleOutlined className="question-icon"/>
                      </Popover>
                    </div>
                    <div>
                      购买后累计有效期 ≈ <span className="success-color large-font">{ prepareInfo.total_days }</span> 天，
                      预计到期时间：<span className="primary-color large-font">{ prepareInfo.plan_end_at }</span>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="order-prepare-pay-type">
              <Tabs activeKey={payType} items={tabItems} centered onChange={changePayType} />
            </div>
            <div className="tips">-- 请选择通道 --</div>
            <div className="order-prepare-pay-channels">
              {
                channels.map(item => {
                  return (
                    <Button key={item.id}
                            className="channel-item"
                            type={activeChannelId === item.id ? 'primary' : 'default'}
                            onClick={() => selectChannel(item.id, payType)}>{item.name}</Button>)
                })
              }
            </div>
            <div className="channel-qrcode" style={{ display: (!orderInfo || (orderInfo && isQrcodeExpired)) ? 'flex' : 'none' }}>
              <div className="empty-box">
                <QRCode
                  size={200}
                  value="-"
                  status="scanned"
                />
                <span className="empty-text">{ (orderInfo && isQrcodeExpired) ? '二维码已过期' : '未选择通道' }</span>
              </div>
            </div>
            <div className="channel-qrcode" style={{ display: (orderInfo && !isQrcodeExpired) ? 'flex' : 'none' }}>
              {
                qrcodeExpiredCount > 0 && (<div className="qrcode-count-down">
                  支付倒计时: <span className="success-color">{ formatTime }</span>
                </div>)
              }
              <QRCode
                size={200}
                value={(orderInfo && orderInfo.url) || '-'}
                icon={payTypeImgMap[qrcodeIconId]}
              />
            </div>
            <div className="channel-qrcode-tip">
              {
                activeChannelId ?
                (<Button type="link" size="small" icon={<RedoOutlined />} onClick={refreshQrcode}>刷新</Button>) :
                (<div style={{ height: '24px' }}></div>)
              }
              <div className="tip warning-color">（ 注：若支付失败请尝试刷新二维码，如果始终无法支付请联系客服 ）</div>
            </div>
            {
              orderInfo && (<div className="show-order-no">
                订单号：{ orderInfo.order_no }
              </div>)
            }
            {
              process.env.REACT_APP_ENV === 'dev' && (<div className="dev-action">
                <Button className="dev-btn" type="primary" size="small" onClick={() => updateOrderStatus('paid')}>设为支付成功</Button>
                <Button className="dev-btn" danger size="small" onClick={() => updateOrderStatus('failed')}>设为支付失败</Button>
              </div>)
            }
          </div>)
        }
      </div>
    </Modal>
  )
}
