import { theme } from "antd";

export default {
  token: {
    // colorPrimary: '#3693B3',
    colorPrimary: '#7580cc',
    // colorSuccess: '#52c41a', // 成功色
    // colorWarning: '#faad14', // 警戒色
    // colorError: '#ff4d4f', // 错误色
    // colorInfo: '#1677ff', // 信息色
    // colorLink: '#1677ff', // 链接色
    // borderRadius: 6, // 基础组件的圆角大小，例如按钮、输入框、卡片等
    // controlHeight: 32, // 按钮和输入框等基础控件的高度
    // fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`, // 按钮和输入框等基础控件的高度
    // fontSize: 14, // 文本梯度也将基于该字号进行派生
    // motion: false, // 配置动画效果，为 `false` 时则关闭动画
    // zIndexBase: 0, // 所有组件的基础 Z 轴值，用于一些悬浮类的组件的可以基于该值 Z 轴控制层级，例如 BackTop、 Affix 等
    // zIndexPopupBase: 1000, // 浮层类组件的基础 Z 轴值，用于一些悬浮类的组件的可以基于该值 Z 轴控制层级，例如 FloatButton、 Affix、Modal 等
  },
  components: {
    // 修改单个组件的主色而不影响其他的UI组件
    Button: {
      // colorPrimary: "#f00",
      colorLink: "#7580cc",
      // colorLink: "#010936",
      colorLinkHover: '#a0a8d5',
    },
  },
  // 1. 单独使用算法（默认提供三套预设算法）
  algorithm: theme.darkAlgorithm, // 暗黑算法
  // algorithm: theme.defaultAlgorithm, // 默认算法
  // algorithm: theme.compactAlgorithm, // 紧凑算法
  // 2. 组合使用暗色算法与紧凑算法
  // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
}
