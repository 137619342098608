import { Modal, Button } from 'antd';
import globalConfig from '@/config';

export default function(props) {
  const { open, onOpen } = props;

  const handleCancel = () => {
    onOpen(false);
  }

  return (
    <Modal title="用户协议"
           width="60vw"
           open={open}
           style={{ top: '5vh', minWidth: '620px' }}
           styles={{ body: { height: '80vh' }}}
           footer={null}
           onCancel={handleCancel}>
      <div className="large-modal-inner">
        <div className="modal-content">
          <div className="_markdown-body">
            <div className="paragraph">更新时间：2024年2月23日</div>
            <div className="paragraph"><strong>1. 导言</strong></div>
            <div className="paragraph">欢迎你使用“{ globalConfig.siteTitle }”软件及相关服务！</div>
            <div className="paragraph"
                 >“{ globalConfig.siteTitle }”软件及相关服务，系指{ globalConfig.developmentCompany }及/或关联方（下称“公司”）以网页、应用程序（可能含不同版本）、小程序、应用程序编程接口（API）以及随技术发展出现的创新形态方式向你提供的产品与服务，包括但不限于以“AI交互”为核心功能以及其他功能的平台。本用户协议是你与公司之间就注册、登录、使用（以下统称“使用”）本软件及相关服务所订立的协议（以下简称“本协议”）。
            </div>
            <div className="paragraph"
                 >为了更好地为你提供服务，请你在开始使用本软件及相关服务之前，认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可的条款、法律适用和争议解决条款等。<strong>其中，免除或者限制责任条款等重要内容将以加粗形式提示你注意，你应重点阅读。</strong>
            </div>
            <div className="paragraph" >如你不同意本协议，这将导致我们无法为你提供完整的产品和服务，你也可以选择停止使用。<strong>如你自主选择同意或使用本软件及相关服务，则视为你已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与本软件及相关服务相关的协议和规则（包括但不限于《隐私协议》）的约束。</strong>
            </div>
            <div className="paragraph" >如对本协议内容有任何疑问、意见或建议，你可通过电子邮箱<a
              href={'mailto:' + globalConfig.feedbackEmail}>{globalConfig.feedbackEmail}</a>与我们反馈。
            </div>
            <div className="paragraph" >
              <strong>特别提示：本软件及相关服务依托于生成式人工智能模型，并可能同时依托于其他模型、服务，以向你提供人工智能生成技术服务及其他服务，可能出现错误或遗漏，仅供你参考，你不应将输出的内容作为专业建议、商业用途或用于其他目的。若涉及对你或者相关方可能会产生重大影响的情形（包括但不限于医疗、财务、投资、保险、信贷、教育、就业、住房、法律事务、医疗决定或其他重大决定），你必须咨询相关领域专业人士。本软件的输出不应成为你进一步作为或不作为的依据。你根据输出的内容所作出的任何判断或者据此作出的后续相关操作行为，所带来的后果和责任均由你自行承担，包括因对输出的内容的真实性、准确性、可靠性、不侵权或满足特定目的的依赖而产生的风险。你应科学理性认识和依法使用生成式人工智能技术。总之，本服务提供的内容，应仅供您参考，不构成任何建议或承诺，我们不承担您因使用本服务给您或任何第三方产生的任何损害或责任。</strong>
            </div>
            <div className="paragraph" ><strong>2. 接受本协议条款</strong></div>
            <div className="paragraph"
                 >通过访问或使用本服务，即表示你确认你具备与{ globalConfig.developmentCompany }签订具有法律约束力合同的权利，且你同意并遵守本协议。
            </div>
            <div className="paragraph"
                 >如果你代表企业或其他实体自主选择同意本协议或使用本服务，则
            </div>
            <div className="paragraph" >(1) “你”包括你和该企业或实体；</div>
            <div className="paragraph" >(2)
              你声明并保证你是该企业或实体的授权代表，有权约束该企业或实体遵守这些条款，并且你代表该实体同意这些条款；
            </div>
            <div className="paragraph" >(3)
              你的企业或实体对你自主选择同意本协议或使用本服务以及与你的实体相关的其他人（包括但不限于任何员工、代理或承包商）访问或使用你的账户负有法律和财务责任。你可以通过自主选择同意本协议或使用本服务接受本协议。你理解并同意以后，我们将把你对服务的访问或使用视为对本协议的接受。
            </div>
            <div className="paragraph" ><strong>3.本软件及相关服务</strong></div>
            <div className="paragraph"
                 >3.1本软件及相关服务的<strong>主要适用人群是年满18周岁的成年人</strong>，
              如你未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本软件及相关服务。
            </div>
            <div className="paragraph"
                 >3.2为更好的提升体验及服务、或为安全保障，我们将不定期对本服务的部分或全部进行更新或改变（包括但不限于软件/服务修改、升级、替换、功能强化、开发新服务等）。我们将采取商业上合理的努力，如通过平台通知等方式，向你通知对本协议条款的任何重大变更。但你仍需定期查看这些条款以了解此类变更。我们还将更新条款顶部的“最后更新”日期，以反映条款的生效日期。在新条款生效日期之后，你继续访问或使用本服务即表示你接受新条款。如果你不同意新条款，则你必须停止访问或使用本服务。
            </div>
            <div className="paragraph"
                 >3.3你可以随时停止使用本服务，尽管我们对此表示遗憾。我们也可能随时停止向你提供服务，或随时对本服务增加或设置新的限制。
            </div>
            <div className="paragraph"
                 >3.4你理解并同意，使用本服务需自行准备有关的终端设备（如电脑、手机等），一旦你在终端设备中访问本服务，即视为你使用本服务。为充分实现本服务的全部功能，你可能需要将终端设备联网，你理解你应承担所需要的费用（如流量费、上网费等）。
            </div>
            <div className="paragraph"
                 >3.5除非得到公司事先明示书面授权，你不得以任何形式对本软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
            </div>
            <div className="paragraph"
                 >3.6<strong>公司非常重视人工智能模型生成内容的质量，将努力采取合理的安全措施过滤不当内容。如果你发现本软件及相关服务输出的内容或返回的处理结果不合理、有瑕疵或存在任何侵犯你权力的内容，你随时通过本软件及相关服务的反馈入口或电子邮箱 <a
              href={'mailto:' + globalConfig.feedbackEmail}>{globalConfig.feedbackEmail}</a>进行反馈。</strong></div>
            <div className="paragraph" >
              <strong>如你发布的内容被相关权利人投诉侵权并被处理，且你不认可处理结果，你应根据公司提示向公司提交不侵权声明及相关权利证明材料进行申诉，公司同样将会依据相关法律规定及时处理你的申诉。</strong>
            </div>
            <div className="paragraph" ><strong>4.你的账号</strong></div>
            <div className="paragraph"
                 >4.1本服务为你提供了注册通道，你可以通过中华人民共和国境内的移动电话号码或我们允许的其他方式注册{ globalConfig.siteTitle }账号。此外，你也可以选择使用我们提供的第三方平台账号授权登录功能，通过你合法拥有的第三方平台的用户账号，注册并登录{ globalConfig.siteTitle }的账号，但第三方平台对此有限制或禁止的情况除外。你应保障所使用的第三方平台的用户账号已完成真实身份信息认证，并符合相关法律法规要求。你设置的账号、密码、收到的验证码，是你用以登录并以注册你的用户身份使用本软件及相关服务的凭证。
            </div>
            <div className="paragraph"
                 >4.2在需要终止使用账号服务时，符合以下条件的，你可以申请注销你的账号：
            </div>
            <div className="paragraph"
                 >（1）你仅能申请注销你本人的账号，并依照“{ globalConfig.siteTitle }”的流程进行注销；
            </div>
            <div className="paragraph"
                 >（2）你仍应对你在注销账号前且使用“{ globalConfig.siteTitle }”服务期间的行为承担相应责任；
            </div>
            <div className="paragraph"
                 >（3）注销成功后，将无法使用我们的任何服务，账号记录、功能等将无法恢复或提供，会员也会被取消。
            </div>
            <div className="paragraph"
                 >如你需要注销你的“{ globalConfig.siteTitle }”账号，请点击用户头像【个人中心】中的账号注销处，按提示进行注销。
            </div>
            <div className="paragraph"
                 >4.3<strong>你理解并承诺，你所设置的账号不得违反国家法律法规及公司的相关规则，你的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像或其他足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。公司有权对你提交的信息进行审核。你在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。</strong>
            </div>
            <div className="paragraph"
                 >4.4<strong>你在“{ globalConfig.siteTitle }”注册的的账号仅限于你本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，公司有权立即暂停或终止向该注册账号提供服务，并有权永久禁用该账号。</strong>
            </div>
            <div className="paragraph"
                 >4.5你有责任维护个人账号、密码、验证码的安全性与保密性，并对你以注册账号名义所从事的活动承担全部法律责任，包括但不限于你在本服务上进行的任何数据修改、言论发表、款项支付等操作行为可能引起的一切法律责任。你应高度重视对账号、密码、验证码的保密，在任何情况下不向他人透露。若发现他人未经许可使用你的账号或发生其他任何安全漏洞问题时，你应当立即通知我们。
            </div>
            <div className="paragraph"
                 >4.6如你在丢失账号、遗忘密码或泄露验证码时，你可向我们及时申诉请求找回。你理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。公司特别提醒你应妥善保管你的账号、密码，及验证码。当你使用完毕后，应安全退出。如因你保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失、验证码被泄露，你将自行承担相应责任。
            </div>
            <div className="paragraph"
                 >4.7<strong>在注册、使用和管理账号时，你应保证注册账号时填写的身份信息的真实性，请你在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括你的姓名及电子邮件地址、联系电话、联系地址等）。依照国家相关法律法规的规定，为使用本服务的部分功能，你需要填写真实的身份信息，请你按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若你提交的材料或提供的信息不准确、不真实、不规范或公司有理由怀疑为错误、不实、不合法的资料，则公司有权拒绝为你提供相关功能，你可能无法使用本软件及相关服务或在使用过程中部分功能受到限制。</strong>
            </div>
            <div className="paragraph"
                 >4.8当你完成本软件的账号注册、登录并进行合理和必要的身份验证后，你可随时浏览、修改自己提交的个人身份信息。你理解并同意，出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，你可能无法修改注册时提供的初始注册信息及其他验证信息。
            </div>
            <div className="paragraph"
                 >4.9你理解并同意，为了充分使用账号资源，如你在注册后未及时进行初次登录使用或连续超过六个月未登录账号使用等情形，我们有权收回你的账号。如你的账号被收回，你可能无法通过你此前持有的账号登录并使用本服务，你该账号下保存的任何个性化设置和使用记录将无法恢复。在收回你的账号之前，公司将以适当的方式向你作出提示，如你在收到相关提示后六个月内仍未登录、使用账号，公司将进行账号收回。
            </div>
            <div className="paragraph"
                 >如你的账号被收回，你可以通过注册新的账号登录、使用本服务。你注册新账号并登录、使用的行为仍受到本协议相关条款的约束。
            </div>
            <div className="paragraph"
                 >4.10<strong>如果我们认为，你未能遵守本协议的任何规定，或可能会对本服务造成损害，或侵犯任何第三方的权利，或违反任何适用的法律或法规，我们保留随时禁用你的账号的权利。</strong>
            </div>
            <div className="paragraph" ><strong>5.用户个人信息保护</strong></div>
            <div className="paragraph"
                 >公司与你一同致力于你个人信息（即能够独立或与其他信息结合后识别你身份的信息）的保护，保护用户个人信息是公司的基本原则之一。在使用本软件及相关服务的过程中，你可能需要提供你的个人信息，以便公司向你提供更好的服务和相应的技术支持。公司将依法保护你浏览、查阅、复制、更正、修改、补充、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与本软件及相关服务相匹配的技术措施及其他安全措施保护你的个人信息，更多关于用户个人信息保护的内容，<strong>请参见《隐私政策》</strong>。
            </div>
            <div className="paragraph" ><strong>6.使用本服务共识</strong></div>
            <div className="paragraph"
                 >6.1本服务依托于生成式人工智能模型，并可能同时依托于其他模型、服务，以向你提供人工智能生成技术服务及其他技术服务。
            </div>
            <div className="paragraph"
                 >人工智能和机器学习是动态的且不断发展的。你同意，你已认识到人工智能生成的输出具有固有的局限性。我们致力于不断改进我们的服务，提高其准确性、可靠性、安全性和整体价值。然而，由于机器学习算法固有的概率性质，我们的服务可能偶尔会生成不准确的信息。<br />
              通过使用我们的服务，你承认并同意以下内容：</div>
            <div className="paragraph"
                 >（1）对于你将人工智能模型的输出用于可能对你或者你的最终用户产生重大影响的场景，包括但不限于医疗、财务、投资、保险、信贷、教育、就业、住房、法律事务、医疗决定或其他重大决定，建议你咨询相关专业人士。
            </div>
            <div className="paragraph"
                 >（2）你有责任根据你的具体需求来评估人工智能模型输出的准确性和适当性，包括在应用或传播本服务的输出之前，你视情况进行人工审查。
            </div>
            <div className="paragraph"
                 >（3）人工智能模型的输出不应成为你和你的最终用户进一步作为或不作为的依据。你根据输出的内容所作出的任何判断，或者据此作出的后续相关行为，所带来的后果和责任均由你自行承担，包括因对输出的内容的真实性、准确性、可靠性、不侵权或满足特定目的的依赖而产生的风险。你应科学理性认识和依法使用生成式人工智能技术。
            </div>
            <div className="paragraph"
                 >（4）本服务可能偶尔会生成不完整、不正确或具有潜在冒犯性的输出，这不应该被解释为反映我们的观点或立场。此外，输出中对第三方产品或服务的任何引用并不意味着我们的认可或与我们有关联。
            </div>
            <div className="paragraph" ><strong>7.用户行为规范</strong></div>
            <div className="paragraph"
                 >在提供高质量的服务同时，不断加强相应的信息安全管理能力，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。
            </div>
            <div className="paragraph"
                 >7.1<strong>你使用本服务，创建、使用应用，创建、使用API和模型，你输入、输出/生成、制作、评论、上传、发布、传播的信息及软件等，均应自觉遵守法律、行政法规，遵守公共秩序，尊重社会公德和伦理道德、社会主义核心价值观、国家利益、尊重他人合法权益、道德风尚和信息真实性等“七条底线”要求，否则公司有权立即采取相应处理措施。你不得自行或允许、协助任何人利用本服务从事下述行为：</strong>
            </div>
            <div className="paragraph" >（1）反对宪法确定的基本原则的；<br />
              （2）危害国家安全和利益，泄露国家秘密的；<br />
              （3）颠覆国家政权，推翻社会主义制度、损害国家形象、煽动分裂国家、破坏国家统一和社会稳定的；<br />
              （4）损害国家荣誉和利益的；<br />
              （5）宣扬恐怖主义、极端主义的；<br />
              （6）宣扬民族仇恨、破坏民族团结的；<br />
              （7）宣扬歧视、仇恨，利用特定群体基于其年龄、社会、身体或精神特征的任何脆弱性，以实质性扭曲属于该群体的人的行为，造成或可能造成该人或另一人的身体或心理伤害，包括但不限于民族歧视、种族歧视、宗教歧视、国籍歧视、性别歧视、性取向歧视、年龄歧视、职业歧视、健康歧视、地域歧视;<br />
              （8）从事或协助非法或高风险活动，例如： 军事和战争、发展武器、爆炸物或危险材料、
              关键基础设施（如运输、能源）的管理或运营、创建或分销管控物质或服务、生成或传播与自残有关的信息、具有高经济损害风险的活动，含赌博、自动确定信贷资格、就业和教育决策、做出高风险的政府决策（例如，执法）；<br />
              （9）破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
              （10）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；例如，
              利用或针对本服务从事诈骗、欺诈、误导或欺骗性活动，包括但不限于假冒或将AI生成的答案表示为人工生成、剽窃或学术不诚实；<br />
              （11）散布、传播、宣扬暴力、淫秽、色情、露骨、赌博、凶杀、恐怖或者教唆犯罪的；<br />
              （12）利用、伤害或企图利用或伤害未成人、损害未成年人身心健康、侵害未成年人合法权益的；<br />
              （13）侮辱、诽谤、冒犯、骚扰、辱骂、激怒他人、欺凌或控制他人、恐吓、威胁、伤害、伤害、惊吓、困扰、尴尬或使他人不安；<br />
              （14）对他人进行暴力恐吓、威胁，实施人肉搜索的；<br />
              （15）涉及他人隐私、个人信息或资料的，例如，非法收集或披露个人身份信息或教育、财务或其他受保护的记录，例如地址、电话号码、电子邮件地址、个人身份证明文件中的号码和特征（例如，社会保险号码、护照号码、身份证号码）或信用卡号码；<br />
              （16）散布污言秽语，损害社会公序良俗的；<br />
              （17）违反商业道德，侵害商业秘密、侵犯他人隐私权、名誉权、荣誉权、肖像权、知识产权、贸易管制等合法权益内容的；<br />
              （18）散布广告，或类似的商业招揽信息、过度营销信息及垃圾信息、促销材料，以及其他未经授权或未经请求的消息；<br />
              （19）处理或使用敏感数据；<br />
              （20）与所评论的信息毫无关系的；<br />
              （21）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；<br />
              （22）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；<br />
              （23）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的，包括但不限于以下情形：<br />
              i. 任何暴力和/或自残行为内容；<br />
              ii. 任何威胁生命健康、利用刀具等危险器械表演的危及自身或他人人身及/或财产权利的内容；<br />
              iii.怂恿、诱导他人参与可能会造成人身伤害或导致死亡的危险或违法活动的内容；<br />
              （24）在未经专业人员审查的情况下提供针对性的专业建议：包括法律、财务、医疗/健康、投资、保险、信贷、教育、就业、住房等专业建议；<br />
              （25）以可能产生利益冲突或破坏本服务目的的方式使用本服务；<br />
              （26）开发、服务与公司和/或关联公司有竞争可能性的应用程序、产品、服务或模型；<br />
              （27）其他违反法律法规、政策及公序良俗、干扰本软件及相关服务正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
            </div>
            <div className="paragraph"
                 >7.2你不得自行或允许、协助任何人使用插件、外挂、系统或第三方工具干扰、破坏、修改本服务的正常运行或施加其他影响，包括但不限于使用任何自动化程序、软件或类似工具接入本服务，收集或处理其中信息、内容；
            </div>
            <div className="paragraph"
                 >7.3你不得自行或允许、协助任何人进行任何危害本服务系统安全的行为，亦不得利用本服务从事任何危害计算机网络安全的行为。该等行为包括但不限于：
            </div>
            <div className="paragraph" >（1）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；<br />
              （2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；<br />
              （3）明知他人正在从事危害网络安全的活动，为其提供技术支持、广告推广、支付结算等帮助；<br />
              （4）使用未经许可的数据或进入未经许可的服务器/账号；<br />
              （5）未经允许进入公众计算机网络或他人计算机系统并删除、修改、增加存储信息；<br />
              （6）未经许可，企图探查、扫描、测试本服务的系统或网络的弱点，或实施其他破坏网络安全的行为；<br />
              （7）
              企图干涉、破坏本服务系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为。<br />
              （8）伪造TCP/IP数据包名称或部分名称；<br />
              （9）对本服务进行逆向工程、拆卸、反向汇编、反编译、编译，或以其他方式试图发现本服务或第三方服务的源代码、系统的源代码或底层组件、底层技术、算法、模型；<br />
              （10）恶意注册本服务的账号，包括但不限于频繁、批量注册账号；<br />
              （11）出租、租赁、分发、许可、转让或出售、改编、复制、传播、垂直搜索、镜像或交易等访问或使用本服务或其任何部分；<br />
              （12）未经授权冒充他人或获取对本软件及相关服务的访问权；<br />
              （13）假冒或试图假冒我们、其他用户或任何其他个人（在世或已故）或实体。<br />
              （13）提供包含病毒、木马、蠕虫、逻辑炸弹或其他恶意或有害材料，或使用你创建的应用生成此类恶意或有害材料；<br />
              （14）以自动化的方式使用本服务或通过本服务访问第三方服务，尤其是可能对我们的基础设施造成不合理或不成比例负载的行动、或超出我们的任何速率限制；<br />
              （15）使用任何“深度链接”、“页面抓取”、“AI应用”、“蜘蛛”或其他自动设备、程序、算法或方法，或任何手动过程来访问、获取、复制或监控本服务的任何部分，或通过任何手段获取或试图获取任何材料、文件或信息的行为；<br />
              （16）通过黑客、密码“挖掘”或任何其他非法手段获取或试图获取对本服务的未经授权的访问,或绕过我们可能用于防止或限制访问本服务的任何措施，或破坏本服务的任何部分，或造成任何部分的损坏。<br />
              （17）干扰或试图干扰与本服务连接的任何网络、服务器、计算机或数据库，或违反其任何条款、政策或程序。</div>
            <div className="paragraph" >7.4未经授权冒充他人或获取对本软件及相关服务的访问权。</div>
            <div className="paragraph"
                 >7.5利用或针对本软件及相关服务从事诈骗、欺诈、误导或欺骗性活动，包括但不限于假冒或将智能体生成的答案表示为人工生成、剽窃或学术不诚实、或网络钓鱼，和或从事其他任何违法违规活动。
            </div>
            <div className="paragraph"
                 >7.6以可能侵犯任何第三方隐私的任何方式使用本软件及相关服务，例如非法收集或披露个人身份信息或教育、财务或其他受保护的记录，例如地址、电话号码、电子邮件地址、个人身份证件中的号码和特征（例如身份证号、社保账号、护照号码）或信用卡号码。
            </div>
            <div className="paragraph"
                 >7.7<strong>你对本服务的访问和使用，在任何时候都必须符合所有法律法规的要求。如果我们认为你违反本协议或违反任何法律法规，我们可独立进行判断并有权不事先通知你，自行采取处置措施，处置措施包括但不限于警示、限制功能、暂停或终止向你提供服务，并追究你的相关法律责任。我们同时也不承担任何责任。</strong>
            </div>
            <div className="paragraph" >7.8<strong>你不得利用基于深度学习等新技术新应用制作、发布、传播虚假信息。你在发布或传播利用基于深度学习、虚拟现实等新技术新应用生成的信息时，
              应当以显著方式予以标识或提示。你不应删除或者以令人误导的方式修改本软件基于深度学习、虚拟现实等新技术新应用添加的标识。</strong>
            </div>
            <div className="paragraph" ><strong>8.知识产权</strong></div>
            <div className="paragraph"
                 >8.1在你使用本软件及相关服务期间，你可以向本软件及相关服务提交的内容或信息（合称“输入”，包括但不限于模型、文字、图片、音频、视频等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），并接收响应你的输入而生成的内容（称“输出”、“生成”）。**你理解并承诺，输入均应为你享有知识产权或已获取权利人合法授权的内容，不存在任何违反适用的法律法规、侵犯他人合法权益（包括但不限于著作权、专利权、商标权等知识产权及人格权、个人信息权益等其他权益）、违反公序良俗的内容。**您使用“{ globalConfig.siteTitle }”生成的内容所有权归属于您所有。
            </div>
            <div className="paragraph"
                 >8.2<strong>你理解并同意，对于你通过本软件及相关服务输入、上传、发布、传播的内容之全部或部分（合称信息内容），你授予公司和/或关联方使用此等信息内容，用于公司和/或公司品牌有关的宣传、推广、相关研究。</strong>
            </div>
            <div className="paragraph"
                 >8.3公司为本软件开发、运营提供技术支持，并对本软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。
            </div>
            <div className="paragraph"
                 >8.4您对您在{ globalConfig.siteTitle }平台上发布或以其他方式提供的信息内容拥有所有权、控制权和责任。您的信息内容不得违反任何本协议的内容、适用法律和法规，也不得侵犯或盗用任何个人或实体的任何权利。如果我们对您的内容违反以上协议内容有疑虑，我们可以随时自行决定删除您的内容；如您对处理结果有异议的，可与我们联系并提供材料协商解决。
            </div>
            <div className="paragraph"
                 >8.5<strong>如果您将您的信息内容设置为公开，对于您上传到{ globalConfig.siteTitle }平台公共部分的任何文件或内容（包括但不限于模型、图片、提示词等），您明确授予我们及我们的关联实体永久的、非排他性的、可转授权、再许可的、免费的、免版税的、不可撤销的全球许可，以在互联网当前或未来的媒体中以任何形式使用、展示、下载和/或分发上述文件或内容及创建的衍生作品，或根据您的指示产生的资产。上述许可用于与{ globalConfig.siteTitle }平台和公司其他相关业务平台，包括但不限于与修改、改进和提高人工智能模型相关，以及以任何媒体格式和通过任何媒体渠道推广和重新分发的部分或全部服务（及其衍生作品）。</strong>
            </div>
            <div className="paragraph"
                 >如果您在{ globalConfig.siteTitle }平台的公共部分删除任何上述文件或内容，您根据前述内容授予{ globalConfig.siteTitle }平台及公司的许可将自动终止，但对于{ globalConfig.siteTitle }平台及公司已经复制和再许可或指定再许可产生的衍生作品或相关的任何文件或内容，不会被撤销或被视为非法。本许可在任何一方出于任何原因终止本协议后仍然有效。
            </div>
            <div className="paragraph" >如果您将您的内容设置为私人内容，我们将使用合理和适当的措施，以对您的内容进行保密并防止未经授权的访问或披露。
              此外，当内容包含合理且惯常的许可声明（例如开源许可）时，此类内容在进一步访问、分发或使用时应遵守该许可的条款，任何一方均不得删除对任何此类许可的引用。
            </div>
            <div className="paragraph" >8.6如你发现我们的平台里存在任何侵犯你权利的内容，可通过电子邮件<a
              href={'mailto:' + globalConfig.feedbackEmail}>{globalConfig.feedbackEmail}</a>与我们书面联系**。**<br />
              你的书面通知应包括以下信息：</div>
            <div className="paragraph" >（1）权利人主体信息和相关证明材料；</div>
            <div className="paragraph"
                 >（2）侵权材料的描述以及其在平台上的位置，以便我们足以定位该被投诉的内容；
            </div>
            <div className="paragraph" >（3）被投诉内容所侵犯的权利类型；</div>
            <div className="paragraph" >（4）构成侵权的初步证明材料；</div>
            <div className="paragraph"
                 >（5）你作为权利人或其代理人的地址、电话号码和电子邮件地址和证明材料；
            </div>
            <div className="paragraph"
                 >（6）你应当保证投诉中的陈述和所提供材料的真实性、有效性和合法性，保证承担因投诉而处理或处罚被投诉内容给被投诉方以及本服务造成的全部损失及由此产生的全部法律责任。
            </div>
            <div className="paragraph" ><strong>9.第三方服务</strong></div>
            <div className="paragraph"
                 >本服务可能包括第三方服务，包括但不限于第三方API。你承认并同意第三方服务由该第三方服务提供商向你提供。你应遵守第三方发布或提供的协议和其他条款条件（例如，用户协议、隐私政策等）。你确认并同意，你与任何第三方因使用本服务而产生的争议是你与该第三方之间的直接争议，你无条件地向提示语科技（北京）有限公司方面放弃对任何和所有此类争议的索赔、要求、罚款、赔偿和损害赔偿（实际和间接）的权利，无论其性质和种类是已知还是未知，且无论与此类争议有何联系。
            </div>
            <div className="paragraph"
                 >你承认并同意，你使用第三方服务将受到该等第三方的协议的约束。你输入的信息将根据这些第三方协议的规定进行处理。（例如，如果你的输入非法或包含不适当内容，某些第三方服务可能拒绝为你生成输出）
            </div>
            <div className="paragraph" ><strong>10.服务费用</strong></div>
            <div className="paragraph" >10.1本软件部分服务以免费方式提供。</div>
            <div className="paragraph"
                 >10.2本软件部分服务为积分消耗机制（积分单位为“算力”），用户可以通过任务免费领取积分和付费充值等方式获取积分。具体积分使用规则和支付方式等以相关页面详细内容为准。
            </div>
            <div className="paragraph"
                 >10.3如果由于本公司的过失导致您无法使用服务，您可以在首次购买服务后的24小时内申请取消服务并退还服务费。如果不符合上述要求，您的退款申请将根据实际情况考虑，最终决定权由本公司全权决定。本公司仅有义务通过原支付渠道进行退款，退款申请将在申请后30天内处理。退款相关事宜可以通过电子邮件<a
              href={'mailto:' + globalConfig.feedbackEmail}>{globalConfig.feedbackEmail}</a>联系我们。
            </div>
            <div className="paragraph"
                 >10.4根据实际需要对收费服务的规则进行修改，将在生效前于本平台相应服务页面进行通知或公告，已购买的服务不受影响。如果您不同意上述修改，则应停止使用该服务。
            </div>
            <div className="paragraph" ><strong>11.违约处理</strong></div>
            <div className="paragraph"
                 >11.1<strong>如果你违反本协议约定，我们有权视情况采取预先警示、拒绝发布、立即停止传输信息、删除你的内容、短期禁止你发布你的内容、限制账号部分或者全部功能，直至终止提供服务、永久关闭账号及法律法规规定的其他处置措施，对于因此造成平台功能不可用、账号信息删除、内容删除、无法正常获取你账号内资产或其他权益等不利后果，应由你自行承担。对于已删除的你的内容，我们有权不予恢复。</strong>
            </div>
            <div className="paragraph"
                 >11.2如果你采取不正当或作弊等手段参与我们举办的有奖活动，我们有权限制或取消你参与活动的资格，取消或撤回奖励。如你已领取并使用奖励，我们有权要求你退回奖品或同等价值的现金。
            </div>
            <div className="paragraph"
                 >11.3我们有权公告处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违法犯罪的行为，我们将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查。
            </div>
            <div className="paragraph" >11.4
              如果你侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益，引起第三方投诉、举报或以诉讼等方式维权索赔的，我们有权根据法律法规要求采取必要措施。针对第三方提出的全部权利主张，你应自行处理并承担全部可能由此引起的法律责任。因你的行为导致我们及或关联公司向任何第三方承担赔偿责任，或遭受国家机关处罚，或受到其他经济损失或商誉损失的，你应赔偿我们及或关联公司因此遭受的全部损失。
            </div>
            <div className="paragraph" ><strong>12.服务的变更、中断和终止</strong></div>
            <div className="paragraph"
                 >你理解并同意，我们提供的本服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向你提供服务，确保服务的连贯性和安全性。你理解，我们不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用本软件及服务以及其他的损失和风险。
            </div>
            <div className="paragraph"
                 >你理解并同意，我们为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能设置、范围，修改、中断、中止或终止本软件及相关服务。
            </div>
            <div className="paragraph"
                 >你可以随时终止对本服务的使用。我们可以随时终止向你提供服务或终止这些条款，无论是否通知你。如果我们终止你的账户或你删除你的账户，你与我们之间的这些条款将自动终止。
            </div>
            <div className="paragraph"
                 >你授予提示语科技（北京）有限公司和或其他方的权利和许可，以及其他根据其性质在本协议终止后仍可继续生效的条款，将在本协议终止后继续有效。
            </div>
            <div className="paragraph" ><strong>13.免责声明</strong></div>
            <div className="paragraph" >
              <strong>13.1你理解并同意，本软件及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：</strong>
            </div>
            <div className="paragraph" >
              <strong>（1）本软件及相关服务完全适合用户的使用要求；</strong><br />
              <strong>（2）本软件及相关服务不受干扰，及时、安全、可靠或不出现任何错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；</strong><br />
              <strong>（3）本软件及相关服务中任何错误都将能得到更正。</strong></div>
            <div className="paragraph" >
              <strong>13.2如有涉嫌借款、投融资、理财或其他涉财产的信息、帐户密码、广告或推广等信息，请你谨慎对待并自行进行判断，对你因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</strong>
            </div>
            <div className="paragraph" >
              <strong>13.3你理解并同意，在使用本软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于自然灾害(如洪水、地震、台风等)、政府行为、战争、罢工、骚乱等。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力造成的暂停、中止、终止服务或造成的任何损失，公司在法律法规允许范围内免于承担责任。</strong>
            </div>
            <div className="paragraph" >
              <strong>13.4公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</strong>
            </div>
            <div className="paragraph" >
              <strong>13.5你理解并同意，关于本软件及相关服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。你对本软件及相关服务的使用行为应自行承担相应风险。</strong>
            </div>
            <div className="paragraph" >
              <strong>13.6你理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果你已经理解并同意自行承担。</strong>
            </div>
            <div className="paragraph" >
              <strong>13.7在任何情况下，即使公司已被告知此类损害的可能性，公司或关联公司或许可方均不对任何直接、间接、后果性、惩罚性、偶然性、附带性、特殊性、后果性或刑罚性、惩戒性的损害承担责任，包括但不限于因你使用本软件及相关服务而遭受的利润损失、商誉损失、数据损失或其他损失。除法律法规另有明确规定外，公司对你承担的全部责任，无论因何原因或何种行为方式，始终不超过你在使用本软件及相关服务期间支付给公司的费用(如有)。</strong>
            </div>
            <div className="paragraph" ><strong>14.其它</strong></div>
            <div className="paragraph" >
              <strong>14.1法律适用和争议解决。本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款应在不违反法律的前提下尽可能按照接近本协议原条文目的之原则进行解释和使用，且本协议其它规定仍应具有完整的效力及效果。</strong>
            </div>
            <div className="paragraph" >
              <strong>本协议的签订地为中华人民共和国北京市朝阳区。若你与公司发生任何争议，双方应尽量友好协商解决，如协商不成，你同意应将争议提交至本协议签订地的人民法院诉讼解决。</strong>
            </div>
            <div className="paragraph" >14.2
              开源。本服务包括根据开源许可条款或其他许可条款提供的软件。许可条款中可能有一些条款明确覆盖了本协议的部分内容。
            </div>
            <div className="paragraph" ><strong>14.3
              完整协议。本协议及其他我们已经发布、后续可能发布的、不时修改的与我们有关的公约、条款、政策、指南和文件等，构成你和我们之间关于你使用本服务的完整协议，并取代你和我们之间的任何先前协议。本协议对你、我们和你的继任者、继承人和受让人具有约束力。</strong>
            </div>
            <div className="paragraph" >14.4
              转让。除非我们可以将这些条款或我们在此项下的权利或义务转让给我们的关联公司或与合并、收购或出售我们全部或实质性全部资产有关的事项，否则任何一方都不得转让或转让本协议。
            </div>
            <div className="paragraph" >14.5
              我们有权依本软件及相关服务或运营的需要单方决定，安排或指定其关联方、控制公司、继承公司或我们认可的第三方公司继续运营本软件。并且，就本协议项下涉及的某些服务，可能会由公司的关联方、控制公司、继承公司或我们认可的第三方公司向你提供。你知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
            </div>
            <div className="paragraph" >14.6.
              不放弃权利。我们未坚持或执行这些条款的任何规定不得被视为对任何规定或权利的放弃。
            </div>
            <div className="paragraph" >14.7
              安全性。我们不保证我们的服务的安全性，也不保证没有错误或病毒。你必须采取合理和适当的措施来帮助确保你访问和使用服务的安全性。你有责任配置你的信息技术、计算机程序和平台以访问我们的服务。你应使用自己的防病毒软件。
            </div>
            <div className="paragraph" >14.8.
              可分割性。如果法院认定本协议的特定条款中的某规定无效，则该规定将从条款中删除，但不影响这些条款的其余部分，而这些条款的其余规定将继续有效和可执行。
            </div>
            <div className="paragraph" >14.9
              本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。
            </div>
            <div className="paragraph" >14.10
              你和我们均是独立的主体，在任何情况下本协议不构成公司对你的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button type="primary" onClick={handleCancel}>确定</Button>
        </div>
      </div>
    </Modal>
  )
}
