import './index.scss';
import CommonHeader from "@/components/CommonHeader";
import { Button, Tag, Input } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {useState, useEffect} from 'react';
import {AppTotalTag} from "@/config/chat";
import FormAppDetail from "@/pages/form_apps/FormAppDetail";
import {setLoginVisible} from "@/store/modules/userStore";

export default function FormApps() {
  const dispatch = useDispatch();
  const { userId, form_ai_app_tags } = useSelector(state => state.user);
  const { conversations, formApps } = useSelector((state) => state.chat);

  const [searchValue, setSearchValue] = useState('');
  const [activeTag, setActiveTag] = useState(AppTotalTag);
  const [filterApps, setFilterApps ] = useState([]);

  const [detailOpen, setDetailOpen ] = useState(false);
  const [detailApp, setDetailApp ] = useState(null);

  useEffect(() => {
    setFilterApps(formApps.filter(app => {
      const name = app.name.toLowerCase();
      if (activeTag === AppTotalTag) {
        return name.includes(searchValue.toLowerCase());
      } else {
        return app.tags.includes(activeTag) && name.includes(searchValue.toLowerCase());
      }
    }));
  }, [searchValue, activeTag, formApps])

  const showAppDetail = (app) => {
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    setDetailApp(app);
    setDetailOpen(true);
  }

  return (
    <div className="market-container">
      {/* 头部 */}
      <CommonHeader title="应用中心" />
      {/* 主体 */}
      <div className="market-body">
        <div className="app-search-bar">
          <div className="search-tags">
            {
              form_ai_app_tags.map(item => {
                return (<Button key={item}
                                className="search-tag"
                                type={activeTag === item ? 'primary' : 'default'}
                                onClick={() => setActiveTag(item)}>{item}</Button>)
              })
            }
          </div>
          <div className="search-input-box">
            <Input value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}
                   className="search-input"
                   allowClear
                   placeholder="搜索应用"></Input>
          </div>
        </div>
        <div className="app-list">
          {
            filterApps.length ? filterApps.map(app => {
              return (<div key={app.id} className="app-item" onClick={() => showAppDetail(app)}>
                <div className="app-item-avatar">
                  <img className="app-img" src={app.avatar_url} alt=""/>
                </div>
                <div className="app-item-content">
                  <div className="app-title ellipsis">{app.name}</div>
                  <div className="app-des ellipsis-2">{app.introduction}</div>
                  <div className="app-other">
                    <div className="app-tags">
                      { app.tags.map(tag => {
                        return (<Tag key={tag}>{tag}</Tag>)
                      }) }
                    </div>
                    <div className="app-action">
                      <Button type="primary" size="small">运行</Button>
                    </div>
                  </div>
                </div>
              </div>)
            }) : (<div className="app-empty">
              <FileExclamationOutlined className="app-empty-icon" />
              <span>暂无该类型应用哦！</span>
            </div>)
          }
        </div>
        <FormAppDetail app={detailApp} open={detailOpen} onOpen={setDetailOpen}></FormAppDetail>
      </div>
    </div>
  )
};
