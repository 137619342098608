import {Modal, Tabs, Input, Avatar, DatePicker, Tag, Button, App} from 'antd';
import {FileExclamationOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import {useEffect, useState, useRef, useMemo} from 'react';
import { useSelector } from 'react-redux';
import './index.scss';
import {getConversation_dataStore, getFormApp_dataStore} from "@/utils";
import dayjs from 'dayjs';

export default function(props) {
  const { message } = App.useApp();
  const { open, onOpen, type, app, conversation_id, onSelect } = props;
  const { username, nickname, email_mask, phone_mask, avatar_url } = useSelector((state) => state.user);

  const allHistory = useRef([]);
  const [conversation, setConversation] = useState(null);
  const [history, setHistory] = useState([]);
  const [activeMessageType, setActiveMessageType] = useState('all');

  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const curUserName = useMemo(() => {
    return username || nickname || email_mask || phone_mask;
  }, [username, email_mask, phone_mask, nickname]);

  useEffect(() => {
    // 过滤名字
    const filterSearchValueArr = allHistory.current.filter(message => {
      const content = message.content.toLowerCase();
      return content.includes(searchValue.toLowerCase())
    })

    // 过滤开始时间
    let filterStartDateArr = [...filterSearchValueArr];
    if (startDate) {
      filterStartDateArr = filterStartDateArr.filter(message => {
        return dayjs(message.created_at).isAfter(dayjs(startDate));
      })
    }

    // 过滤结束时间
    let filterEndDateArr = [...filterStartDateArr];
    if (endDate) {
      filterEndDateArr = filterEndDateArr.filter(message => {
        return dayjs(message.created_at).isBefore(dayjs(endDate).add(1, 'd'));
      })
    }

    setHistory(filterEndDateArr);
  }, [searchValue, startDate, endDate])

  useEffect(() => {
    if (!open) {
      // 关闭模态框，清除所有的数据
      allHistory.current = [];
      setHistory([]);
      setConversation(null);
      setActiveMessageType('all');
      setSearchValue('');
      setStartDate('');
      setEndDate('');
    } else {
      // 打开模态框时
      if (conversation_id && app) {
        // 在这里获取历史数据
        init();
      }
    }
  }, [open, conversation_id]);

  useEffect(() => {
    if (activeMessageType === 'all') {
      setHistory(allHistory.current);
    } else {
      setHistory([]);
    }
  }, [activeMessageType]);

  const startDateValue = useMemo(() => {
    if (startDate) {
      return dayjs(startDate);
    } else {
      return null
    }
  }, [startDate])

  const endDateValue = useMemo(() => {
    if (endDate) {
      return dayjs(endDate);
    } else {
      return null
    }
  }, [endDate])

  async function init() {
    if (type === 'form_app') {
      // 如果是form app类型的对话
      // 根据appid获取对话数据
      const conversation = await getFormApp_dataStore(conversation_id);
      setConversation(conversation);
      allHistory.current = conversation ? conversation.messages.reverse() : [];
    } else {
      const conversation = await getConversation_dataStore(conversation_id);
      setConversation(conversation);
      allHistory.current = conversation.messages.reverse();
    }
    // 获取最新的10条（后面分页）
    const list = getMessages();
    setHistory(list);
  }

  function getMessages() {
    return allHistory.current;
  }

  const handleCancel = () => {
    onOpen(false);
  }

  const handleChangeMessageType = (activeTabKey) => {
    setActiveMessageType(activeTabKey);
  }

  function datePickerChange(dateString, type) {
    if (type === 'start') {
      setStartDate(dateString);
    }

    if (type === 'end') {
      setEndDate(dateString);
    }
  }

  function datePickerClear(type) {
    if (type === 'start') {
      setStartDate('');
    }

    if (type === 'end') {
      setEndDate('');
    }
  }

  function handleSelectMessage(message) {
    onSelect && onSelect(message)
  }

  // 导出text
  async function handleExport() {
    // 准备文本
    let curConversation = null;
    if (type === 'form_app') {
      curConversation = await getFormApp_dataStore(conversation_id);
    } else {
      curConversation = await getConversation_dataStore(conversation_id);
    }
    if (curConversation && curConversation.messages) {
      const allMessages = curConversation.messages;
      if (!allMessages.length) {
        return message.warning('暂无对话历史记录');
      }

      let str = '';
      allMessages.forEach((item) => {
        let master = '';
        if (item.role === 'user') {
          master = curUserName;
        }
        if (item.role === 'assistant') {
          master = app.name;
        }
        str += `[${item.created_at}] ${master}\r\n${item.content}\r\n\r`;
      })

      // 创建一个Blob对象，类型为纯文本
      const blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
      // 创建一个指向Blob对象的URL
      const url = URL.createObjectURL(blob);
      // 创建一个a标签
      const a = document.createElement("a");

      // 设置a标签属性
      a.href = url;
      a.download = '导出会话_' + dayjs().format('YYYY-MM-DD HH-mm-ss') + '.txt';

      // 模拟a标签点击，触发下载
      document.body.appendChild(a);
      a.click();

      // 清理并移除元素和对象URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      message.warning('暂无对话历史记录');
    }
  }

  return (
    <Modal title=""
           width="80%"
           style={{ top: '50px' }}
           open={open}
           footer={null}
           onCancel={handleCancel}>
      {
        !!app && (<div className="chat-history-modal">
          <div className="chat-history-header">
            <img className="chat-history-app-avatar" src={app.avatar_url} alt=""/>
            <div className="chat-history-app-name">{ (conversation && conversation.name) || app.name }</div>
          </div>
          <div className="chat-history-search">
            <Input value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}
                   allowClear
                   prefix={<SearchOutlined />}
                   className="chat-history-search-input"
                   placeholder="请输入" />
          </div>
          <div className="chat-history-body">
            <div className="chat-history-main">
              <Tabs
                activeKey={activeMessageType}
                items={[ { label: '全部', key: 'all' }, { label: '文件', key: 'file' }, { label: '图片和视频', key: 'media' } ]}
                onChange={handleChangeMessageType}
              />
              <div className="chat-history-box">
                {
                  history.length ? history.map((item, index) => {
                    if (['user', 'assistant'].includes(item.role)) {
                      return (<div key={item.created_at + index} className="chat-history-item" onClick={() => handleSelectMessage(item)}>
                        <div className="chat-history-item-left">
                          <Avatar className="chat-history-item-avatar" size={36} src={item.role === 'assistant' ? app.avatar_url : avatar_url}></Avatar>
                        </div>
                        <div className="chat-history-item-right">
                          <div className="chat-history-item-title">
                            <div className="chat-history-item-name">{ item.role === 'assistant' ? app.name : (nickname || username || '我') }</div>
                            <div className="chat-history-item-time">{ item.created_at }</div>
                          </div>
                          <div className="chat-history-item-content">
                            { item.content }
                          </div>
                        </div>
                      </div>)
                    } else {
                      return <div>---- 分割线 ----</div>
                    }
                  }): (<div className="empty-history">
                    {/*<FileExclamationOutlined className="empty-icon" />*/}
                    <span>没有找到相关记录！</span>
                  </div>)
                }
              </div>
            </div>
            <div className="chat-history-filter">
              <div className="chat-history-filter-title">添加筛选条件</div>

              <div className="chat-history-filter-item">
                <div className="chat-history-filter-item-label">日期：</div>

                {/* 选择开始时间 */}
                <div className="chat-history-filter-item-select date-select division-line">
                  <span className="date-select-label">开始：</span>
                  {
                    startDate ? (<Tag className="date-tag" bordered={false} closable onClose={() => datePickerClear('start')}>{ startDate }</Tag>) : (<span className="date-select-label">点击选择</span>)
                  }
                  <DatePicker className="date-picker"
                              value={startDateValue}
                              onChange={(date, dateString) => datePickerChange(dateString, 'start')} />
                </div>

                {/* 选择结束时间 */}
                <div className="chat-history-filter-item-select date-select">
                  <span className="date-select-label">截止：</span>
                  {
                    endDate ? (<Tag className="date-tag" bordered={false} closable onClose={() => datePickerClear('end')}>{ endDate }</Tag>) : (<span className="date-select-label">点击选择</span>)
                  }
                  <DatePicker className="date-picker"
                              value={endDateValue}
                              onChange={(date, dateString) => datePickerChange(dateString, 'end')} />
                </div>
              </div>

              <div className="chat-history-filter-title">导出</div>
              <Button className="export-btn" type="primary" onClick={() => handleExport()}>导出到txt文本</Button>
            </div>
          </div>
        </div>)
      }
    </Modal>
  )
}
