
// app类型
export const AppType = {
  bot: 'bot', // 智能体
  form: 'form' // 表单应用
}

// app筛选里的全部按钮
export const AppTotalTag = '全部';

// 聊天，右侧窗口宽度
export const ChatRightWindowWidth = '400px';

// 新用户初始添加聊天对话数量
export const newUserInitChatNumber = 10;

export const FormAppInputType = {
  text: 'text',
  textarea: 'textarea',
  file: 'file',
  radio: 'radio',
  checkbox: 'checkbox'
}

const allCommandList = [
  { title: '考研进 985 和高考进 985 有什么区别？' },
  { title: '写一篇能火的汽车方面的抖音文案' },
  { title: '给我提供一个RPG游戏的剧本' },
  { title: '帮我写一份工作日报，以UI设计内容为主' },
  { title: '什么叫做幸运者偏差？' },
  { title: '描述一个人从出生到死亡的过程' },
  { title: '帮我写一首吐槽996的现代诗' },
  { title: '帮我写一份关于HR岗位的离职报告！' },
  { title: '用鲁迅的风格写一篇短篇小说' },
  { title: '女朋友出轨了怎么办？' },
  { title: '帮我推荐三亚适合情侣度假的酒店' },
  { title: '推荐几部优秀的科幻小说！' },
  { title: '你会代替人类工作吗？' },
  { title: '有什么工作稳定月收入6000以上，基本朝九晚六，双休？' },
  { title: '学Python都用来干嘛的？' },
  { title: '帮我写一份5天云南旅游攻略？' },
  { title: '如何入门Python爬虫?' },
  { title: '我是行政，写一篇工作日报' },
  { title: '端午节粽子怎么包？' },
];


