import ReactMarkdown from "react-markdown";
import RemarkMath from "remark-math";
import RemarkBreaks from "remark-breaks";
import RehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css';
import RemarkGfm from "remark-gfm";
import RehypeHighlight from "rehype-highlight";
import '@/style/highlight.scss';
import '@/style/markdown.scss';
import {useSelector} from "react-redux";
import { useMemo, useRef, useState, useEffect } from 'react';
import { Button, Spin } from 'antd';
import useCopy from "@/hooks/useCopy";
import { useDebouncedCallback } from "use-debounce";
import mermaid from "mermaid";

// import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
// import {atomDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ReactTyped } from "react-typed";
import { CopyOutlined } from '@ant-design/icons';

function getCodeContentByChildren(children) {
  let str = '';
  if (Array.isArray(children)) {
    children.forEach((item) => {
      if (typeof item === 'string') {
        str += item;
      } else {
        str += item.props ? getCodeContentByChildren(item.props.children) : '';
      }
    })
  } else {
    str += children;
  }

  return str;
}

function escapeDollarNumber(text) {
  let escapedText = "";

  for (let i = 0; i < text.length; i += 1) {
    let char = text[i];
    const nextChar = text[i + 1] || " ";

    if (char === "$" && nextChar >= "0" && nextChar <= "9") {
      char = "\\$";
    }

    escapedText += char;
  }

  return escapedText;
}

function escapeBrackets(text) {
  const pattern =
    /(```[\s\S]*?```|`.*?`)|\\\[([\s\S]*?[^\\])\\\]|\\\((.*?)\\\)/g;
  return text.replace(
    pattern,
    (match, codeBlock, squareBracket, roundBracket) => {
      if (codeBlock) {
        return codeBlock;
      } else if (squareBracket) {
        return `$$${squareBracket}$$`;
      } else if (roundBracket) {
        return `$${roundBracket}$`;
      }
      return match;
    },
  );
}

function Mermaid(props) {
  const ref = useRef(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (props.code && ref.current) {
      mermaid
        .run({
          nodes: [ref.current],
          suppressErrors: true,
        })
        .catch((e) => {
          setHasError(true);
          console.error("[Mermaid] ", e.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  if (hasError) {
    return null;
  }

  return (
    <div
      className="no-dark mermaid"
      style={{
        cursor: "pointer",
        overflow: "auto",
      }}
      ref={ref}
    >
      {props.code}
    </div>
  );
}

function PreCode(props) {
  const ref = useRef(null);
  const refText = ref.current ? ref.current.innerText : '';
  const [mermaidCode, setMermaidCode] = useState("");

  const renderMermaid = useDebouncedCallback(() => {
    if (!ref.current) return;
    const mermaidDom = ref.current.querySelector("code.language-mermaid");
    if (mermaidDom) {
      setMermaidCode(mermaidDom.innerText);
    }
  }, 600);

  useEffect(() => {
    setTimeout(renderMermaid, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refText]);

  return (
    <>
      {mermaidCode.length > 0 && (
        <Mermaid code={mermaidCode} key={mermaidCode} />
      )}
      <pre ref={ref}>
        {props.children}
      </pre>
    </>
  );
}

export default function Markdown({ message }) {
  const { content, loading } = message;
  const { theme: appTheme } = useSelector((state) => state.common);
  const { handleCopy } = useCopy();
  const escapedContent = useMemo(
    () => escapeBrackets(escapeDollarNumber(content)),
    [content],
  );

  const component = {
    // code: (props) => {
    //   // const {children, className, node, ...rest} = props;
    //   // const match = /language-(\w+)/.exec(className || '');
    //   // return match ? (
    //   //   <div className="code-container2">
    //   //     <SyntaxHighlighter
    //   //       {...rest}
    //   //       PreTag="div"
    //   //       children={String(children).replace(/\n$/, '')}
    //   //       language={match[1]}
    //   //       style={atomDark}
    //   //       wrapLongLines
    //   //     />
    //   //     {/*<div className="code-container-top">*/}
    //   //     {/*  <div className="code-container-lang">{ match[1] }</div>*/}
    //   //     {/*  <div className="code-container-copy">*/}
    //   //     {/*    <Button type="link" icon={<CopyOutlined />} size="small" onClick={() => handleCopy(String(children))}>复制代码</Button>*/}
    //   //     {/*  </div>*/}
    //   //     {/*</div>*/}
    //   //   </div>
    //   // ) : (
    //   //   <code {...rest} className={className}>
    //   //     {children}
    //   //   </code>
    //   // )
    // },
    code: (props) => {
      const {children, className, node, ...rest} = props;
      const match = /language-(\w+)/.exec(className || '');
      return match ? (
        <div className="code-container">
          <code {...rest} className={className}>
            {children}
          </code>
          <div className="code-container-top">
            <div className="code-container-lang">{ match[1] }</div>
            <div className="code-container-copy">
              <Button type="link" icon={<CopyOutlined />} size="small" onClick={() => handleCopy(getCodeContentByChildren(children))}>复制代码</Button>
            </div>
          </div>
        </div>
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      )
    },
    pre: PreCode,
    p: (pProps) => <p {...pProps} dir="auto" />,
    a: (aProps) => {
      const href = aProps.href || "";
      const isInternal = /^\/#/i.test(href);
      const target = isInternal ? "_self" : aProps.target ?? "_blank";
      return <a {...aProps} target={target} />;
    },
  }

  return (<div className={ appTheme + ' markdown-body' }>
    {/*<ReactTyped strings={[item.content]} typeSpeed={10} showCursor={false}></ReactTyped>*/}
    { loading ? <Spin size="small" /> : (<ReactMarkdown
      remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
      rehypePlugins={[
        RehypeKatex,
        [
          RehypeHighlight,
          {
            detect: false,
            ignoreMissing: true,
          },
        ],
      ]}
      children={escapedContent} components={component}></ReactMarkdown>) }
  </div>)
}
