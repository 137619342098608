import './index.scss';
import CommonHeader from "@/components/CommonHeader";
import { Button, Tag, Input } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {useState, useEffect} from 'react';
import {setConversations, setActiveConversationId} from "@/store/modules/chatStore";
import {createConversationObj} from "@/utils";
import {AppTotalTag} from "@/config/chat";
import {setLoginVisible} from "@/store/modules/userStore";
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '@/config/nav';

export default function BotApps() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, bot_ai_app_tags } = useSelector(state => state.user);
  const { botApps, conversations } = useSelector((state) => state.chat);

  const [searchValue, setSearchValue] = useState('');
  const [activeTag, setActiveTag] = useState(AppTotalTag);
  const [filterApps, setFilterApps ] = useState([]);

  useEffect(() => {
    setFilterApps(botApps.filter(app => {
      const name = app.name.toLowerCase();
      if (activeTag === AppTotalTag) {
        return name.includes(searchValue.toLowerCase());
      } else {
        return app.tags.includes(activeTag) && name.includes(searchValue.toLowerCase());
      }
    }));
  }, [searchValue, activeTag, botApps])

  const handleSelectChatApp = (app) => {
    if (!userId) {
      dispatch(setLoginVisible(true));
      return;
    }

    let conversation = conversations.find(item => item.appId === app.id);
    if (!conversation) {
      // 如果列表没有，则添加，并激活
      conversation = createConversationObj(app, 'bot');
      dispatch(setConversations([...conversations, conversation]));
    }
    // 切换激活
    dispatch(setActiveConversationId(conversation.id));
    // 前往chat
    navigate(RouterPath.chat);
  }

  return (
    <div className="bot-container">
      {/* 头部 */}
      <CommonHeader title="智能体" />
      {/* 主体 */}
      <div className="bot-body">
        <div className="app-search-bar">
          <div className="search-tags">
            {
              bot_ai_app_tags.map(item => {
                return (<Button key={item}
                                className="search-tag"
                                type={activeTag === item ? 'primary' : 'default'}
                                onClick={() => setActiveTag(item)}>{item}</Button>)
              })
            }
          </div>
          <div className="search-input-box">
            <Input value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}
                   className="search-input"
                   allowClear
                   placeholder="搜索智能体"></Input>
          </div>
        </div>
        <div className="bot-list">
          {
            filterApps.length ? filterApps.map(app => {
              return (<div key={app.id} className="bot-item" onClick={() => handleSelectChatApp(app)}>
                <div className="bot-item-avatar">
                  <img className="bot-img" src={app.avatar_url} alt=""/>
                </div>
                <div className="bot-item-content">
                  <div className="bot-title ellipsis">{app.name}</div>
                  <div className="bot-description ellipsis-3">{app.introduction}</div>
                  <div className="bot-other">
                    <div className="app-tags">
                      { app.tags.map(tag => {
                        return (<Tag key={tag}>{tag}</Tag>)
                      }) }
                    </div>
                    <div className="app-action">
                      <Button type="primary" size="small">对话</Button>
                    </div>
                  </div>
                </div>
              </div>)
            }) : (<div className="bot-empty">
              <FileExclamationOutlined className="empty-icon" />
              <span>暂无该类型智能体哦！</span>
            </div>)
          }
        </div>
      </div>
    </div>
  )
};
