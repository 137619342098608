import {Input, Modal, App} from 'antd';
import {useEffect, useState} from 'react';

export default function EditConversationNameModal(props) {
  const { open, onOpen, conversation, onFinish } = props;
  const { message } = App.useApp();

  const [value, setValue] = useState('');

  useEffect(() => {
    if (open && conversation) {
      setValue(conversation.name);
    }
  }, [open])

  const handleCancel = () => {
    onOpen(false);
  }

  const handleOk = () => {
    if (value) {
      onFinish(value)
      onOpen(false);
    } else {
      message.error('对话名称不能为空')
    }
  }

  return (
    <Modal title="修改对话名称"
           open={open}
           onOk={handleOk}
           onCancel={handleCancel}>
      <div className="edit-dialog-name-modal">
        <Input value={value}
               onChange={(e) => setValue(e.target.value)}
               className="edit-input"
               allowClear
               placeholder="对话名称"></Input>
      </div>
    </Modal>
  )
}
