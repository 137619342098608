import ChatBar from './ChatBar';
import ChatBox from './ChatBox';
import './Chat.scss';

function ChatContainer() {

  return (
    <div className="chat-container">
      <ChatBar></ChatBar>
      <ChatBox></ChatBox>
    </div>
  )
}

export default ChatContainer;
