import { useState, useEffect, useRef } from 'react';

export default function useCountDown() {
  const [count, setCount] = useState(60);
  const [countLoading, setCountLoading] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (count <= 0) {
      resetCount();
    }
  }, [count])

  function startCountDown() {
    setCount((prevState) => prevState - 1);
    timerRef.current = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000)
    return timerRef.current;
  }

  function resetCount() {
    timerRef.current && clearInterval(timerRef.current);
    setCount(60);
  }

  return { count, startCountDown, resetCount, countLoading, setCountLoading }
};
