import { createSlice } from '@reduxjs/toolkit'

const counterStore = createSlice({
  name: 'counter',
  // 初始化状态
  initialState: {
    count: 0
  },
  // 修改数据的方法（同步）
  reducers: {
    increment(state) {
      state.count++
    },
    decrement(state) {
      state.count--
    },
    addToNum(state, action) {
      console.log(action);
      state.count += action.payload;
    }
  }
})

// 结构出来 counterStore 里actions里的函数
const { increment, decrement, addToNum } = counterStore.actions;

// 获取reducer函数
const reducer = counterStore.reducer;

// 导出actions里的函数，和reducer函数
export { increment, decrement, addToNum };
export default reducer;
