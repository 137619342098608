import {useEffect} from "react";
import './index.scss';
import globalConfig from '@/config';
import JoinImg from '@/images/join_3.png';
import Register from "./Register";

const Join = () => {
  useEffect(() => {
  }, [])

  return (
    <div className="page-join">
      <div className="left">
        <div className="register-box">
          {/*<img src={logoImg} alt="logo" className="logo"/>*/}
          {/*<div className="register-box-title">注 册</div>*/}
          {/*<div className="enter-x mb-6 text-left">*/}
          {/*  已经有账号了？<span className="custom-link">立即登陆</span>*/}
          {/*</div>*/}
          <Register />
        </div>
      </div>
      <div className="right">
        <svg
          className="bg"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="bgCover" fill="none" stroke="currentColor" strokeWidth="100">
            <circle r="234" cx="196" cy="23"/>
            <circle r="234" cx="790" cy="491"/>
          </g>
        </svg>
        <svg className="bgRight" viewBox="0 0 220 192" fill="none">
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor"/>
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"/>
        </svg>
        <div className="join-up-box"
        >
          <div className="title">欢迎加入我们
          </div
          >
          <div className="desc">{globalConfig.siteTitle} 系统是一款基于最前沿的人工智能技术开发的智能助手，旨在帮助您更高效地处理各种任务和工作。无论您是个人用户、企业还是组织，我们的AI系统都可以为您提供全面的支持和服务。
          </div>
          <div className="desc">
            探索无限可能，与AI系统同行。让智慧引领您的未来，选择{globalConfig.siteTitle}，开启智能化生活！
          </div>
          <div className="avatar"
          >
            <div className="ant-avatar-group css-k83k30">
              <span className="ant-avatar ant-avatar-lg ant-avatar-circle ant-avatar-image css-k83k30">
                <img src={JoinImg} alt=""/>
              </span>
            </div>
          </div>
        </div
        >
      </div>
    </div>
  )
}

export default Join;
